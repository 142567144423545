import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Link } from 'react-router-dom'
import config from '../../config/config'
import 'react-lazy-load-image-component/src/effects/blur.css';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import ImageRenderer from '../ImageRenderer';


function SliderCard(props) {
    const closeCanvas = () => {
        let canvasOverlays = document.querySelectorAll(".offcanvas-backdrop");
        if (canvasOverlays) {
            canvasOverlays.forEach(canvasOverlay => {
                canvasOverlay.remove();
            });
            document.body.removeAttribute("style");
        }
        let canvaslist = document.querySelectorAll(".offcanvas");
        if (canvaslist) {
            canvaslist.forEach(canvas => {
                if (canvas && canvas.classList.contains("show")) {
                    canvas.classList.remove("show");
                }
            });
            document.body.removeAttribute("style");
        }

        const plyr_body = document.querySelector(".mobile-music-plyr");
        if (plyr_body && plyr_body.classList.contains("active")) {
            plyr_body.classList.remove("active")
            plyr_body.classList.add("not-active");
        }

        if (document.body.classList.contains("mini-active")) {
            document.body.classList.remove("mini-active");
        }



    }

    return (

        <div className="music-slider-blk" key={`media_${props.media.id}`}>
            <div className="blk-wrapper">
                <div className="image">
                    <Link to={`/media/${props.media.id}`} onClick={closeCanvas}>
                        <ImageRenderer
                            wrapperClassName="lazy-slider"
                            url={`${config.MEDIA_URL}/${props.media.cover_image}`} />
                        {/* <img src={`${config.MEDIA_URL}/${props.media.cover_image}`} alt="" /> */}
                    </Link>
                </div>
                <div className="slider-btm-wp">
                    <div className={ props.hideIcon ? 'wp-lft full' : 'wp-lft' }>
                        <h4 className="title">
                            {props.media.title}
                        </h4>
                        {
                            props.media.artists ?
                                <h4 className="artist">
                                    {
                                        props.media.artists.map((artist, i, row) => {
                                            if (i + 1 === row.length) {
                                                // Last one.
                                                return `${artist.name}`
                                            } else {
                                                return `${artist.name}, `
                                            }

                                        })
                                    }
                                </h4> : null
                        }
                    </div>
                    {
                        !props.hideIcon && props.media.category && <div className="wp-rgt cardd3">
                            <img src={`${config.CATEGORY_URL}/${props.media.category.icon}`} alt="" className="icon d-only" />
                            <img src={`${config.CATEGORY_URL}/${props.media.category.mobile_icon}`} alt="" className="icon m-only" />
                        </div>
                    }
                </div>
            </div>
        </div>

    )
}

export default SliderCard
