import axios from "axios";
import { decode } from "base-64";
import config from "./config/config";

const unAuthorizedAxios = axios.create({
    baseURL: config.API_BASE_URL,
    headers: {
        "Content-type": "application/json",
        "access_token": config.access_token,
    }
});

const axiosInstance =  axios.create({
    baseURL: config.API_BASE_URL,
    headers: {
        "Content-type": "application/json",
        "access_token": config.access_token,
    }
});

axiosInstance.interceptors.request.use(
    (config) => {
        let token = localStorage.getItem('_token');
        try {
            token = decode(token);
        } catch (error) {
        }
        if (token) {
            config.headers.Authorization = 'Bearer '+ token;
        }
        return config;
    },
    (error) => Promise.reject(error),
);

export default axiosInstance;

export { unAuthorizedAxios };

