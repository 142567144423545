import React from 'react';
import App from './App';
import LegalInfo from './LegalInfo';
import Account from './Account';
import AccountInfo from './AccountInfo';
import Artists from './Artists';
import Series from './Series';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AudioBtm from './Components/AudioDetails/AudioBtm';
import Header from './Components/Header';
import './App.css';
import ScrollToTop from './Components/ScrollToTop';
import Favourites from './Favourites';
import Register from './Register';
import Join from './Components/Register/Join';
import License from './Components/Register/License';
import Choosepassword from './Components/Register/Choosepassword';
import Confirmation from './Components/Register/Confirmation';
import Payment from './Components/Register/Payment';
import Signin from './Components/Register/Signin';
import Subscription from './Subscription';
import Viewall from './Viewall';
import Search from './Search';
import { Provider } from 'react-redux';
import { store, persistor } from './store/store';
import ProtectedRoute from './Components/ProtectedRoute';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import VerifyEmail from './Components/Register/VerifyEmail';

import { PersistGate } from 'redux-persist/integration/react';
import Channels from './Channels';
import SeriesAll from './SeriesAll';
import Watchlist from './Watchlist';
import SearchDetail from './SearchDetail';
import MediaPurchase from './Components/MediaPurchase';
import ForgotPassword from './Components/Register/ForgotPassword';
import ResetPassword from './Components/Register/ResetPassword';

import Footer from './Components/Footer';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <div id="mobileBg" className='mobileBg'></div>
    <div id="miniBg" className='mobileBg'></div>
    <div className="main-wrp">
      <ToastContainer />
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>

            <ScrollToTop>
              <Routes>

                {/* path="/" element={<Header />} */}
                <Route path="/" element={<Header />}>
                  <Route index element={<App />} />

                  <Route path="media/:mediaId" element={<AudioBtm />} />

                  <Route path="channel/:channelId" element={<Artists />} />
                  <Route path="series/:seriesSlug" element={<Series />} />
                  <Route path="collection/:id" element={<Viewall />} />
                  <Route path="search" element={<Search />} />
                  <Route path="search/:key" element={<SearchDetail />} />
                  <Route path="channels" element={<Channels />} />
                  <Route path="series" element={<SeriesAll />} />
                  <Route element={<ProtectedRoute />}>
                    <Route path="favourites" element={<Favourites />} />
                    <Route path="favourites/:key" element={<Watchlist />} />
                  </Route>
                </Route>
                <Route element={<ProtectedRoute />}>
                  <Route path="media-purchase" element={<MediaPurchase />} />
                  <Route path="subscription" element={<Subscription />} />
                  <Route path="accountinfo" element={<AccountInfo />} />
                </Route>
                <Route path="legalinfo" element={<LegalInfo />} />
                <Route path="account" element={<Account />} />

                <Route path="forgot-password" element={<ForgotPassword />} />
                <Route path="reset-password/:token" element={<ResetPassword />} />
                <Route path="register" element={<Register />} />
                <Route path="join" element={<Join />} />
                <Route path="license" element={<License />} />
                <Route path="choosepassword" element={<Choosepassword />} />
                <Route path="verify-email/:token" element={<VerifyEmail />} />
                <Route path="confirmation" element={<Confirmation />} />
                <Route path="payment" element={<Payment />} />
                <Route path="signin" element={<Signin />} />
              </Routes>

              <Footer />
            </ScrollToTop >
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </div>
  </>
);


