import { createSlice } from "@reduxjs/toolkit";
import STATUS from "./status";

let pageName = localStorage.getItem('previousName') ? localStorage.getItem('previousName') : 'STARTSEITE';
const initialState = {
    status: STATUS.IDLE,
    pageName: pageName,
}


const previousPageSlice = createSlice({
    name: 'previousPageSlice',
    initialState,
    reducers: {
        setPreviousPage: (state,action) => {
            state.status = STATUS.IDLE;
            state.pageName = action.payload;
            localStorage.setItem('previousName', action.payload);
        },
    }
})

const { reducer } = previousPageSlice;
export const { setPreviousPage } = previousPageSlice.actions

export default reducer;