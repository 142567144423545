
import React, { useEffect } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from "react-router-dom";
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Errors from './Components/Errors';
import profileUpdateService from './store/services/profileUpdate.service';
import notification from './config/notification';
import { authUserThunk } from './store/auth/authSlice';
import axios from 'axios';
import config from './config/config';
import { useDocumentTitle } from './hooks/setDocumentTitle';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import subscriptionService from './store/services/subscription.service';
import { currencyFormatDE, formatDate } from './helpers/helpers';
import { subscriptionThunk } from './store/subscriptionSlice';
import { Bars } from 'react-loader-spinner';
import Skeleton from 'react-loading-skeleton';
import ChangeCardComponent from './ChangeCardComponent';
import SiteLogo from './Components/SiteLogo';


function AccountInfo() {
  useDocumentTitle('Account Info');
  const { authUser } = useSelector(state => state.authSlice);
  const [errors, setErrors] = useState({});
  const [name, setName] = useState(authUser.first_name)
  const [isNameApply, setNameApply] = useState(false);
  const [isNameDisabled, setNameDisabled] = useState(true);
  const [lastname, setlastName] = useState(authUser.last_name)
  const [islastNameApply, setlastNameApply] = useState(false);
  const [islastNameDisabled, setlastNameDisabled] = useState(true);
  const [mail, setMail] = useState(authUser.email)
  const [isMailApply, setMailApply] = useState(false);
  const [isMailDisabled, setMailDisabled] = useState(true);
  const [pass, setPass] = useState(null)
  const [isPassApply, setPassApply] = useState(false);
  const [isPassDisabled, setPassDisabled] = useState(true);
  const [cancelSubscription, setCancelSubscription] = useState(false)
  const [subscription, setSubscription] = useState(null);
  const [loading, setLoading] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [cancel, setCancel] = useState(false)
  const [changeCard, setChangeCard] = useState(false)
  const token = useSelector((state) => state.authSlice.token)
  const dispatch = useDispatch();



  const updateName = (e) => {
    e.preventDefault();
    setNameDisabled(false);
    setNameApply(true);
  }

  const saveName = async (e) => {

    e.preventDefault();
    setErrors({})
    try {
      await profileUpdateService.updatefirstName({ first_name: name })
      notification('message', 'Änderungen gespeichert', 500);
      dispatch(authUserThunk());
      setNameApply(false);
      setNameDisabled(true);
    } catch (er) {
      if (er.response && er.response.status == '401') {
        window.location.reload(false);
      }
      if (er.response && er.response.status === 422) {
        setErrors(er.response.data.data);
      } else {
        notification('error', 'Das hat nicht geklappt. Bitte versuche es später noch einmal.')
      }
    }

  }

  const updatelastName = (e) => {
    e.preventDefault();
    setlastNameDisabled(false);
    setlastNameApply(true);
  }

  const savelastName = async (e) => {

    e.preventDefault();
    setErrors({})
    try {
      await profileUpdateService.updatelastName({ last_name: lastname })
      notification('message', 'Änderungen gespeichert', 500);
      dispatch(authUserThunk());
      setlastNameApply(false);
      setlastNameDisabled(true);
    } catch (er) {
      if (er.response && er.response.status == '401') {
        window.location.reload(false);
      }
      if (er.response && er.response.status === 422) {
        setErrors(er.response.data.data);
      } else {
        notification('error', 'Das hat nicht geklappt. Bitte versuche es später noch einmal.')
      }
    }

  }

  // -----------

  const updateMail = (e) => {
    e.preventDefault();
    setMailDisabled(false);
    setMailApply(true);
  }

  const saveMail = async (e) => {
    e.preventDefault();
    setErrors({})
    try {
      await profileUpdateService.updateEmail({ email: mail })
      notification('message', 'Änderungen gespeichert', 500);
      dispatch(authUserThunk());
      setMailApply(false);
      setMailDisabled(true);
    } catch (er) {
      if (er.response && er.response.status == '401') {
        window.location.reload(false);
      }
      if (er.response && er.response.status === 422) {
        setErrors(er.response.data.data);
      } else {
        notification('error', 'Das hat nicht geklappt. Bitte versuche es später noch einmal.')
      }
    }

  }
  // ------------

  const updatePass = (e) => {
    e.preventDefault();
    setPassDisabled(false);
    setPassApply(true);
  }

  const savePass = async (e) => {
    e.preventDefault();
    setErrors({})
    try {
      await profileUpdateService.updatePassword({ password: pass })
      notification('message', 'Änderungen gespeichert', 500);
      dispatch(authUserThunk());
      setPassApply(false);
      setPassDisabled(true);
    } catch (er) {
      if (er.response && er.response.status === 422) {
        setErrors(er.response.data.data);
      } else {
        notification('error', 'Das hat nicht geklappt. Bitte versuche es später noch einmal.')
      }
    }

  }

  // -----------
  const [isFileSelected, setFileSelected] = useState(false);
  const [file, setFile] = useState(authUser.user_image);
  const [image, setImage] = useState(null);
  function updateFile(e) {
    setImage(e.target.files[0]);
    setFile(URL.createObjectURL(e.target.files[0]));
    setFileSelected(true);
  }



  const saveFile = async (e) => {
    e.preventDefault();
    var data = new FormData();
    data.append('image', image);

    var configuration = {
      method: 'post',
      url: `${config.API_BASE_URL}/update-image`,
      headers: {
        'Authorization': `Bearer ${token}`,
        'access_token': config.access_token,
      },
      data: data
    };

    try {
      await axios(configuration);
      notification('message', 'Änderungen gespeichert', 500);
      dispatch(authUserThunk());

    } catch (er) {
      if (er.response && er.response.status == '401') {
        window.location.reload(false);
      }
      if (er.response && er.response.status === 422 && er.response.data.data && er.response.data.data.image) {
        notification('error', er.response.data.data.image[0])
      } else {
        notification('error', 'Das hat nicht geklappt. Bitte versuche es später noch einmal.')
      }
    }

  }

  const removeFile = () => {
    setFileSelected(false);
    setFile("images/kanale-3.png");
  }

  const getSubscription = async () => {
    setLoading(true)
    try {
      let res = await subscriptionService.getCurrentSubscription();

      setSubscription(res.data.data)
      setLoading(false)
    } catch (error) {

    }
  }


  const cancelPlan = async () => {
    setProcessing(true)
    try {
      await subscriptionService.cancelSubscription();
      dispatch(subscriptionThunk())
      setCancel(true)
      notification('message', 'Kündigung bestätigt');
      setCancelSubscription(false)
    } catch (error) {
      notification('message', 'Etwas ist schief gelaufen. Bitte versuche es erneut');
    }
    setProcessing(false)
  }

  useEffect(() => {
    var root = document.getElementById("root");
    root.removeAttribute("class");
    getSubscription()
  }, [cancel]);




  if (cancelSubscription) {
    return (
      <>
        {
          processing ? <Bars
            height="80"
            width="80"
            color="#ffffff"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass="loading-spinner-animation"
            visible={true}
          /> : ''
        }
        <div className="cancel-page">
          <div className="account-sec privacy-policy-wrp">
            <div className="container">
              <div className="em-logo position-relative mb-4 ">
                <Link to="/">
                  <SiteLogo />
                </Link>
                <div className="back-sec">
                  <button onClick={() => setCancelSubscription(false)}><img src="images/dn_ar.svg" /></button>
                </div>
              </div>
              <div className="cancel-subscription base-container">
                <h5>Kündigung bestätigen</h5>
                <div className="cancel-srry-txt">
                  <p>Wir finden es sehr schade, dass du uns verlassen möchtest. Wenn du Tipps hast, was wir verbessern können, freuen wir uns über eine kurze Nachricht an <a href="mailto:info@streamfair.de" target="_blank">info@streamfair.de</a>.</p>

                  <p>Bitte bestätige mit Klick auf den blauen Button unten deine Kündigung. Du kannst streamfair dann bis zum Ablauf deines bestehenden Abos am {subscription && formatDate(subscription.end_date, 1)} weiternutzen.</p>

                  <p>Wenn du zudem auch dein Benutzerkonto löschen lassen willst, schreibe bitte eine Mail mit Betreff "Löschung Kundenkonto" und deinem Namen an die o. g. E-Mailadresse.</p>
                </div>
                <button className="join-btn can-subs-btn" onClick={cancelPlan}>KÜNDIGUNG BESTÄTIGEN</button>
                <button className="escape" onClick={() => setCancelSubscription(false)}>Abbrechen</button>
              </div>

            </div>
          </div>
        </div>
      </>
    );
  }
  if (changeCard) {
    return (
      <>
        {
          processing ? <Bars
            height="80"
            width="80"
            color="#ffffff"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass="loading-spinner-animation"
            visible={true}
          /> : ''
        }
        <ChangeCardComponent subscription={subscription} toggleCard={setChangeCard} getSubscription={getSubscription} />
      </>
    );
  }
  return (
    <div className="account-sec account-info">
      <div className="container">
        <div className="em-logo position-relative mb-4 ">
          <Link to="/">
            <SiteLogo />
          </Link>
          <div className="back-sec">
            <Link to={-1}><img src="images/dn_ar.svg" alt="" /></Link>
          </div>
        </div>
        <div className="base-container">
          <div className="em-logo-bt text-center pb-1">
            <p>Konto</p>
          </div>
          <div className="account-info-btm">
            <div className="top-in-wrp">
              <h4>PERSÖNLICHE INFORMATIONEN</h4>
            </div>

            <form onSubmit={saveFile}>
              <div className="profile-img-con">
                <img src={file} />
              </div>
              <div className="center-error-div"> <Errors errors={errors} /></div>
              <div className="inp-wp email-in">
                <div className="form-group">
                  {
                    isFileSelected ? <button onClick={removeFile}>Entfernen</button> : <label htmlFor="pf-img" className='pf-upload'>Hochladen</label>
                  }
                  <input type="file" accept="image/png, image/gif, image/jpeg" className="form-control" id='pf-img' onChange={updateFile} />
                </div>
                <div className="change-btn">
                  <button type='submit'>Speichern</button>
                </div>

              </div>
            </form>

            <form onSubmit={saveName}>
              <div className="inp-wp email-in">
                <div className="form-group">
                  <input type="text" className="form-control" onChange={event => setName(event.target.value)} placeholder='Vorname' disabled={isNameDisabled ? true : false} value={name} />
                </div>
                <div className="change-btn">
                  {
                    isNameApply ? <button type='submit'>Speichern</button> : <button onClick={updateName}>Ändern</button>
                  }

                </div>

              </div>
            </form>
            <form onSubmit={savelastName}>
              <div className="inp-wp email-in">
                <div className="form-group">
                  <input type="text" className="form-control" onChange={event => setlastName(event.target.value)} placeholder='Nachname' disabled={islastNameDisabled ? true : false} value={lastname} />
                </div>
                <div className="change-btn">
                  {
                    islastNameApply ? <button type='submit'>Speichern</button> : <button onClick={updatelastName}>Ändern</button>
                  }

                </div>

              </div>
            </form>
            <form onSubmit={saveMail}>
              <div className="inp-wp email-in">
                <div className="form-group">
                  <input type="text" onChange={event => setMail(event.target.value)} className="form-control" placeholder='mail@email.com' disabled={isMailDisabled ? true : false} value={mail} />
                </div>
                <div className="change-btn">
                  {
                    isMailApply ? <button type='submit'>Speichern</button> : <button onClick={updateMail}>Ändern</button>
                  }
                </div>

              </div>
            </form>
            <form onSubmit={savePass}>
              <div className="inp-wp password-in">
                <div className="label-wp">
                  <label htmlFor="">Passwort: </label>
                </div>
                <div className="form-group">
                  <input type="password" onChange={event => setPass(event.target.value)} className="form-control" placeholder='**********' disabled={isPassDisabled ? true : false} value={pass} />
                </div>
                <div className="change-btn">
                  {
                    isPassApply ? <button type='submit'>Speichern</button> : <button onClick={updatePass}>Ändern</button>
                  }
                </div>
              </div>
            </form>

          </div>

          <div className="account-info-btm an-sec">
            <div className="top-in-wrp">
              <h4>ABONNEMENT</h4>
            </div>
            {
              loading ? <Skeleton baseColor="#202020" highlightColor="#444" className='plans-skeleton' /> :
                subscription
                  ?
                  <>
                    <div className="new-inp-wp">
                      <div className="inp-lft">
                        <h5>{subscription.subscription_name}</h5>
                        <p>Betrag: {currencyFormatDE(Number(subscription.subscription_price))}</p>

                        {
                          subscription.subscription_cancel == '1' ?
                            <p className='yellow'>Gekündigt zum {formatDate(subscription.end_date, 1)}</p>
                            :
                            <p>Nächste Zahlung: {formatDate(subscription.expire_date, 1)}</p>
                        }
                      </div>
                      <div className="inp-rgt">
                        <Link to='/subscription'>Abonnement ändern</Link>
                      </div>
                    </div>
                    <div className="new-inp-wp">
                      <div className="inp-lft">
                        <h5>Zahlungsart</h5>
                        <p>{subscription.card_brand} {subscription.card_last4}</p>
                        <p>Ablaufdatum: {subscription.card_exp_month}/{subscription.card_exp_year}</p>
                      </div>
                      <div className="inp-rgt">
                        <button onClick={() => { setChangeCard(true) }}>Zahlungsart ändern</button>
                      </div>
                    </div>
                    {
                      subscription.subscription_cancel == '0'
                      &&
                      <button className='canSub' onClick={() => setCancelSubscription(true)}>Abo kündigen</button>

                    }
                  </>
                  :
                  <>
                    <div className="mx-3 my-3">
                      Sie haben keinen aktiven Plan. Bitte abonnieren Sie <Link to='/subscription'>hier</Link> einen Plan
                    </div>

                  </>
            }

          </div>


          <div className="btm-para">
            <p>Alle Informationen zur Verwaltung und zum Löschen deines Kontos bei streamfair findest du in unseren <Link to='/legalinfo'>FAQ zur Kontoverwaltung.</Link></p>
          </div>
        </div>

      </div>


    </div>
  );
}

export default AccountInfo;
