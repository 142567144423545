import React from 'react'
import Skeleton from 'react-loading-skeleton'

function LoadingTitle() {
  return (
    <>
        <div className="music-top">
                <Skeleton className='sk-title'/>
        </div>
    </>
    )
  }
  
  export default LoadingTitle