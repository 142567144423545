import axios, { unAuthorizedAxios } from "../../axios";


class AuthService { 

    login(data) {
        return unAuthorizedAxios.post('/login',data);
    }

    register(data){
        return unAuthorizedAxios.post('/register',data);
    }

    checkEmail(data){
        return unAuthorizedAxios.post('/check-email',data);
    }

    authUser(data) {
        return axios.get('/userData', data);
    }

    verifyEmail(data) {
        return axios.post('/verify-email',data);
    }

    resendVerifyEmail(data) {
        return axios.post('/resend-verify-email',data);
    }

    forgotPassword(data) {
        return axios.post('/forgot-password',data);
    }
    verifyResetToken(data) {
        return axios.post('/verify-reset-token',data);
    }
    resetPassword(data) {
        return axios.post('/reset-password',data);
    }

}

export default new AuthService();