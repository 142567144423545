// ScrollToTop.jsx
import React from 'react';
import { useEffect } from "react";
import { useLocation } from "react-router";
import { authUserThunk, resetUser } from "../store/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { settingThunk } from '../store/settingSlice';
import { filterThunk } from '../store/filterSlice';
import { resetSubscription, subscriptionThunk } from '../store/subscriptionSlice';
import { resetTrack } from '../store/trackSlice';
import { getExpireTime, isTimeExpired } from '../helpers/helpers';

const ScrollToTop = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { token, isAuthenticated } = useSelector((state) => state.authSlice)
  const { fetched } = useSelector((state) => state.settingSlice)
  const { fetched: fetchedFilters } = useSelector((state) => state.filterSlice)
  const { fetched: fetchedSubscription } = useSelector((state) => state.subscriptionSlice)

  useEffect(() => {
    if (token && !isAuthenticated) {
      dispatch(authUserThunk())
    }
  }, [dispatch, token, isAuthenticated])

  window.onbeforeunload = function () {
    localStorage.removeItem('persist:root');
  }

  useEffect(() => {
    if (!fetched) {
      dispatch(settingThunk())
    }
  }, [dispatch, fetched])

  useEffect(() => {
    if (!fetchedFilters) {
      dispatch(filterThunk())
    }
  }, [dispatch, fetchedFilters])

  useEffect(() => {
    if (!fetchedSubscription && isAuthenticated) {
      dispatch(subscriptionThunk())
    }
  }, [dispatch, fetchedSubscription, isAuthenticated])

  useEffect(() => {
    
    let mediaNetworkIssue = location.state && location.state.mediaReset ? location.state.mediaReset : false;
    if (!mediaNetworkIssue) {
      window.scrollTo(0, 0);
    }
  }, [location]);

  
  // User logout functionality

  const logout = () => {
    localStorage.clear();
    dispatch(resetUser());
    dispatch(resetSubscription());
    dispatch(resetTrack());

  }

  useEffect(() => {

    if (isTimeExpired()) {
      logout();
    } 
    localStorage.setItem('_expireTime', getExpireTime())
  }, [location]);


  return <>{props.children}</>
};

export default ScrollToTop;