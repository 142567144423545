
import './App.css';
import Banner from './Components/Home/Banner';
import Categorytab from './Components/Home/CategoryTab';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { useDocumentTitle } from './hooks/setDocumentTitle';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import usePreviousPage from './hooks/usePreviousPage';


function App() {

  useEffect(() => {
    document.body.classList.remove('noBefore');
  }, [])

  const location = useLocation();
  let reload = sessionStorage.getItem("reload") ? sessionStorage.getItem("reload") : false;
  sessionStorage.removeItem("reload");
  if (reload) {
    window.location.reload(false);
  }
  usePreviousPage("STARTSEITE");
  useDocumentTitle("Home");
  return (
    <div className="App main appMain">

      <Banner />
      <Categorytab />


    </div>
  );
}

export default App;
