import React, { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { ThreeDots } from 'react-loader-spinner';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import LoadingComponent from './Components/LoadingComponent';
import config from './config/config';
import { useDocumentTitle } from './hooks/setDocumentTitle';
import usePreviousPage from './hooks/usePreviousPage';
import homeService from './store/services/home.service';
import ImageRenderer from './Components/ImageRenderer';

function SeriesAll() {
    useDocumentTitle('Series');
    usePreviousPage('Series');
    const [loading, setLoading] = useState(false);
    const [series, setSeries] = useState([]);
    const [hasMore, setHasMore] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const fetchSeries = async () => {
        if (currentPage == 0) {
            setLoading(true);
            setSeries([])
        }
        setHasMore(false);
        try {
            let res = await homeService.getSeries(currentPage + 1);
            // console.log(res.data.data);
            if (res.data.data.next_page_url) {
                setHasMore(true);
            }
            setCurrentPage((cpage) => cpage + 1);
            setSeries((pre) => [...pre, ...res.data.data.data]);
            setLoading(false);
        } catch (error) {
            // console.log(error);
        }
    }
    useEffect(() => {
        fetchSeries();
    }, []);
    return (
        // <SkeletonTheme baseColor="#202020" highlightColor="#444">
        <div className='viewall-page appMain'>
            <div className="container">
                <div className="cat-top">
                    <div className="heading3">
                        <h3 className='pl-0'>Series</h3>
                    </div>
                    {
                        loading ?
                            // <LoadingComponent/>
                            null
                            :
                            series &&
                            <InfiniteScroll
                                dataLength={series.length}
                                next={fetchSeries}
                                hasMore={hasMore}
                                loader={<ThreeDots
                                    height="80"
                                    width="80"
                                    radius="9"
                                    color="#4fa94d"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                />}
                            >
                                <div className="row">
                                    {
                                        series.map((series) => {
                                            return (
                                                <div className="col-20" key={`ser-${series.id}`}>
                                                    <Link to={`/series/${series.slug}`}>
                                                        <div className="v-card v-all">
                                                            <ImageRenderer
                                                                wrapperClassName="lazy-viewall"
                                                                url={`${config.SERIES_URL}/${series.image}`} />
                                                            <div className="slider-btm-wp">
                                                                <div className="wp-lft">
                                                                    <h4 className="title">{series.title}</h4>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </InfiniteScroll>
                    }
                </div>
            </div>
        </div>
        // </SkeletonTheme>
    )
}
export default SeriesAll