import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useRef, useState } from 'react';
import homeService from './store/services/home.service';
import { useEffect } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import config from './config/config';
import NotFound from './NotFound';
import { useSelector } from 'react-redux';
import notification from './config/notification';
import { useDocumentTitle } from './hooks/setDocumentTitle';
import SliderCard from './Components/Home/SliderCard';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ProductCard from './Components/ProductCard';
import usePreviousPage from './hooks/usePreviousPage';
import ImageRenderer from './Components/ImageRenderer';



function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style }}
            onClick={onClick}
        > <img src={'/images/caret1.png'} alt="" /> </div>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style }}
            onClick={onClick}
        ><img src={'/images/caret1.png'} alt="" /></div>
    );
}

export default function Artists() {
    useDocumentTitle('Channel');

    var body = document.body;
    body.classList.remove("body_class");
    body.classList.add("artist-body");
    const { channelId } = useParams();
    const [collections, setCollections] = useState(null);
    const [products, setProducts] = useState(null);
    const [loading, setLoading] = useState(false);
    const [channel, setChannel] = useState(null);
    const [notFound, setNotFound] = useState(false);
    const { isAuthenticated } = useSelector((state) => state.authSlice);
    const navigate = useNavigate();
    usePreviousPage(channel && channel.name);
    const recent = {
        variableWidth: true,
        dots: false,
        arrows: true,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 5,
        swipeToSlide: true,
        cssEase: 'linear',
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1200,
                settings: "unslick"
            }
        ],

    };
    const [copySuccess, setCopySuccess] = useState('');
    const textAreaRef = useRef(null);

    function copyToClipboard(e) {
        textAreaRef.current.select();
        document.execCommand('copy');
        // This is just personal preference.
        // I prefer to not show the whole text area selected.
        e.target.focus();
        // setCopySuccess('Copied!');
        setCopySuccess(true);
        setTimeout(() => {
            setCopySuccess(false);
        }, 3000);
    };

    const [like, setLike] = useState(false);
    let location = useLocation();
    const likeFun = async () => {

        if (!isAuthenticated) {
            notification('info', 'Bitte logge dich zuerst ein.');
            navigate('/signin', { state: { from: location } })
            return;
        }
        let data = {
            'type': 3,
            'main_id': channel.id
        }
        if (!like) {
            try {
                await homeService.addToWatchlist(data);
                setLike(true);
                return;
            } catch (error) {
                notification('error', 'Das hat nicht geklappt. Bitte versuche es später noch einmal.');
                return;
            }
        } else {
            try {
                await homeService.removeFromWatchlist(data);
                setLike(false);
                return;
            } catch (error) {
                notification('error', 'Das hat nicht geklappt. Bitte versuche es später noch einmal.');
                return;
            }
        }
    }

    const fetchChannelById = async () => {
        setLoading(true);
        setCollections(null);
        setProducts(null);
        try {
            let res = await homeService.channelById(channelId);
            if (res.data.success === false) {
                setNotFound(true);
                return;
            }
            // console.log(res.data.data);
            setCollections(res.data.data.collections);
            setChannel(res.data.data.channel);
            setProducts(res.data.data.products);
            setLike(res.data.data.channel.watchlist);
            setLoading(false);
        } catch (error) {
            // console.log(error);
        }
    }

    useEffect(() => {
        fetchChannelById();
    }, [])


    if (notFound) {
        return <NotFound />;
    }

    return (
        // <SkeletonTheme baseColor="#202020" highlightColor="#444">
        <div className='artists-page appMain'>

            <div className="container">
                <div className="s-banner">
                    {
                        !channel ?
                            // <Skeleton width={1220} height={468} />
                            null
                            :
                            <ImageRenderer
                                wrapperClassName="lazy-banner"
                                url={`${config.CHANNEL_URL}/${channel.banner_image}`} />
                    }
                    <div className="banner-text">
                        {/* <div className="artist-logo">
                                <img src="/images/artist-image.png" alt="" />
                            </div> */}
                        <div className="a-con">

                            <div className="a-text">
                                {
                                    channel ?
                                        <div dangerouslySetInnerHTML={{ __html: channel.short_description }}></div>
                                        : null
                                    // <h3><Skeleton /></h3>
                                }
                                {/* <button className='btn btn-primary'>MEHR ERFAHREN</button> */}
                            </div>
                            {
                                !channel ?
                                    // <Skeleton width={40} height={ 40} circle />
                                    null
                                    :
                                    <button className='heart' onClick={likeFun}>
                                        {
                                            like ?
                                                <img src={'/images/heart-active.png'} alt="" />
                                                :
                                                <img src={'/images/heart.png'} alt="" />
                                        }
                                    </button>
                            }

                        </div>

                    </div>
                </div>
            </div>

            <div className="artist-btm">
                <div className="container">
                    {
                        loading ?
                            // <div className='music-slider filme mtc-4'>
                            //     <div className="row">
                            //         <div className="col-7 pe-0">
                            //             <div className="top-title position-relative"><h3><b className='dont-break'><Skeleton /></b></h3>
                            //                 <Skeleton width={30} height={30} circle={true} />
                            //             </div>
                            //         </div>
                            //         <div className="col-5 text-end rgt">
                            //             <h3><Skeleton /></h3>
                            //         </div>
                            //     </div>

                            //     <div className="music-btm-slider neu">
                            //         <Slider {...recent}>
                            //             <div className="music-slider-blk">
                            //                 <div className="blk-wrapper">
                            //                     <div className="image">
                            //                         <Skeleton square height="280px" />
                            //                     </div>
                            //                     <div className="slider-btm-wp">
                            //                         <div className='wp-lft'>
                            //                             <h4 className="title">
                            //                                 <Skeleton />
                            //                             </h4>
                            //                             <h4 className="artist">
                            //                                 <Skeleton />
                            //                             </h4>
                            //                         </div>

                            //                     </div>
                            //                 </div>
                            //             </div>
                            //             <div className="music-slider-blk">
                            //                 <div className="blk-wrapper">
                            //                     <div className="image">
                            //                         <Skeleton square height="280px" />
                            //                     </div>
                            //                     <div className="slider-btm-wp">
                            //                         <div className='wp-lft'>
                            //                             <h4 className="title">
                            //                                 <Skeleton />
                            //                             </h4>
                            //                             <h4 className="artist">
                            //                                 <Skeleton />
                            //                             </h4>
                            //                         </div>

                            //                     </div>
                            //                 </div>
                            //             </div>
                            //             <div className="music-slider-blk">
                            //                 <div className="blk-wrapper">
                            //                     <div className="image">
                            //                         <Skeleton square height="280px" />
                            //                     </div>
                            //                     <div className="slider-btm-wp">
                            //                         <div className='wp-lft'>
                            //                             <h4 className="title">
                            //                                 <Skeleton />
                            //                             </h4>
                            //                             <h4 className="artist">
                            //                                 <Skeleton />
                            //                             </h4>
                            //                         </div>

                            //                     </div>
                            //                 </div>
                            //             </div>
                            //             <div className="music-slider-blk">
                            //                 <div className="blk-wrapper">
                            //                     <div className="image">
                            //                         <Skeleton square height="280px" />
                            //                     </div>
                            //                     <div className="slider-btm-wp">
                            //                         <div className='wp-lft'>
                            //                             <h4 className="title">
                            //                                 <Skeleton />
                            //                             </h4>
                            //                             <h4 className="artist">
                            //                                 <Skeleton />
                            //                             </h4>
                            //                         </div>

                            //                     </div>
                            //                 </div>
                            //             </div>
                            //             <div className="music-slider-blk">
                            //                 <div className="blk-wrapper">
                            //                     <div className="image">
                            //                         <Skeleton square height="280px" />
                            //                     </div>
                            //                     <div className="slider-btm-wp">
                            //                         <div className='wp-lft'>
                            //                             <h4 className="title">
                            //                                 <Skeleton />
                            //                             </h4>
                            //                             <h4 className="artist">
                            //                                 <Skeleton />
                            //                             </h4>
                            //                         </div>

                            //                     </div>
                            //                 </div>
                            //             </div>
                            //         </Slider>
                            //     </div>
                            // </div> 
                            null
                            :
                            collections && collections.map((collection) => {

                                return (

                                    <div className='music-slider filme mtc-4' key={`collection_${collection.id}`}>
                                        <div className="row">
                                            <div className="col-7 pe-0">
                                                <div className="top-title position-relative"><h3><b className='dont-break'>{collection.title}</b></h3>
                                                    {
                                                        collection.icon && <img className="top-img" src={`${config.COLLECTION_URL}/${collection.icon}`} alt="" />
                                                    }

                                                </div>
                                            </div>
                                            <div className="col-5 text-end rgt">

                                                <h3><Link to={`/collection/${collection.id}`}>Alle ansehen</Link></h3>

                                            </div>
                                        </div>


                                        <div className="music-btm-slider neu">


                                            <Slider {...recent}>


                                                {
                                                    collection.media_arr && collection.media_arr.map((media) => {
                                                        return (
                                                            <SliderCard media={media} key={media.id} />
                                                        )
                                                    })
                                                }


                                            </Slider>
                                        </div>
                                    </div>

                                )

                            }


                            )
                    }
                </div>
            </div>
            {
                !products || products.length === 0
                    ? null
                    :
                    <div className="audio-wrap1 product">
                        <div className="container">
                            <div className='music-slider recent'>
                                <div className="music-top">
                                    <div className="row">
                                        <div className="col-12 pl-smll-0">
                                            <div className="top-title position-relative mb-0 left-minus"><h3><b className='dont-break'>Zugehörige Artikel</b></h3>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="music-btm-slider neu al1 pb-2  pb-xl-0">
                                    <Slider {...recent}>

                                        {
                                            products && products.map((product) => {
                                                return (
                                                    <ProductCard product={product} key={product.id} />
                                                )
                                            })
                                        }

                                    </Slider>
                                </div>

                            </div>
                        </div>
                    </div>
            }

            {
                channel && (channel.email || channel.telephone) ?
                    <div className="contact-sec">
                        <div className="container">
                            <h3>Kontakt & Buchungsanfrage</h3>
                            <ul>
                                <li className="mail">
                                    <img src="/images/mail.svg" alt="" />
                                    <div className="c-txt">
                                        <form>
                                            <textarea
                                                ref={textAreaRef}
                                                value={channel.email}
                                                readOnly={true} />
                                        </form>
                                    </div>
                                    <div className="copy-icon">

                                        <button onClick={copyToClipboard}><i className="fa fa-clone" aria-hidden="true"></i></button>
                                        {copySuccess} {copySuccess ? <span className="ms-2">Copied</span> : null}

                                    </div>

                                </li>
                                <li className="contact">
                                    <a href={`tel:${channel.telephone}`}><img src="/images/tel.svg" alt="" />{channel.telephone}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    :
                    null
            }
        </div>
        // </SkeletonTheme>
    )
}
