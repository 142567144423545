
let BACKEND_URL = process.env.REACT_APP_BACKEND_URL
let ASSET_URL = process.env.REACT_APP_ASSET_URL;

const config = {
    API_BASE_URL: `${BACKEND_URL}/api`,
    access_token: process.env.REACT_APP_ACCESS_TOKEN,
    BACKEND_URL,
    ASSET_URL,
    CATEGORY_URL: `${ASSET_URL}/category`,
    COLLECTION_URL: `${ASSET_URL}/collection`,
    MEDIA_URL: `${ASSET_URL}/media`,
    SERIES_URL: `${ASSET_URL}/series`,
    CHANNEL_URL: `${ASSET_URL}/channel`,
    BANNER_URL: `${ASSET_URL}/banner`,
    PRODUCT_URL: `${ASSET_URL}/product`,
};

export default config;