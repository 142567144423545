import React from 'react'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setPreviousPage } from '../store/previousPageSlice';

const usePreviousPage = (currentPageName) => {
    const { pageName } = useSelector((state) => state.previousPageSlice);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setPreviousPage(currentPageName));
    }, [currentPageName]);

    return pageName;
}

export default usePreviousPage