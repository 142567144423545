import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Swal from "sweetalert2";
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import subscriptionService from './store/services/subscription.service';
import STATUS from './store/status';
import { subscriptionThunk } from './store/subscriptionSlice';
import SubscriptionPayment from './SubscriptionPayment';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useDocumentTitle } from './hooks/setDocumentTitle';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { currencyFormatDE, formatDate } from './helpers/helpers';
import notification from './config/notification';
import { Bars } from 'react-loader-spinner';
import SiteLogo from './Components/SiteLogo';

export default function Subscription() {
    useDocumentTitle('Subscription');
    const [cancel, setCancel] = useState(false);
    const [subscriptions, setSubscriptions] = useState(null);
    const [activeSubscription, setActiveSubscription] = useState(null);
    const [selectedSubscription, setSelectedSubscription] = useState(0)
    const [confirmChangeSubs, setConfirmChangeSubs] = useState(false);
    const [showDrawbackConfirm, setShowDrawbackConfirm] = useState(false);
    const { status, isExpired, isSubscribe, subscriptionId, endDate, subscription_cancel, next_payment_date, upcoming_subscription_id } = useSelector((state) => state.subscriptionSlice)
    const [subscriptionType, setSubscriptionType] = useState(0);
    const [subscriptionPrice, setSubscriptionPrice] = useState(0)
    const [processing, setProcessing] = useState(false)
    const [changeBooking, setChangeBooking] = useState(false)
    const [showDrawbackCancelConfirm, setShowDrawbackCancelConfirm] = useState(false);
    const [removeNewSubscription, setRemoveNewSubscription] = useState(false);
    const dispatch = useDispatch();
    const getAllSubscriptions = async () => {
        try {
            let res = await subscriptionService.allSubscriptions();
            let allsub = res.data.data;

            let activesub = allsub.filter((sub) => {
                return sub.id == subscriptionId;
            })
            let othersub = allsub.filter((sub) => {
                return sub.id != subscriptionId;
            })

            if (isExpired) {
                if (allsub.length > 0) {
                    let first = Object.values(allsub)[0];
                    if (first.id == upcoming_subscription_id) {
                        first = Object.values(allsub)[1];
                    }
                    setSubscriptionType(first.id);
                    setSubscriptionPrice(first.price);
                }
                setSubscriptions(allsub);
                setActiveSubscription(null);
            } else {
                if (othersub.length > 0) {
                    let first = Object.values(othersub)[0];
                    if (first.id == upcoming_subscription_id) {
                        first = Object.values(othersub)[1];
                    }
                    setSubscriptionType(first.id);
                    setSubscriptionPrice(first.price);
                }
                setSubscriptions(othersub);
                setActiveSubscription(activesub);
            }


        } catch (error) {

        }
    }

    useEffect(() => {
        if (status == STATUS.SUCCESS) {
            getAllSubscriptions();
        }
    }, [status])

    useEffect(() => {
        dispatch(subscriptionThunk());
    }, [dispatch])

    useEffect(() => {
        var root = document.getElementById("root");
        root.removeAttribute("class");
    }, []);

    function cancelPlan() {
        Swal.fire({
            title: 'Bist du dir sicher?',
            text: 'Ihr aktuelles aktives Abonnement wird gekündigt',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#419af3',
            cancelButtonColor: '#ec5151',
            confirmButtonText: 'Bestätigen',
            cancelButtonText: 'Absagen'
        }).then(async (result) => {
            if (result.isConfirmed) {
                setCancel(false)
                try {
                    await subscriptionService.cancelSubscription();
                    setCancel(true);
                    Swal.fire({
                        title: 'Bestätigt',
                        text: 'Ihr aktueller Plan wurde gekündigt.',
                        icon: 'success',
                        confirmButtonColor: '#419af3'
                    })
                    dispatch(subscriptionThunk())
                } catch (error) {
                    Swal.fire({
                        title: 'Oops!',
                        text: 'Das hat nicht geklappt. Bitte versuche es später noch einmal.',
                        icon: 'error',
                        confirmButtonColor: '#419af3'
                    })
                }


            }
        })
    }


    const subPlan = async () => {
        if (subscriptionType == '0') {
            notification('Bitte wähle einen Plan.');
            return;
        }
        setSelectedSubscription(0);

        if (isExpired) {
            setSelectedSubscription(subscriptionType);
            return;
        }

        setConfirmChangeSubs(true);
        return;

    }

    const bookPlan = async () => {
        setProcessing(true)
        try {
            await subscriptionService.bookSubscription({
                'id': subscriptionType
            });
            notification('message', `Abonnement wurde geändert`);
            setConfirmChangeSubs(false);
            setChangeBooking(false)
            dispatch(subscriptionThunk())
        } catch (error) {
            notification('error', 'Das hat nicht geklappt. Bitte versuche es später noch einmal.');
        }
        setProcessing(false)
    }

    const cancelDrawback = async () => {
        setProcessing(true)
        try {
            await subscriptionService.cancelDrawback();
            setShowDrawbackConfirm(false);
            dispatch(subscriptionThunk())
            notification('message', 'Kündigung erfolgreich zurückgezogen');
        } catch (error) {
            notification('error', 'Das hat nicht geklappt. Bitte versuche es später noch einmal.');
        }
        setProcessing(false)
    }

    const cancelBooking = async () => {
        setProcessing(true)
        try {
            await subscriptionService.cancelBooking();
            setRemoveNewSubscription(false);
            dispatch(subscriptionThunk())
            notification('message', 'Kündigung erfolgreich zurückgezogen');
        } catch (error) {
            notification('error', 'Das hat nicht geklappt. Bitte versuche es später noch einmal.');
        }
        setProcessing(false)
    }



    const changeAct = subscription => (e) => {
        setSubscriptionType(subscription.id);
        setSubscriptionPrice(subscription.price);
    }


    let subscriptionDiv;
    let subscriptionActiveDiv;
    let bookingDiv;
    if (status !== STATUS.SUCCESS) {

        subscriptionDiv =
            <>
                <Skeleton />
            </>;
        subscriptionActiveDiv =
            <>
                <Skeleton />
            </>;
        bookingDiv =
            <>
                <Skeleton />
            </>;

    } else {
        subscriptionDiv =
            <>
                <div className="plan-title plan-title-others">NEUES ABONNEMENT</div>
                <div className="t-btn t-btn-new">
                    {
                        subscriptions && subscriptions.map((subscription) => {
                            if (subscription.id != upcoming_subscription_id) {
                                return (
                                    <div key={subscription.id} className="sub-con">
                                        <label htmlFor={subscription.name} onClick={changeAct(subscription)} className={subscriptionType == subscription.id ? 'active' : ''}>
                                            <span className="t-txt">
                                                <span className="tt1">{subscription.name}</span>
                                                <span className="tt2">{currencyFormatDE(subscription.price)} inkl. MwSt.</span>
                                                <span className="tt3" dangerouslySetInnerHTML={{ __html: subscription.content }}></span>
                                            </span>
                                            {
                                                subscription.discount_text != null ? <span className="discountt">{subscription.discount_text}</span> : null
                                            }
                                            <span className="t-circle">
                                                <span className="in-circle"></span>
                                            </span>
                                        </label>
                                        <input type="radio" id={subscription.name} name="subscription_type" className="hidden-radio-btn" />
                                    </div>

                                );
                            }
                        })
                    }
                </div>
            </>;
        subscriptionActiveDiv = activeSubscription && activeSubscription.map((subscription) => {
            return (
                <div key={subscription.id}>
                    <div className="plan-title"> {subscription.id == subscriptionId ? 'AKTUELLES ABONNEMENT' : 'NEUES ABONNEMENT'}</div>
                    <div className="active-plan-sec-new">
                        <div className="draw-back-con">
                            <h5>{subscription.name}</h5>
                            {
                                subscription_cancel ?
                                    <button className="draw-back" onClick={() => setShowDrawbackConfirm(true)}>Kündigung zurückziehen</button>
                                    :
                                    null
                            }

                        </div>
                        <p>Betrag: {currencyFormatDE(subscription.price)}</p>
                        <p>{
                            subscription_cancel ?
                                <span>Gekündigt zum {formatDate(endDate, 1)}</span>
                                :
                                `Nächste Zahlung: ${formatDate(next_payment_date, 1)}`
                        }
                        </p>
                    </div>
                </div>
            );
        })

        let upcomingSub = subscriptions && subscriptions.filter((sub) => { return sub.id == upcoming_subscription_id });
        bookingDiv = upcomingSub && upcomingSub.map((subscription) => {
            return (<div className="plans-container upcoming-plan-component" key={subscription.id}>

                <div>
                    <div className="plan-title">NEUES ABONNEMENT</div>
                    <div className="active-plan-sec-new">
                        <div className="draw-back-con">
                            <h5>{subscription.name}</h5>
                            <button className="draw-back" onClick={() => setChangeBooking(true)}>Abonnement ändern</button>
                        </div>
                        <p>Betrag: {currencyFormatDE(subscription.price)} inkl. MwSt.</p>
                        <p className='blue'>Startet am {formatDate(next_payment_date, 1)}</p>
                        <button className="remove-new-subs" onClick={() => setRemoveNewSubscription(true)}> <img src="images/trash-yellow.png" alt="Trash Icon" /> Neues Abo entfernen</button>
                    </div>
                </div>
            </div>)
        });
    }
    if (removeNewSubscription) {
        return (
            <>
                {
                    processing ? <Bars
                        height="80"
                        width="80"
                        color="#ffffff"
                        ariaLabel="bars-loading"
                        wrapperStyle={{}}
                        wrapperClass="loading-spinner-animation"
                        visible={true}
                    /> : ''
                }
                <div className="cancel-page">
                    <div className="account-sec privacy-policy-wrp">
                        <div className="container">
                            <div className="em-logo position-relative mb-4 ">
                                <Link to="/">
                                    <SiteLogo />
                                </Link>
                                <div className="back-sec">
                                    <button onClick={() => setRemoveNewSubscription(false)}><img src="images/dn_ar.svg" /></button>
                                </div>
                            </div>
                            <div className="cancel-subscription base-container">
                                <h5>Nachfolgendes Abo entfernen</h5>
                                <div className="cancel-srry-txt">
                                    <p>Wenn du auf "Bestätigen" klickst, wird dein nachfolgendes Abo entfernt. Wenn du streamfair mit deinem aktuellen Abo weiter nutzen möchtest, klicke bitte auf der nächsten Seite auf "Kündigung zurückziehen".</p>

                                    <p>Indem du auf den Button klickst, stimmst du unserem <Link to='/legalinfo'>Nutzungsvertrag</Link>, der <Link to='/legalinfo'>Datenschutzerklärung</Link>, <Link to='/legalinfo'>Cookie-Richtlinie</Link> und den <Link to='/legalinfo'>EU-Datenschutzhinweisen</Link> zu.</p>
                                </div>
                                <button className="join-btn can-subs-btn" onClick={cancelBooking}>ABO-ÄNDERUNG BESTÄTIGEN</button>
                                <button className="escape" onClick={() => setRemoveNewSubscription(false)}>Abbrechen</button>
                            </div>

                        </div>
                    </div>
                </div>
            </>
        );
    }


    if (selectedSubscription > 0) {
        return <SubscriptionPayment selectedSubscription={selectedSubscription} />
    }

    if (confirmChangeSubs) {
        return (
            <>
                {
                    processing ? <Bars
                        height="80"
                        width="80"
                        color="#ffffff"
                        ariaLabel="bars-loading"
                        wrapperStyle={{}}
                        wrapperClass="loading-spinner-animation"
                        visible={true}
                    /> : ''
                }
                <div className="cancel-page">
                    <div className="account-sec privacy-policy-wrp">
                        <div className="container">
                            <div className="em-logo position-relative mb-4 ">
                                <Link to="/">
                                    <SiteLogo />
                                </Link>
                                <div className="back-sec">
                                    <button onClick={() => setConfirmChangeSubs(false)}><img src="images/dn_ar.svg" /></button>
                                </div>
                            </div>
                            <div className="cancel-subscription base-container">
                                <h5>Änderung deines Abos</h5>
                                <div className="cancel-srry-txt">
                                    <p>Wenn du auf "Bestätigen" klickst, wird dein laufendes Abo zum zum {formatDate(endDate, 1)} gekündigt und auf das Jahresabo Familie für {currencyFormatDE(subscriptionPrice)}/{subscriptionType == '1' ? 'Monat' : subscriptionType == '3' ? '6 Monate' : 'Jahr'} umgestellt. Dir wird dann bis zu deiner Kündigung wiederkehrend der genannte Betrag abgebucht.</p>

                                    <p>Indem du auf den Button klickst, stimmst du unserem <Link to="/legalinfo">Nutzungsvertrag</Link>, der <Link to="/legalinfo">Datenschutzerklärung</Link>, <Link to="/legalinfo">Cookie-Richtlinie</Link> und den <Link to="/legalinfo">EU-Datenschutzhinweisen</Link> zu.</p>
                                </div>
                                <button className="join-btn can-subs-btn" onClick={bookPlan}>ABO-ÄNDERUNG BESTÄTIGEN</button>
                                <button className="escape" onClick={() => setConfirmChangeSubs(false)}>Abbrechen</button>
                            </div>

                        </div>
                    </div>
                </div>
            </>
        );
    }

    if (showDrawbackConfirm) {
        return (
            <>
                {
                    processing ? <Bars
                        height="80"
                        width="80"
                        color="#ffffff"
                        ariaLabel="bars-loading"
                        wrapperStyle={{}}
                        wrapperClass="loading-spinner-animation"
                        visible={true}
                    /> : ''
                }
                <div className="cancel-page">
                    <div className="account-sec privacy-policy-wrp">
                        <div className="container">
                            <div className="em-logo position-relative mb-4 ">
                                <Link to="/">
                                    <SiteLogo />
                                </Link>
                                <div className="back-sec">
                                    <button onClick={() => setShowDrawbackConfirm(false)}><img src="images/dn_ar.svg" /></button>
                                </div>
                            </div>
                            <div className="cancel-subscription base-container">
                                <h5>Kündigung zurückziehen</h5>
                                <div className="cancel-srry-txt">
                                    <p>Wir freuen uns, dass du dich entschieden hast, streamfair treu zu bleiben. Vielen Dank!</p>

                                    <p>Bitte klicke unten auf "Kündigung zurückziehen", um diesen Schritt zu bestätigen.</p>
                                </div>
                                <button className="join-btn can-subs-btn" onClick={cancelDrawback}>KÜNDIGUNG ZURÜCKZIEHEN</button>
                                <button className="escape" onClick={() => setShowDrawbackConfirm(false)}>Abbrechen</button>
                            </div>

                        </div>
                    </div>
                </div>
            </>
        );
    }

    return (
        <SkeletonTheme baseColor="#202020" highlightColor="#444">
            <div className='subscription-page test'>
                <div className="account-sec privacy-policy-wrp">
                    <div className="container">
                        <div className="em-logo position-relative mb-4 ">
                            <Link to="/">
                                <SiteLogo />
                            </Link>
                            <div className="back-sec">
                                <Link to={-1}><img src="/images/dn_ar.svg" alt="" /></Link>
                            </div>
                        </div>

                        <div className="reg-container reg-1">
                            <h3>Abonnement ändern</h3>
                            <div className="plans-container">

                                {!isExpired && subscriptionActiveDiv}
                                {changeBooking ?
                                    subscriptionDiv
                                    :
                                    !isExpired && subscription_cancel && upcoming_subscription_id > 0 ?
                                        bookingDiv
                                        :
                                        subscriptionDiv}
                            </div>
                            {
                                changeBooking ?
                                    <div className="agree-con-new">
                                        <p>Um dein Abo zu ändern, klicke auf "Weiter".</p>
                                        <button className="join-btn join-btn-new" onClick={subPlan}>WEITER</button>
                                    </div>
                                    :
                                    !isExpired && subscription_cancel && upcoming_subscription_id > 0 ?
                                        null
                                        :
                                        <div className="agree-con-new">
                                            <p>Um dein Abo zu ändern, klicke auf "Weiter".</p>
                                            <button className="join-btn join-btn-new" onClick={subPlan}>WEITER</button>
                                        </div>
                            }

                        </div>
                    </div>
                </div>
            </div>
        </SkeletonTheme>
    )
}
