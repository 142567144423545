// import logo from '../logo.svg';
import React, { useState } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import 'react-loading-skeleton/dist/skeleton.css'
import { useDispatch, useSelector } from 'react-redux';
import { searchThunk, setSearch } from './store/searchSlice';
import STATUS from './store/status';
import LoadingComponent from './Components/LoadingComponent';
import SliderCard from './Components/Home/SliderCard';
import config from './config/config';
import { SkeletonTheme } from 'react-loading-skeleton';
import notification from './config/notification';
import { useDocumentTitle } from './hooks/setDocumentTitle';
import usePreviousPage from './hooks/usePreviousPage';
function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style }}
            onClick={onClick}
        > <img src={'/images/caret1.png'} alt="" /> </div>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style }}
            onClick={onClick}
        ><img src={'/images/caret1.png'} alt="" /></div>
    );
}


function Search() {
    useDocumentTitle('SUCHE');
    usePreviousPage("SUCHE");
    const { search, result, status, fetched } = useSelector((state) => state.searchSlice);
    const [searchValue, setSearchValue] = useState(search ? search : '')
    const dispatch = useDispatch();
    var body = document.body;
    body.classList.remove("body_class");
    body.classList.add("artist-body");




    const recent = {
        variableWidth: true,
        dots: false,
        arrows: true,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 5,
        swipeToSlide: true,
        cssEase: 'linear',
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,


        responsive: [
            {
                breakpoint: 1200,
                settings: "unslick"
            }
        ],

    };

    const handleSearch = (e) => {
        e.preventDefault();
        if (searchValue === '') {
            return notification('info', 'Gib einen Suchbegriff ein.');
        }
        dispatch(setSearch(searchValue))
        dispatch(searchThunk({ search: searchValue }))
    }

    return (
        // <SkeletonTheme baseColor="#202020" highlightColor="#444">
        <div className="search-page appMain">
            <div className={`container search-container ${fetched ? 'after-search' : 'after-search'} `}> {/* ------add class 'before-search' to change layout------- */}
                <form className='search-form' onSubmit={handleSearch}>
                    <div className="search-box">
                        <input type="text" value={searchValue} onChange={(e) => { setSearchValue(e.target.value) }} placeholder="Titel, Autor, Artist oder Kanal suchen" />
                        <button className='search-btn'><i className="fa fa-search" aria-hidden="true"></i></button>
                    </div>
                </form>
            </div>
            {/* ------------- */}


            <div className="favourites-sec appMain">
                <div className="container">
                    {
                        status == STATUS.LOADING ?
                            null
                            // <LoadingComponent />
                            :

                            result && (result.channels && result.channels.length == 0) &&
                                (result.channels && result.series.length == 0)
                                &&
                                (result.channels && result.musics.length == 0)
                                &&
                                (result.channels && result.films.length == 0)

                                ?
                                <h2 className='text-center no-results'>Keine Ergebnisse. Passe deine Suche bitte an.</h2>
                                :
                                <div className="fav-btm">
                                    {
                                        result && result.channels && result.channels.length > 0 ?
                                            <div className='music-slider rounded mtc-4' >
                                                <div className="music-top">
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <div className="top-title position-relative"><h3><b className='dont-break'>Kanäle</b></h3>
                                                                <img className="top-img" src={'/images/kanale-3.png'} alt="" />
                                                            </div>
                                                        </div>
                                                        <div className="col-6 text-end rgt">
                                                            <h3><Link to={'/search/channels'}>Alle ansehen </Link></h3>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="music-btm-slider  neu pb-2">


                                                    <Slider {...recent}>
                                                        {result.channels.map((channel) => {
                                                            return (

                                                                <div className="music-slider-blk " key={channel.id}>
                                                                    <Link to={`/channel/${channel.id}`}>
                                                                        <div className="blk-wrapper">
                                                                            <div className="image">
                                                                                <img src={`${config.CHANNEL_URL}/${channel.thumbnail_image}`} alt="" />
                                                                            </div>
                                                                            <div className="slider-btm-wp">
                                                                                <div className="wp-lft">
                                                                                    <h4 className="title">{channel.name}</h4>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Link>
                                                                </div>

                                                            );
                                                        })}
                                                    </Slider>

                                                </div>
                                            </div> : null
                                    }

                                    {
                                        result && result.series && result.series.length > 0 ?
                                            <div className='music-slider mtc-4' >
                                                <div className="music-top">
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <div className="top-title position-relative"><h3><b className='dont-break'>Series</b></h3>
                                                                {/* <img className="top-img" src={'/images/kanale-3.png'} alt="" /> */}
                                                            </div>
                                                        </div>
                                                        <div className="col-6 text-end rgt">
                                                            <h3><Link to={'/search/series'}>Alle ansehen </Link></h3>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="music-btm-slider  neu pb-2">

                                                    <Slider {...recent}>
                                                        {result.series.map((series) => {
                                                            return (

                                                                <div className="music-slider-blk " key={series.id}>
                                                                    <Link to={`/series/${series.slug}`}>
                                                                        <div className="blk-wrapper">
                                                                            <div className="image">
                                                                                <img src={`${config.SERIES_URL}/${series.image}`} alt="" />
                                                                            </div>
                                                                            <div className="slider-btm-wp">
                                                                                <div className="wp-lft">
                                                                                    <h4 className="title">{series.title}</h4>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Link>
                                                                </div>

                                                            );
                                                        })}
                                                    </Slider>

                                                </div>
                                            </div> : null
                                    }

                                    {
                                        result && result.musics && result.musics.length > 0
                                            ?
                                            <div className='music-slider filme mtc-4' >
                                                <div className="music-top">
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <div className="top-title position-relative"><h3><b className='dont-break'>Musik & Hörbücher</b></h3>
                                                                <img className="top-img" src={'/images/musik-3.png'} alt="" />
                                                            </div>
                                                        </div>
                                                        <div className="col-6 text-end rgt">
                                                            <h3><Link to="/search/music">Alle ansehen</Link></h3>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="music-btm-slider ">
                                                    <Slider {...recent}>
                                                        {
                                                            result.musics && result.musics.map((media) => {
                                                                return (
                                                                    <SliderCard hideIcon={true} media={media} key={media.id} />
                                                                )
                                                            })
                                                        }
                                                    </Slider>
                                                </div>
                                            </div> : null
                                    }

                                    {
                                        result && result.films && result.films.length > 0
                                            ?
                                            <div className='music-slider filme mtc-4' >
                                                <div className="music-top">
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <div className="top-title position-relative"><h3><b className='dont-break'>Filme</b></h3>
                                                                <img className="top-img" src={'/images/Kamera-3.png'} alt="" />
                                                            </div>
                                                        </div>
                                                        <div className="col-6 text-end rgt">
                                                            <h3><Link to="/search/film">Alle ansehen</Link></h3>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="music-btm-slider ">
                                                    <Slider {...recent}>
                                                        {
                                                            result.films && result.films.map((media) => {
                                                                return (
                                                                    <SliderCard hideIcon={true} media={media} key={media.id} />
                                                                )
                                                            })
                                                        }
                                                    </Slider>
                                                </div>
                                            </div> : null
                                    }

                                </div>
                    }



                </div>



            </div>


        </div>
        // </SkeletonTheme>
    );
}

export default Search;
