import React, { useState } from "react";
import { useRef } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Bars } from "react-loader-spinner";
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import notification from "../../config/notification";
import { useDocumentTitle } from "../../hooks/setDocumentTitle";
import authService from "../../store/services/auth.service";
import SiteLogo from "../SiteLogo";
export default function Choosepassword() {
    useDocumentTitle('Choose Password');
    const location = useLocation();
    if (!location || !location.state || !location.state.email) {
        return <Navigate to="/join" replace={true} />
    }

    const password = useRef();
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const checkPass = async (e) => {
        e.preventDefault();
        let newsletter = location.state.newsletter === true ? 1 : 0;
        setError(null);
        setLoading(true)
        try {
            let res = await authService.register({
                email: location.state.email,
                first_name: location.state.first_name,
                last_name: location.state.last_name,
                password: password.current.value,
                newsletter
            })
            setLoading(false)
            notification('message', 'Bestätigungsmail versendet', 500)
            navigate('/confirmation', { state: { ...location.state, password: password.current.value } });
        } catch (er) {
            setLoading(false)

            if (er.response && er.response.status === 422) {
                er.response.data.data.password ? setError(er.response.data.data.password[0]) : setError('');
            } else {
                notification('error', 'Das hat nicht geklappt. Bitte versuche es später noch einmal.')
            }
        }
    };
    return (
        <div className='choosepassword-page'>
            {
                loading ? <Bars
                    height="80"
                    width="80"
                    color="#ffffff"
                    ariaLabel="bars-loading"
                    wrapperStyle={{}}
                    wrapperClass="loading-spinner-animation"
                    visible={true}
                /> : ''
            }
            <div className="account-sec privacy-policy-wrp">
                <div className="container">
                    <div className="em-logo position-relative mb-4 ">
                        <Link to="/">
                            <SiteLogo />
                        </Link>
                        <div className="back-sec">
                            <Link to="/license" state={{ email: location.state.email }}><img src="images/dn_ar.svg" alt="" /></Link>
                        </div>
                    </div>
                    <div className="reg-container reg-2">
                        <h4 className="join-steps">SCHRITT 3 VON 5</h4>
                        <div className="join-form">

                            <h3 className='form-title'>Wähle ein sicheres Passwort</h3>
                            <form className="join" onSubmit={checkPass}>
                                <div className="field">
                                    <input type="password" className="j-email" placeholder='Passwort' ref={password} />
                                    <p className="err">{error}</p>
                                </div>
                                <div className="field">
                                    <div>
                                        <div className="choose-p">
                                            Mindestens sechs Zeichen (Groß- und Kleinschreibung beachten) mit mindestens einer Zahl oder einem Sonderzeichen.
                                        </div>
                                        <button type="submit" className="join-btn cp-btn ">REGISTRIEREN</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
