import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useState } from "react";
import AuthService from '../../store/services/auth.service';
import { encode } from 'base-64';
import notification from '../../config/notification';
import { Bars, ThreeDots } from "react-loader-spinner";
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { setUser } from '../../store/auth/authSlice';
import { resetTrack } from "../../store/trackSlice";
import { subscriptionThunk } from '../../store/subscriptionSlice';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useDocumentTitle } from '../../hooks/setDocumentTitle';
import STATUS from '../../store/status';
import SiteLogo from '../SiteLogo';


export default function Signin() {
    useDocumentTitle('Sign In');
    const { isAuthenticated, status } = useSelector((state) => state.authSlice);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [visibility, setVisibility] = useState(false);
    const [errorEmail, setErrorEmail] = useState('');
    const [errorPassword, setErrorPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const navigate = useNavigate();
    const location = useLocation();
    let previous_location = '/';
    if (location.state && location.state.from) {
        previous_location = location.state.from.pathname;
    } else {
        previous_location = '/';
    }
    useEffect(() => {
        if (isAuthenticated) {
            navigate(previous_location);
        }

    }, [isAuthenticated, navigate])

    useEffect(() => {
        var root = document.getElementById("root");
        root.removeAttribute("class");
    }, []);


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const user = await AuthService.login({
                email, password
            })

            const token = encode(user.data.data.token);
            // notification('message', 'Login successful',500);
            localStorage.setItem('_token', token);
            dispatch(setUser(user.data.data));
            dispatch(subscriptionThunk())
            dispatch(resetTrack());
            setVisibility(false);
            setErrorEmail('');
            setErrorPassword('');
            setError('');
            setLoading(true);
            return navigate(previous_location);

        } catch (er) {

            if (!er.response) {
                notification('error', 'Das hat nicht geklappt. Bitte versuche es später noch einmal.')
                return;
            }
            if (er.response.status === 422) {
                setError('');
                er.response.data.data.email ? setErrorEmail(er.response.data.data.email[0]) : setErrorEmail('');
                er.response.data.data.password ? setErrorPassword(er.response.data.data.password[0]) : setErrorPassword('');
            } else {
                setErrorEmail('');
                setErrorPassword('')
                setVisibility(true);
                er.response.data.message ? setError(er.response.data.message) : setError('');
            }
        }
    };
    if (status === STATUS.LOADING) {
        return (<div className="verify-email-page">
            <div className="loading-spinner-animation">
                <ThreeDots
                    height="80"
                    width="80"
                    radius="9"
                    color="#4fa94d"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                />
            </div>
        </div>);
    }
    return (
        <div className='login-page'>
            {
                loading ? <Bars
                    height="80"
                    width="80"
                    color="#ffffff"
                    ariaLabel="bars-loading"
                    wrapperStyle={{}}
                    wrapperClass="loading-spinner-animation"
                    visible={true}
                /> : ''
            }
            <div className="account-sec privacy-policy-wrp">
                <div className="container">
                    <div className="em-logo position-relative mb-4 ">
                        <Link to="/">
                            <SiteLogo />
                        </Link>
                        <div className="back-sec">
                            <Link to="/register"><img src="images/dn_ar.svg" alt="" /></Link>
                        </div>
                    </div>
                    <div className="reg-container reg-2">
                        <h3 className='login-title'>Melde dich mit deinen Daten an</h3>
                        <div className="l-form">
                            <form onSubmit={handleSubmit} className="login-form">
                                <div className="position-relative mail-sec2">
                                    <input
                                        id="email"
                                        name="email"
                                        onChange={event => setEmail(event.target.value)}
                                        value={email}
                                        className="j-email je-1"
                                        placeholder='E-Mail-Adresse'
                                    />
                                    <div className="err2">{errorEmail}</div>
                                </div>
                                <div className="position-relative pass-sec2">
                                    <input
                                        id="password"
                                        name="password"
                                        type="password"
                                        value={password}
                                        onChange={event => setPassword(event.target.value)}
                                        className="j-email"
                                        placeholder='Passwort'
                                    />
                                    <div className="err2">{errorPassword}</div>
                                </div>

                                {
                                    visibility ? (
                                        <div>
                                            <div className='err'>{error}</div>
                                            <Link to="/forgot-password" className='fr'>Passwort vergessen?</Link>
                                        </div>
                                    ) : ''
                                }
                                <button type='submit' className='join-btn f-btn'>WEITER</button>
                            </form>
                            <p className="small2">Du bist zum ersten Mal hier? Herzlich willkommen!</p>
                            <Link className="signin-btn" to="/join">JETZT REGISTRIEREN</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
