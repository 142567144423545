import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link, useNavigate } from 'react-router-dom';
import notification from "../../config/notification";
import { useDocumentTitle } from "../../hooks/setDocumentTitle";
import authService from "../../store/services/auth.service";
import SiteLogo from "../SiteLogo";

export default function ForgotPassword() {
    useDocumentTitle('Forgot Password');
    const [isDisabled, setDisabled] = useState(false);
    const email = useRef();
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const checkMail = async (e) => {
        e.preventDefault();
        setDisabled(true);
        setError(null);
        try {
            await authService.forgotPassword({
                email: email.current.value
            })
            notification('message', 'Wir haben dir einen Link zum Zurücksetzen deines Passwortes geschickt.');
        } catch (er) {
            if (er.response && er.response.status === 422) {
                er.response.data.data.email ? setError(er.response.data.data.email[0]) : setError('');
                setDisabled(false);
            } else {
                notification('error', 'Das hat nicht geklappt. Bitte versuche es später noch einmal.');
                setDisabled(false);
            }
        }
    };

    useEffect(() => {
        var root = document.getElementById("root");
        root.removeAttribute("class");
    }, []);


    return (
        <div className='register-page'>
            <div className="account-sec privacy-policy-wrp">
                <div className="container">
                    <div className="em-logo position-relative mb-4 ">
                        <Link to="/">
                            <SiteLogo />
                        </Link>
                    </div>
                    <div className="reg-container reg-2">
                        <div className="join-form">
                            <h3 className='form-title'>Gib deine E-Mail-Adresse ein</h3>
                            <form className="join" onSubmit={checkMail}>
                                <div className='form-field'>
                                    <input id='jMail' className='j-email' ref={email} />
                                    <p className="err">{error}</p>
                                </div>
                                <div className='form-field'>
                                    <div className="f-btn-section">

                                        <button type="submit" disabled={isDisabled} className="join-btn f-btn">WEITER</button>

                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
