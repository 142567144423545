import React, { useState, useEffect } from 'react';
import ProductsComponent from './ProductsComponent';
import { getCurrentTimeStamp, getDuration, lastUpdatedExceed, getBufferTime, getExpireTime, currencyFormatDE, formatArtistName } from '../../helpers/helpers';
import config from '../../config/config';
import { useDispatch, useSelector } from 'react-redux';
import { setTop2 } from '../../store/topBarSlice';
import Marquee from 'react-fast-marquee';


export default function MediaPlaylistComponent({ tracks, desc, contentId, album, artist, changeTrack, mediaId }) {
  const { contentId: contentIdActive } = useSelector((state) => state.playerSlice)
  const topBarSlice = useSelector((state) => state.topBarSlice)
  const [scroll, setScroll] = useState(false);
  const dispatch = useDispatch();


  const setBarTop = () => {
    let activeTrack = document.querySelector(".track-active2");
    if (activeTrack) {
      let topValue = activeTrack.dataset.top;
      dispatch(setTop2({
        top2: topValue
      }))
    }
  }

  useEffect(() => {
    const parentDiv = document.querySelector('#playlistParent');
    if (parentDiv) {
      const childDivs = parentDiv.querySelectorAll('.playlistChild');
      if (childDivs) {
        childDivs.forEach((childDiv) => {
          // const distance = childDiv.offsetTop;
          const distance = childDiv.offsetTop - childDiv.style.marginBottom;
          childDiv.setAttribute('data-top', distance);
        });

      }
    }
    setBarTop();
  }, [contentIdActive])





  return (
    <>
      <div className="offcanvas offcanvas-end" tabIndex="-1" id="mediaPlaylist" aria-labelledby="offcanvasRightLabel">
        <div className="offcanvas-body offcanvas-body2">
          <div className='media-desc-page'>

            <div className="m-desc-header sticky-top scrolled">
              <button data-bs-dismiss="offcanvas" aria-label="Close" className='m-desc-back'><img src="/images/back-btn2.png" alt="Back" /></button>
              <div className="m-albumm">{album}</div>
              <div className="m-artistt">{artist}</div>
            </div>
            <div className="m-desc-body">

              <div className="m-playlist">
                <div className="tracklist-open">
                  <div className="tracklist-btm-top">
                    <div className="tab-row" >
                      <div className="tab-col">Nr.</div>
                      <div className="tab-col">Titel & Artist</div>
                      <div className="tab-col duration-title">Dauer</div>
                    </div>
                  </div>
                  <div className="tab-btm-wp" id="playlistParent">
                    {tracks && tracks.map((track, i) => (
                      <div className={`playlistChild tab-row ${contentId == track.id ? "content-active track-active2 m-active" : ""}`} onClick={() => changeTrack(track, i)} id={`link${i}`} key={track.id}>
                        <div className="tab-col">{i + 1}.</div>
                        <div className="tab-col">
                          <h3 className="marque-text">{track.title}</h3>
                          <div className="marque-tracklist-text">


                            {track.title ?
                              (track.title.length > 30 ? <Marquee speed={30}>
                                <h3 style={{
                                  marginRight: "20px"
                                }}>
                                  {track.title}
                                </h3>
                              </Marquee> : <h3 >{track.title}</h3>)

                              : true}

                          </div>
                          <p className="tab-col-artist">{formatArtistName(track.artists)}</p>
                        </div>
                        <div className="tab-col tab-col-duration2">{getDuration(track.duration)}</div>

                      </div>

                    ))}

                    <div className='bottom-div  active' id='bottom2' style={{ top: topBarSlice.top2 + "px" }}></div>

                  </div>
                </div>
              </div>




            </div>

          </div>
          <ProductsComponent mediaId={mediaId} />
        </div>
      </div>



    </>
  )
}
