import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import homeService from './store/services/home.service';
import { useEffect } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import config from './config/config';
import { useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ThreeDots } from 'react-loader-spinner';


import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useDocumentTitle } from './hooks/setDocumentTitle';
import MediaCard from './Components/MediaCard';
import usePreviousPage from './hooks/usePreviousPage';

export default function Viewall() {

    var body = document.body;
    body.classList.remove("body_class");
    body.classList.add("artist-body");
    const [loading, setLoading] = useState(true);
    const [collection, setCollection] = useState(null);
    const [media, setMedia] = useState([])
    const [filterSelected, setFilterSelected] = useState(0);
    const { filters, fetched } = useSelector((state) => state.filterSlice);
    const [hasMore, setHasMore] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const navigate = useNavigate();
    const { id } = useParams();
    useDocumentTitle('collection');
    usePreviousPage(collection && collection.title);

    const fetchCollection = async () => {
        if (currentPage == 0) {
            setLoading(true);
            setMedia([])
        }
        setHasMore(false);
        try {
            let res = await homeService.collectionById(id, filterSelected, currentPage + 1)
            if (res.data.success == false) {
                return navigate('/');
            }
            if (res.data.data.media_arr && res.data.data.media_arr.data && res.data.data.media_arr.next_page_url) {
                setHasMore(true);
            }
            setCurrentPage((cpage) => cpage + 1);
            setCollection(res.data.data);
            setMedia((pre) => [...pre, ...res.data.data.media_arr.data]);
            setLoading(false);
        } catch (error) {
            // console.log(error);
        }
    }
    useEffect(() => {
        fetchCollection();
    }, [filterSelected]);

    const changeFilter = (filter) => () => {
        setCurrentPage(() => 0);
        setLoading(true);
        setMedia([]);
        setFilterSelected(filter);
    }

    if (loading) {
        return (
            <div></div>
            // <SkeletonTheme baseColor="#202020" highlightColor="#444">
            //     <div className="container">
            //         <div className="cat-top">
            //             <div className="heading3">
            //                 <Skeleton />
            //             </div>
            //             <div className="cat-ul">
            //                 <ul className="d-flex flex-wrap mb-3">
            //                     {
            //                         fetched ?
            //                             <li className={filterSelected == 0 ? 'active' : null} onClick={changeFilter(0)}>
            //                                 Alle
            //                             </li> : null
            //                     }
            //                     {
            //                         !fetched ?
            //                             <Skeleton />
            //                             : (
            //                                 filters.map((filter) => {
            //                                     return (
            //                                         <li key={filter.id} className={filterSelected == filter.id ? 'active' : null} onClick={changeFilter(filter.id)}>
            //                                             {filter.title}
            //                                         </li>
            //                                     )
            //                                 }))
            //                     }
            //                 </ul>
            //             </div>
            //             <div className="row loading-component">
            //                 <div className="col-20">
            //                     <Skeleton/>
            //                 </div>
            //                 <div className="col-20">
            //                     <Skeleton/>
            //                 </div>
            //                 <div className="col-20">
            //                     <Skeleton/>
            //                 </div>
            //                 <div className="col-20">
            //                     <Skeleton/>
            //                 </div>
            //                 <div className="col-20">
            //                     <Skeleton/>
            //                 </div>
            //             </div>
            //         </div>
            //     </div>
            // </SkeletonTheme>
        )
    }

    return (
        <div className='viewall-page appMain'>
            <div className="container">
                <div className="cat-top">
                    <div className="heading3">
                        {
                            collection && collection.icon && <img src={`${config.COLLECTION_URL}/${collection.icon}`} alt="" />
                        }
                        <h3>{collection && collection.title}</h3>
                    </div>
                    <div className="cat-ul">
                        <ul className="d-flex flex-wrap mb-3">
                            {
                                fetched ?
                                    <li className={filterSelected == 0 ? 'active' : null} onClick={changeFilter(0)}>
                                        Alle
                                    </li> : null
                            }
                            {
                                !fetched ?
                                    // <Skeleton width={100} height={50} />
                                    null
                                    : (
                                        filters.map((filter) => {
                                            return (
                                                <li key={filter.id} className={filterSelected == filter.id ? 'active' : null} onClick={changeFilter(filter.id)}>
                                                    {filter.title}
                                                </li>
                                            )
                                        }))
                            }
                        </ul>
                    </div>
                    {
                        media &&
                        <InfiniteScroll
                            dataLength={media.length}
                            next={fetchCollection}
                            hasMore={hasMore}
                            loader={<ThreeDots
                                height="80"
                                width="80"
                                radius="9"
                                color="#4fa94d"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                            />}
                        >


                            <div className="row">

                                {
                                    media.map((media) => {
                                        return (
                                            id == 'music' || id == 'film' ?
                                                <MediaCard hideIcon={true} media={media} key={media.id} />
                                                : <MediaCard media={media} key={media.id} />
                                        )
                                    })
                                }
                            </div>
                        </InfiniteScroll>
                    }
                </div>
            </div>
        </div>
    )
}
