import { createSlice } from "@reduxjs/toolkit";
import STATUS from "./status";


const initialState = {
    status: STATUS.IDLE,
    fetched: false,
    url: 'null',
    pip: false,
    playing: false,
    controls: false,
    light: true,
    volume: 0.7,
    muted: false,
    played: 0,
    loaded: 0,
    duration: 0,
    playbackRate: 1.0,
    loop: false,
    poster: '',
    time: '',
    index: 0,
    bgimage: '',
    isFull: false,
    title: null,
    like: false,
    redirect: false,
    filetype: null,
    last_played: null,
    isInitialize: false,
    contentId: 0,
    artist: null,
    iphonePlayer:false,
}

const playerSlice = createSlice({
    name: 'playerSlice',
    initialState,
    reducers: {
        resetPlayer: (state) => {
            state.status = STATUS.IDLE
            state.fetched = false
            state.url = null
            state.pip = false
            state.playing = false
            state.controls = false
            state.light = true
            state.volume = 0.7
            state.muted = false
            state.played = 0
            state.loaded = 0
            state.duration = 0
            state.playbackRate = 1.0
            state.loop = false
            state.poster = ''
            state.time = ''
            state.index = 0
            state.bgimage = ''
            state.isFull = false
            state.like = false
            state.title = null
            state.redirect = false
            state.filetype = null
            state.last_played = null
            state.contentId = 0
            state.artist = null
            state.iphonePlayer = false

        },
        setPlayer: (state, action) => {
            state.status = STATUS.SUCCESS
            state.fetched = true
            state.url = action.payload.url
            state.pip = action.payload.pip
            state.playing = action.payload.playing
            state.controls = action.payload.controls
            state.light = action.payload.light
            state.volume = action.payload.volume
            state.muted = action.payload.muted
            state.played = action.payload.played
            state.loaded = action.payload.loaded
            state.duration = action.payload.duration
            state.playbackRate = action.payload.playbackRate
            state.loop = action.payload.loop
            state.poster = action.payload.poster
            state.time = action.payload.time
            state.index = action.payload.index
            state.bgimage = action.payload.bgimage
            state.isFull = action.payload.isFull
            state.like = action.payload.like
            state.title = action.payload.title
            state.redirect = action.payload.redirect
            state.filetype = action.payload.filetype
            state.last_played = action.payload.last_played
            state.contentId = action.payload.contentId
            state.artist = action.payload.artist
            state.isInitialize = true
            state.iphonePlayer = action.payload.iphonePlayer
        }
    },

})

const { reducer } = playerSlice;
export const { resetPlayer, setPlayer } = playerSlice.actions
export default reducer;