import React from 'react';
import { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Bars } from 'react-loader-spinner';
import { Link, Navigate, useLocation } from 'react-router-dom';
import notification from '../../config/notification';
import { useDocumentTitle } from '../../hooks/setDocumentTitle';
import authService from '../../store/services/auth.service';
import SiteLogo from '../SiteLogo';
import ImageRenderer from '../ImageRenderer';
export default function Confirmation() {
    useDocumentTitle('Confirmation');
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    if (!location || !location.state || !location.state.email || !location.state.password) {
        return <Navigate to="/join" replace={true} />
    }
    const resendVerifyEmail = async () => {
        setLoading(true)
        try {
            let res = await authService.resendVerifyEmail({
                email: location.state.email
            })
            setLoading(false)
            notification('message', res.data.message)
        } catch (er) {
            setLoading(false)
            notification('error', 'Das erneute Senden der E-Mail hat nicht funktioniert. Bitte versuche es erneut.')
        }
    }
    return (
        <div className='confirmation-page'>
            {
                loading ? <Bars
                    height="80"
                    width="80"
                    color="#ffffff"
                    ariaLabel="bars-loading"
                    wrapperStyle={{}}
                    wrapperClass="loading-spinner-animation"
                    visible={true}
                /> : ''
            }
            <div className="account-sec privacy-policy-wrp">
                <div className="container">
                    <div className="em-logo position-relative mb-4 ">
                        <Link to="/">
                            <SiteLogo />
                        </Link>
                    </div>
                    <div className="reg-container reg-2">
                        <h3 className='c-title'>Du hast Post von uns!</h3>
                        <div className="confirm-txt">Bitte kontrolliere das Postfach von <span>{location.state.email}</span>, öffne unsere E-Mail und bestätige deine E-Mail-Adresse durch Klicken auf den Link darin. Wir sehen uns gleich wieder.</div>
                        <ImageRenderer
                            wrapperClassName="mail-logo1"
                            url="images/Email-logo.png" />
                        <div className="n-receive">Du hast keine E-Mail erhalten?</div>
                        <div className='send-again cursor-pointer' onClick={resendVerifyEmail}>ERNEUT SENDEN</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
