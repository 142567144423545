import React from 'react';
import '../../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from "react-router-dom";
import NotFound from '../../NotFound';
import homeService from '../../store/services/home.service';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import config from '../../config/config';
import LoadingComponent from '../LoadingComponent'
import { SkeletonTheme } from 'react-loading-skeleton';
import { useDocumentTitle } from '../../hooks/setDocumentTitle';
import ProductCard from '../ProductCard';
import SliderCard from '../Home/SliderCard';
import MediaDetails from './MediaDetails';
import { setTop1 } from '../../store/topBarSlice';
import SampleVideo from '../SampleVideo';
function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style }}
            onClick={onClick}
        > <img src={'/images/caret1.png'} alt="" /> </div>
    );
}
function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style }}
            onClick={onClick}
        ><img src={'/images/caret1.png'} alt="" /></div>
    );
}
function AudioBtm() {
    useDocumentTitle('Media');
    const { tracks } = useSelector((state) => state.mediaSlice)
    const { poster, contentId } = useSelector((state) => state.playerSlice)
    const { media } = useSelector((state) => state.mediaSlice)
    const topBarSlice = useSelector((state) => state.topBarSlice)
    const { mediaId } = useParams();
    const [products, setProducts] = useState(null);
    const [recommendations, setRecommendations] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loadingrec, setLoadingrec] = useState(false);
    var body = document.body;
    body.classList.add("body_class");
    body.classList.remove("artist-body");
    var elem = document.getElementById('player-component');
    if (typeof elem !== 'undefined' && elem !== null) {
        elem.style.display = 'block';
    }
    var tglclass2 = document.getElementById('tgl');
    if (typeof tglclass2 !== 'undefined' && tglclass2 !== null) {
        elem.style.display = 'block';
    }
    var cross = document.getElementById('cross');
    if (typeof cross !== 'undefined' && cross !== null) {
        cross.style.display = 'none';
    }
    const location = useLocation();
    const dispatch = useDispatch();
    let oldUrl = sessionStorage.getItem('oldUrl') ? sessionStorage.getItem('oldUrl') : '/';
    let backUrl = oldUrl;
    if (backUrl == location.pathname || backUrl == '/signin' || backUrl == 'register') {
        backUrl = '/';
    }
    const recent = {
        variableWidth: true,
        dots: false,
        arrows: true,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 5,
        swipeToSlide: true,
        cssEase: 'linear',
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1200,
                settings: "unslick"
            }
        ],
    };

    const stopSample2 = () => {
        const videoJs = document.querySelectorAll(".vjs-tech");
        if (videoJs) {
            videoJs.forEach((videoJs) => {
                videoJs.pause();
                videoJs.currentTime = 0;
            });
        }
    }

    const [matches, setMatches] = useState(
        window.matchMedia("(min-width: 1200px)").matches
    )
    useEffect(() => {
        window
            .matchMedia("(min-width: 1200px)")
            .addEventListener('change', e => setMatches(e.matches));
    }, []);
    const fetchProduct = async () => {
        setLoading(true);
        setProducts(null);
        try {
            let res = await homeService.getProduct({
                media_id: mediaId
            });
            // console.log(res.data);
            setProducts(res.data.data);
        } catch (error) {
            // console.log(error);
        }
        setLoading(false);
    }
    const fetchRecommendations = async () => {
        setLoadingrec(true);
        setRecommendations(null);
        try {
            let res = await homeService.getRecommendations({
                media_id: mediaId
            });
            // console.log(res.data);
            setRecommendations(res.data.data);
        } catch (error) {
            // console.log(error);
        }
        setLoadingrec(false);
    }
    const setBarTop = () => {
        let activeTrack = document.querySelector(".track-active");
        if (activeTrack) {
            let topValue = activeTrack.dataset.top;
            dispatch(setTop1({
                top1: topValue
            }))
        }
    }
    useEffect(() => {
        fetchProduct();
        fetchRecommendations();
    }, [mediaId])
    useEffect(() => {
        if (media.cover_image) {
            document.getElementById('mobileBg').style.backgroundImage = `url(${config.MEDIA_URL}/${media.cover_image})`;
        }
        const parentDiv = document.querySelector('#trackParent');
        if (parentDiv) {
            const childDivs = parentDiv.querySelectorAll('.trackChild');
            if (childDivs) {
                childDivs.forEach((childDiv) => {
                    // const distance = childDiv.offsetTop;
                    const distance = childDiv.offsetTop - childDiv.style.marginBottom;
                    childDiv.setAttribute('data-top', distance);
                });

            }
        }


    }, [media])
    useEffect(() => {

        setBarTop();
    }, [contentId])

    if (!tracks) {
        return <NotFound />
    }
    // if (loading) {
    //     return (
    //         // <SkeletonTheme baseColor="#202020" highlightColor="#444">
    //         //     <LoadingComponent />
    //         // </SkeletonTheme>
    //         <div></div>
    //     )
    // }
    // if (!products || products.length === 0) {
    //     return <></>
    // }
    return (
        <>
            <div className="audio-top-wrap" id="audiocomponent">
                <div className="container">
                    <MediaDetails mediaId={mediaId} location={location} backUrl={backUrl} />
                </div>
            </div>
            {
                loading ?
                    // <SkeletonTheme baseColor="#202020" highlightColor="#444">
                    //     <LoadingComponent />
                    // </SkeletonTheme>
                    <div></div>
                    :
                    !products || products.length === 0
                        ?
                        <></>
                        :
                        <div className="audio-wrap1 product">
                            <div className="container">
                                <div className='music-slider recent'>
                                    <div className="music-top">
                                        <div className="row">
                                            <div className="col-12 pl-smll-0">
                                                <div className="top-title position-relative mb-0 left-minus"><h3><b className='dont-break'>Dazu passend</b></h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="music-btm-slider neu al1 audio-btm pb-xl-0">
                                        <Slider {...recent}>
                                            {
                                                products && products.map((product) => {
                                                    return (
                                                        <ProductCard product={product} key={product.id} />
                                                    )
                                                })
                                            }
                                        </Slider>
                                    </div>
                                </div>
                            </div>
                        </div>
            }
            {
                loadingrec ?
                    // <SkeletonTheme baseColor="#202020" highlightColor="#444">
                    //     <LoadingComponent />
                    // </SkeletonTheme>
                    <div></div>
                    :
                    !recommendations || recommendations.length === 0
                        ?
                        <></>
                        :
                        <div className="audio-wrap1 product">
                            <div className="container">
                                <div className='music-slider recent'>
                                    <div className="music-top">
                                        <div className="row">
                                            <div className="col-12 pl-smll-0">
                                                <div className="top-title position-relative mb-0 left-minus"><h3><b className='dont-break'>Das könnte dir auch gefallen</b></h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="music-btm-slider neu al1 pb-2">
                                        <Slider {...recent}>
                                            {
                                                recommendations && recommendations.map((media) => {
                                                    return (
                                                        <SliderCard media={media} key={media.id} />
                                                    )
                                                })
                                            }
                                        </Slider>
                                    </div>
                                </div>
                            </div>
                        </div>
            }
            {/* =====sample video modal===== */}

            <div className={`modal fade ${media && media.sample_file_type == '1' ? 'audio-sample' : 'video-sample'}`} id="sampleModal2" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <button onClick={stopSample2} className="closeModal" data-bs-dismiss="modal" aria-label="Close"><i className="fa fa-times" aria-hidden="true"></i></button>
                        <div className="modal-body">
                            {
                                media && media.sample_file_type > 0 && media.sample_file ?
                                    <SampleVideo sampleFile={media.sample_file} /> : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default AudioBtm;
