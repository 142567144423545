import axios, { unAuthorizedAxios } from "../../axios";


class SubscriptionService {

    allSubscriptions() {
        return unAuthorizedAxios.get('subscriptions');
    }

    couponCheck(data) {
        return unAuthorizedAxios.post('check-coupon',data);
    }

    payment(data) {
        return unAuthorizedAxios.post('payment',data);
    }

    checkSubscription() {
        return axios.post('check-subscription');
    }

    cancelSubscription() {
        return axios.post('cancel-subscription');
    }

    changeSubscription(data) {
        return axios.post('change-subscription',data);
    }

    purchseMedia(data) {
        return axios.post('purchase-media', data);
    }

    getSubscriptionPrice() {
        return axios.get('get-subscription-price');
    }

    bookSubscription(data) {
        return axios.post('book-subscription',data);
    }

    getCurrentSubscription() {
        return axios.get('current-subscription');
    }

    getCardDetail() {
        return axios.get('user-cards');
    }

    deleteCard(token) {
        return axios.delete(`delete-card/${token}`);
    }

    changeCard(data) {
        return axios.post('change-card', data);
    }
    cancelDrawback() {
        return axios.post('cancel-drawback');
    }
    cancelBooking() {
        return axios.post('cancel-booking');
    }
   

}

export default new SubscriptionService();