import moment from "moment/moment";

export const getDuration = (seconds) => {

    let result = null;
    if (seconds > 3600) {
        result = new Date(seconds * 1000).toISOString().slice(11, 19); // "00:10:00" (hh:mm:ss)
    } else {
        result = new Date(seconds * 1000).toISOString().slice(14, 19); // "10:00" (mm:ss)
    }
    return result;
}

export const getCurrentTimeStamp = () => {
    return moment().format('YYYY-MM-DD HH:mm:ss');
}

export const lastUpdatedExceed = (time) => {
    let now = moment();
    let previousTime = moment(time);
    let difference = now.diff(previousTime); // return in milliseconds
    if (difference >= 60000) { // if difference more than 60 seconds
        return true;
    }
    return false;
}

export const getBufferTime = (time) => {
    let now = moment();
    let previousTime = moment(time);
    let difference = now.diff(previousTime); // return in milliseconds
    if (difference > 0) { // if difference more than 60 seconds
        return parseInt(difference / 1000);
    }
    return 0;
}

export const getExpireTime = () => {
    return moment().add(1, 'hours').format('YYYY-MM-DD HH:mm:ss');
}
export const isTimeExpired = () => {
    let time = localStorage.getItem('_expireTime') ? localStorage.getItem('_expireTime') : null;
    if (time) {
        let now = moment();
        let previousTime = moment(time);
        let difference = previousTime.diff(now); // return in milliseconds
        if (difference > 0) { // if difference more than 60 seconds
            return false
        }
    }
    return true;
}

export const currencyFormatDE = (num) => {
    num = Number(num);
    return (
        num
            .toFixed(2) // always two decimal digits
            .replace('.', ',') // replace decimal point character with ,
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ' €'
    ) // use . as a separator
}

export const formatDate = (date, type) => {
    const d = new Date(date);

    let formatedDate;
    switch (type) {
        case 1:
            formatedDate = moment(d).format('DD.MM.YYYY');
            break;

        default:
            formatedDate = moment(d).format('DD/MM/YYYY')
            break;
    }

    return formatedDate;
}

export const formatArtistName = (artisats) => {
    let artist_name = '';
    artisats.forEach(function (artist, idx, array) {
        if (idx == array.length - 1) {
            artist_name += artist.name
        } else {
            artist_name += artist.name + ', ';
        }
    });

    return artist_name;
}

