import React, { useState, useRef } from 'react';
import classnames from 'classnames';
import { useIntersection } from './intersectionObserver';
import './imageRenderer.css';

const ImageRenderer = ({ url, thumb, width, height, wrapperClassName }) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [isInView, setIsInView] = useState(false);
    const imgRef = useRef();
    useIntersection(imgRef, () => {
        setIsInView(true);
    });

    const handleOnLoad = () => {
        setIsLoaded(true);
    };
    return (
        <div
            className={classnames('lazy-load-image-background', 'blur', wrapperClassName, {
                ['lazy-load-image-loaded']: !!isLoaded
            })}
            ref={imgRef}
            style={{
                // paddingBottom: `${(height / width) * 100}%`,
                paddingBottom: '0%',
                width: 'auto'
            }}
        >
            {isInView && (
                <>
                    {/* <img
                        className={classnames('image', 'thumb', {
                            ['isLoaded']: !!isLoaded
                        })}
                        src={thumb}
                    /> */}
                    <img src={url} onLoad={handleOnLoad}
                    />
                </>
            )}
        </div>
    );
};

export default ImageRenderer;
