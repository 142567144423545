import React, { Component } from 'react'
import { Link, Navigate } from 'react-router-dom'
import { connect } from "react-redux";
import Duration from './Duration'
import ReactPlayer from 'react-player'
import Marquee from 'react-fast-marquee';
import { findDOMNode } from 'react-dom';
import screenfull from 'screenfull/dist/screenfull.js';
import { getCurrentTimeStamp, getDuration, lastUpdatedExceed, getBufferTime, getExpireTime, currencyFormatDE, formatArtistName } from '../../helpers/helpers';
import config from '../../config/config';
import { resetCurrentTrack, updateCurrentTrack, updateTrackThunk } from '../../store/currentTrackSlice';
import notification from '../../config/notification';
import homeService from '../../store/services/home.service';
import { setPlayer } from '../../store/playerSlice';
import STATUS from '../../store/status';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import SampleVideo from '../SampleVideo';
// import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { RWebShare } from "react-web-share";
import { setTrack } from '../../store/trackSlice';
import { setLikeMedia } from '../../store/mediaSlice';
import MediaDescComponent from './MediaDescComponent';



class MediaDetails extends Component {

    load = url => {
        this.props.setPlayer({
            ...this.props.playerSlice,
            url,
            // played: 0,
            // loaded: 0,
            pip: false,
        })

    }

    constructor(props) {
        super(props)
        this.state = {
            matches: window.matchMedia("(min-width: 1200px)").matches,
            showDesc: true,
            isMoreTrack: false,
        };
    }

    // -------------------

    componentDidMount() {

        // function checkScr() {
        //     let full = document.querySelector('#full');
        //     let fullBtn = document.querySelector('.full-btn');
        //     let closeBtn = document.querySelector('.close-btn')
        //     if (document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement) {
        //         full.classList.add('FullScreen');
        //         fullBtn.style.display = "none";
        //         closeBtn.style.display = "block";

        //     }
        //     else {
        //         if (full && full.classList.contains("FullScreen")) {
        //             full.classList.remove('FullScreen');
        //         }
        //         if (fullBtn) {
        //             fullBtn.style.display = "block";
        //         }
        //         if (closeBtn) {
        //             closeBtn.style.display = "none";
        //         }
        //     }
        // }

        // this.interValId = setInterval(checkScr, 100);

        // ------------------
        const handler = e => this.setState({ matches: e.matches });
        window.matchMedia("(min-width: 1200px)").addEventListener('change', handler);

        var cross = document.getElementById('cross');
        if (typeof cross !== 'undefined' && cross !== null) {
            cross.style.display = 'none';

        }


        document.addEventListener("visibilitychange", this.handleVisibilityChange);

    }

    componentWillUnmount() {
        document.removeEventListener("visibilitychange", this.handleVisibilityChange);
        clearInterval(this.interValId);
    }

    handleVisibilityChange = () => {
        let video = document.querySelector('video');
        if (video) {
            if (document.hidden) {
                if (this.props.playerSlice.playing) {
                    video.play();
                }
            }
        }
    };

    showDesc = () => {
        this.setState({ showDesc: true }, () => {
            //   console.log('success');
        });
    }
    showMoreTrack = () => {
        this.setState({ isMoreTrack: true });
    }


    componentDidUpdate() {

        if (this.state.BufferLogTime && navigator.onLine) {
            this.props.updateTrackThunk({
                ...this.state.BufferLogTime,

            });
            console.log('updated');
            this.setState({ BufferLogTime: null })
        }

    }

    checkPermission() {
        if (!this.props.isAuthenticated) {
            this.setState({ redirect: true })
            return false;
        }
        if (this.props.subscriptionStatus != STATUS.SUCCESS) {
            return false;
        }

        if (this.props.isExpired) {
            this.setState({ subRedirect: true })
            return false;
        }

        if (!this.props.canPlay) {
            notification('info', 'Bitte kaufe diesen Inhalt, um ihn wiedergeben zu können.');
            // this.setState({ paidRedirect: true })
            return false;
        }

        return true;
    }

    hideControl = () => {
        if (document.querySelector('#full').classList.contains('FullScreen')) {
            document.querySelector('#full').classList.add('hide');
        }
    }

    showControl = () => {
        if (document.querySelector('#Rplayer')) {
            if (document.querySelector('#full').classList.contains('FullScreen')) {
                if (document.querySelector('#full').classList.contains('hide')) {
                    document.querySelector('#full').classList.remove('hide');
                    let timer;
                    clearTimeout(timer);
                    timer = setTimeout(this.hideControl, 3000);
                }
            }
        }
    }

    openPlyr = (backgroundUrl) => {

        const plyr_body = document.querySelector(".mobile-music-plyr");
        if (plyr_body.classList.contains("not-active")) {
            plyr_body.classList.remove("not-active")
            plyr_body.classList.add("active");
        }

        document.body.classList.add("mini-active");
        document.querySelector('#miniBg').style.backgroundImage = `url('${backgroundUrl}')`;

        setTimeout(() => {
            window.scrollTo({
                top: 0,
                behavior: 'auto'
            });
        }, 100);
    }

    changeTrack = (element, i, event) => {

        const idRoot = document.getElementById("root");
        if (idRoot && !idRoot.classList.contains("mActivated")) {
            idRoot.classList.add("mActivated")
        }

        let permission = this.checkPermission();
        if (!permission) {
            return;
        }



        this.props.setTrack({
            ...this.props.mediaSlice
        })

        let isiphonePlayer = false
        let isFull = element.filetype == '1' ? false : true;
        if (isFull) {
            if (this.state.matches) { //desktop
                screenfull.request(findDOMNode(document.getElementById("Rplayer")));
                setTimeout(this.hideControl, 3000);
            } else { //mobile
                isFull = false;
                this.openPlyr(`${config.MEDIA_URL}/${this.props.media.cover_image}`);
                // if (document.getElementById("Rplayer").requestFullscreen) {
                //     document.getElementById("Rplayer").requestFullscreen();

                //     let full = document.querySelector('#full');
                //     let fullBtn = document.querySelector('.full-btn');
                //     let closeBtn = document.querySelector('.close-btn');
                //     full.classList.add('FullScreen');
                //     fullBtn.style.display = "none";
                //     closeBtn.style.display = "block";

                // } else if (document.querySelector('video') && document.querySelector('video').webkitEnterFullscreen) { //for iphone
                //     isFull = false;
                //     // isiphonePlayer = true;
                //     // document.querySelector('video').webkitEnterFullscreen();
                // }
            }
        }

        this.props.setPlayer({
            ...this.props.playerSlice,
            url: element.original_source,
            poster: `${config.MEDIA_URL}/${this.props.media.cover_image}`,
            bgimage: element.bgimage,
            time: getDuration(element.duration),
            title: element.title, index: i,
            artist: formatArtistName(element.artists),
            playing: true,
            filetype: element.filetype,
            contentId: element.id,
            played: 0,
            like: this.props.mediaSlice.like,
            isFull: isFull,
            iphonePlayer: isiphonePlayer
        })
        window.sessionStorage.setItem("previousUrl", `/media/${this.props.mediaId}`);

        let playedSeconds = this.props.playerSlice.played * this.props.playerSlice.duration

        if (this.props.playerSlice.playing) {
            if (this.props.currentTrackInfo.contentId > 0) {
                this.props.updateTrackThunk({
                    ...this.props.currentTrackInfo,
                    endTimeStamp: getCurrentTimeStamp(),
                    endPosition: playedSeconds
                });
            }
        }

        this.props.updateCurrentTrack({
            contentId: element.id,
            startTimeStamp: getCurrentTimeStamp(),
            endTimeStamp: null,
            startPosition: 0,
            endPosition: 0,
            buffer: 0
        });

        this.props.updateTrackThunk({
            contentId: element.id,
            startTimeStamp: getCurrentTimeStamp(),
            endTimeStamp: null,
            startPosition: 0,
            endPosition: 0,
            buffer: 0
        });




    }

    continuePlay = () => {
        const idRoot = document.getElementById("root");
        if (idRoot && !idRoot.classList.contains("mActivated")) {
            idRoot.classList.add("mActivated")
        }
        let permission = this.checkPermission();
        if (!permission) {
            return;
        }
        if (this.props.tracks && this.props.tracks.length > 0) {


            let lastPlayedId = 0;
            if (this.props.media.last_played) {
                lastPlayedId = this.props.tracks.findIndex(x => x.id == this.props.media.last_played.content_id);
            }
            lastPlayedId = lastPlayedId > 0 ? lastPlayedId : 0;

            if (this.props.playerSlice.contentId == this.props.tracks[lastPlayedId].id) { //Same track playing
                this.props.setPlayer({
                    ...this.props.playerSlice,
                    played: this.props.tracks[lastPlayedId].last_played ? parseFloat(this.props.tracks[lastPlayedId].last_played.endPosition / this.props.tracks[lastPlayedId].duration) : 0,
                    like: this.props.mediaSlice.like,
                })
                // this.player.seekTo(this.props.tracks[lastPlayedId].last_played ? parseFloat(this.props.tracks[lastPlayedId].last_played.endPosition / this.props.tracks[lastPlayedId].duration) : 0)
                return;
            }

            this.props.setTrack({
                ...this.props.mediaSlice
            })


            let isiphonePlayer = false
            let isFull = this.props.tracks[lastPlayedId].filetype == '1' ? false : true;
            if (isFull) {
                if (this.state.matches) { //desktop
                    screenfull.request(findDOMNode(document.getElementById("Rplayer")));
                    setTimeout(this.hideControl, 3000);
                } else { //mobile
                    isFull = false;
                    this.openPlyr(`${config.MEDIA_URL}/${this.props.media.cover_image}`);

                    // if (document.getElementById("Rplayer").requestFullscreen) {
                    //     document.getElementById("Rplayer").requestFullscreen();

                    //     let full = document.querySelector('#full');
                    //     let fullBtn = document.querySelector('.full-btn');
                    //     let closeBtn = document.querySelector('.close-btn');
                    //     full.classList.add('FullScreen');
                    //     fullBtn.style.display = "none";
                    //     closeBtn.style.display = "block";

                    // } else if (document.querySelector('video') && document.querySelector('video').webkitEnterFullscreen) { //for iphone
                    //     isFull = false;
                    //     // isiphonePlayer = true;
                    //     // document.querySelector('video').webkitEnterFullscreen();
                    // }
                }
            }

            this.props.setPlayer({
                ...this.props.playerSlice,
                url: this.props.tracks[lastPlayedId].original_source,
                poster: `${config.MEDIA_URL}/${this.props.media.cover_image}`,
                time: getDuration(this.props.tracks[lastPlayedId].duration),
                bgimage: `${config.MEDIA_URL}/${this.props.media.cover_image}`,
                title: this.props.tracks[lastPlayedId].title,
                index: lastPlayedId,
                volume: 0.7,
                muted: false,
                played: this.props.tracks[lastPlayedId].last_played ? parseFloat(this.props.tracks[lastPlayedId].last_played.endPosition / this.props.tracks[lastPlayedId].duration) : 0,
                playing: true,
                duration: this.props.tracks[lastPlayedId].duration,
                like: this.props.mediaSlice.like,
                filetype: this.props.tracks[lastPlayedId].filetype,
                contentId: this.props.tracks[lastPlayedId].id,
                last_played: this.props.tracks[lastPlayedId].last_played,
                artist: formatArtistName(this.props.tracks[lastPlayedId].artists),
                isFull: isFull,
                iphonePlayer: isiphonePlayer
            })

            window.sessionStorage.setItem("previousUrl", `/media/${this.props.mediaId}`);

            let playedSeconds = this.props.playerSlice.played * this.props.playerSlice.duration

            if (this.props.playerSlice.playing) {
                if (this.props.currentTrackInfo.contentId > 0) {
                    this.props.updateTrackThunk({
                        ...this.props.currentTrackInfo,
                        endTimeStamp: getCurrentTimeStamp(),
                        endPosition: playedSeconds
                    });
                }
            }

            this.props.updateCurrentTrack({
                contentId: this.props.tracks[lastPlayedId].id,
                startTimeStamp: getCurrentTimeStamp(),
                endTimeStamp: null,
                startPosition: 0,
                endPosition: 0,
                buffer: 0
            });

            this.props.updateTrackThunk({
                contentId: this.props.tracks[lastPlayedId].id,
                startTimeStamp: getCurrentTimeStamp(),
                endTimeStamp: null,
                startPosition: 0,
                endPosition: 0,
                buffer: 0
            });
        }

    }

    restartPlay = () => {
        const idRoot = document.getElementById("root");
        if (idRoot && !idRoot.classList.contains("mActivated")) {
            idRoot.classList.add("mActivated")
        }
        let permission = this.checkPermission();
        if (!permission) {
            return;
        }
        if (this.props.tracks && this.props.tracks.length > 0) {


            let lastPlayedId = 0;

            if (this.props.playerSlice.contentId == this.props.tracks[lastPlayedId].id) { //Same track playing
                this.props.setPlayer({
                    ...this.props.playerSlice,
                    played: 0,
                    like: this.props.mediaSlice.like,
                })
                // this.player.seekTo(0)
                return;
            }

            this.props.setTrack({
                ...this.props.mediaSlice
            })

            let isiphonePlayer = false
            let isFull = this.props.tracks[lastPlayedId].filetype == '1' ? false : true;
            if (isFull) {
                if (this.state.matches) { //desktop
                    screenfull.request(findDOMNode(document.getElementById("Rplayer")));
                    setTimeout(this.hideControl, 3000);
                } else { //mobile
                    isFull = false;
                    this.openPlyr(`${config.MEDIA_URL}/${this.props.media.cover_image}`);

                    // if (document.getElementById("Rplayer").requestFullscreen) {
                    //     document.getElementById("Rplayer").requestFullscreen();

                    //     let full = document.querySelector('#full');
                    //     let fullBtn = document.querySelector('.full-btn');
                    //     let closeBtn = document.querySelector('.close-btn');
                    //     full.classList.add('FullScreen');
                    //     fullBtn.style.display = "none";
                    //     closeBtn.style.display = "block";

                    // } else if (document.querySelector('video') && document.querySelector('video').webkitEnterFullscreen) { //for iphone
                    //     isFull = false;
                    //     // isiphonePlayer = true;
                    //     // document.querySelector('video').webkitEnterFullscreen();
                    // }
                }
            }

            this.props.setPlayer({
                ...this.props.playerSlice,
                url: this.props.tracks[lastPlayedId].original_source,
                poster: `${config.MEDIA_URL}/${this.props.media.cover_image}`,
                time: getDuration(this.props.tracks[lastPlayedId].duration),
                bgimage: `${config.MEDIA_URL}/${this.props.media.cover_image}`,
                title: this.props.tracks[lastPlayedId].title,
                index: lastPlayedId,
                volume: 0.7,
                muted: false,
                played: 0,
                playing: true,
                duration: this.props.tracks[lastPlayedId].duration,
                like: this.props.mediaSlice.like,
                filetype: this.props.tracks[lastPlayedId].filetype,
                contentId: this.props.tracks[lastPlayedId].id,
                last_played: this.props.tracks[lastPlayedId].last_played,
                artist: formatArtistName(this.props.tracks[lastPlayedId].artists),
                isFull: isFull,
                iphonePlayer: isiphonePlayer
            })

            window.sessionStorage.setItem("previousUrl", `/media/${this.props.mediaId}`);

            let playedSeconds = this.props.playerSlice.played * this.props.playerSlice.duration

            if (this.props.playerSlice.playing) {
                if (this.props.currentTrackInfo.contentId > 0) {
                    this.props.updateTrackThunk({
                        ...this.props.currentTrackInfo,
                        endTimeStamp: getCurrentTimeStamp(),
                        endPosition: playedSeconds
                    });
                }
            }

            this.props.updateCurrentTrack({
                contentId: this.props.tracks[lastPlayedId].id,
                startTimeStamp: getCurrentTimeStamp(),
                endTimeStamp: null,
                startPosition: 0,
                endPosition: 0,
                buffer: 0
            });

            this.props.updateTrackThunk({
                contentId: this.props.tracks[lastPlayedId].id,
                startTimeStamp: getCurrentTimeStamp(),
                endTimeStamp: null,
                startPosition: 0,
                endPosition: 0,
                buffer: 0
            });
        }

    }
    // next = () => {
    //     let permission = this.checkPermission();
    //     if (!permission) {
    //         return;
    //     }
    //     let currentTrack = parseInt(this.props.playerSlice.index);

    //     let nextTrack = currentTrack === this.props.tracks.length - 1 ? 0 : currentTrack + 1;
    //     this.props.setPlayer({
    //         ...this.props.playerSlice,
    //         url: this.props.tracks[nextTrack].original_source,
    //         poster: `${config.MEDIA_URL}/${this.props.media.cover_image}`,
    //         time: getDuration(this.props.tracks[nextTrack].duration),
    //         bgimage: `${config.MEDIA_URL}/${this.props.media.cover_image}`,
    //         title: this.props.tracks[nextTrack].title,
    //         artist: formatArtistName(this.props.tracks[nextTrack].artists),
    //         index: nextTrack,
    //         filetype: this.props.tracks[nextTrack].filetype,
    //         contentId: this.props.tracks[nextTrack].id,
    //         played: 0
    //     })

    //     let playedSeconds = this.props.playerSlice.played * this.props.playerSlice.duration

    //     if (this.props.playerSlice.playing) {

    //         if (this.props.currentTrackInfo.contentId > 0) {
    //             this.props.updateTrackThunk({
    //                 ...this.props.currentTrackInfo,
    //                 endTimeStamp: getCurrentTimeStamp(),
    //                 endPosition: playedSeconds
    //             });
    //         }

    //         this.props.updateTrackThunk({
    //             contentId: this.props.tracks[nextTrack].id,
    //             startTimeStamp: getCurrentTimeStamp(),
    //             endTimeStamp: null,
    //             startPosition: 0,
    //             endPosition: 0,
    //             buffer: 0
    //         });
    //     }

    //     this.props.updateCurrentTrack({
    //         contentId: this.props.tracks[nextTrack].id,
    //         startTimeStamp: getCurrentTimeStamp(),
    //         endTimeStamp: null,
    //         startPosition: 0,
    //         endPosition: 0,
    //         buffer: 0
    //     });

    //     /**8 tracklist animation */

    //     if (nextTrack === 0) {
    //         document.getElementById("bottom").style.top = "0px";
    //     }
    //     let sum = 0;
    //     for (let k = 0; k < nextTrack; k++) {
    //         let clientHeight1 = document.querySelector('#link' + k).clientHeight;
    //         let clientHeight2 = document.querySelector('#link' + nextTrack).clientHeight;
    //         document.getElementById("bottom").style.height = clientHeight2 + "px";
    //         sum += clientHeight1;
    //         document.getElementById("bottom").style.top = sum + "px";
    //     }
    // }

    // handleEnded = () => {
    //     let permission = this.checkPermission();
    //     if (!permission) {
    //         return;
    //     }
    //     let currentTrack = parseInt(this.props.playerSlice.index);
    //     let nextTrack = currentTrack === this.props.tracks.length - 1 ? 0 : currentTrack + 1;

    //     if (nextTrack == 0) {
    //         this.props.setPlayer({
    //             ...this.props.playerSlice,
    //             playing: false
    //         })
    //     } else {
    //         this.props.setPlayer({
    //             ...this.props.playerSlice,
    //             url: this.props.tracks[nextTrack].original_source,
    //             poster: `${config.MEDIA_URL}/${this.props.media.cover_image}`,
    //             time: getDuration(this.props.tracks[nextTrack].duration),
    //             bgimage: `${config.MEDIA_URL}/${this.props.media.cover_image}`,
    //             title: this.props.tracks[nextTrack].title,
    //             artist: formatArtistName(this.props.tracks[nextTrack].artists),
    //             index: nextTrack,
    //             playing: true,
    //             filetype: this.props.tracks[nextTrack].filetype,
    //             contentId: this.props.tracks[nextTrack].id,
    //             played: 0
    //         })
    //     }


    //     let playedSeconds = this.props.playerSlice.played * this.props.playerSlice.duration

    //     if (this.props.playerSlice.playing) {
    //         if (this.props.currentTrackInfo.contentId > 0) {
    //             this.props.updateTrackThunk({
    //                 ...this.props.currentTrackInfo,
    //                 endTimeStamp: getCurrentTimeStamp(),
    //                 endPosition: playedSeconds
    //             });
    //         }
    //     }

    //     if (nextTrack != 0) {
    //         this.props.updateTrackThunk({
    //             contentId: this.props.tracks[nextTrack].id,
    //             startTimeStamp: getCurrentTimeStamp(),
    //             endTimeStamp: null,
    //             startPosition: 0,
    //             endPosition: 0,
    //             buffer: 0
    //         });

    //         this.props.updateCurrentTrack({
    //             contentId: this.props.tracks[nextTrack].id,
    //             startTimeStamp: getCurrentTimeStamp(),
    //             endTimeStamp: null,
    //             startPosition: 0,
    //             endPosition: 0,
    //             buffer: 0
    //         });
    //     }




    //     // this.setState({ playing: true });
    //     /**8 tracklist animation */

    //     if (nextTrack === 0) {
    //         document.getElementById("bottom").style.top = "0px";
    //     }
    //     let sum = 0;
    //     for (let k = 0; k < nextTrack; k++) {
    //         let clientHeight1 = document.querySelector('#link' + k).clientHeight;
    //         let clientHeight2 = document.querySelector('#link' + nextTrack).clientHeight;
    //         document.getElementById("bottom").style.height = clientHeight2 + "px";
    //         sum += clientHeight1;
    //         document.getElementById("bottom").style.top = sum + "px";
    //     }
    // }
    // previous = () => {
    //     let permission = this.checkPermission();
    //     if (!permission) {
    //         return;
    //     }
    //     let currentTrack = parseInt(this.props.playerSlice.index);
    //     let prevTrack = currentTrack === 0 ? this.props.tracks.length - 1 : currentTrack - 1;
    //     this.props.setPlayer({
    //         ...this.props.playerSlice,
    //         url: this.props.tracks[prevTrack].original_source,
    //         poster: `${config.MEDIA_URL}/${this.props.media.cover_image}`,
    //         time: getDuration(this.props.tracks[prevTrack].duration),
    //         bgimage: `${config.MEDIA_URL}/${this.props.media.cover_image}`,
    //         title: this.props.tracks[prevTrack].title,
    //         artist: formatArtistName(this.props.tracks[prevTrack].artists),
    //         index: prevTrack,
    //         filetype: this.props.tracks[prevTrack].filetype,
    //         contentId: this.props.tracks[prevTrack].id,
    //         played: 0,
    //     })

    //     let playedSeconds = this.props.playerSlice.played * this.props.playerSlice.duration

    //     if (this.props.playerSlice.playing) {
    //         if (this.props.currentTrackInfo.contentId > 0) {
    //             this.props.updateTrackThunk({
    //                 ...this.props.currentTrackInfo,
    //                 endTimeStamp: getCurrentTimeStamp(),
    //                 endPosition: playedSeconds
    //             });
    //         }

    //         this.props.updateTrackThunk({
    //             contentId: this.props.tracks[prevTrack].id,
    //             startTimeStamp: getCurrentTimeStamp(),
    //             endTimeStamp: null,
    //             startPosition: 0,
    //             endPosition: 0,
    //             buffer: 0
    //         });
    //     }

    //     this.props.updateCurrentTrack({
    //         contentId: this.props.tracks[prevTrack].id,
    //         startTimeStamp: getCurrentTimeStamp(),
    //         endTimeStamp: null,
    //         startPosition: 0,
    //         endPosition: 0,
    //         buffer: 0
    //     });

    //     /**8 tracklist animation */

    //     if (prevTrack === 0) {
    //         document.getElementById("bottom").style.top = "0px";
    //     }
    //     let sum = 0;
    //     for (let k = 0; k < prevTrack; k++) {
    //         let clientHeight1 = document.querySelector('#link' + k).clientHeight;
    //         let clientHeight2 = document.querySelector('#link' + prevTrack).clientHeight;
    //         document.getElementById("bottom").style.height = clientHeight2 + "px";
    //         sum += clientHeight1;
    //         document.getElementById("bottom").style.top = sum + "px";
    //     }
    // }

    // // -----

    // stopSample = () => {
    //     document.querySelectorAll(".vjs-tech")[0].pause();
    //     document.querySelectorAll(".vjs-tech")[0].currentTime = 0;
    // }

    // hideControl = () => {
    //     if (document.querySelector('#full').classList.contains('FullScreen')) {
    //         document.querySelector('#full').classList.add('hide');
    //     }
    // }

    // showControl = () => {
    //     if (document.querySelector('#Rplayer')) {
    //         if (document.querySelector('#full').classList.contains('FullScreen')) {
    //             if (document.querySelector('#full').classList.contains('hide')) {
    //                 document.querySelector('#full').classList.remove('hide');
    //                 let timer;
    //                 clearTimeout(timer);
    //                 timer = setTimeout(this.hideControl, 3000);
    //             }
    //         }
    //     }
    // }


    // handleClickFullscreen = () => {
    //     screenfull.request(findDOMNode(document.getElementById("Rplayer")))
    //     this.props.setPlayer({
    //         ...this.props.playerSlice,
    //         isFull: true
    //     })
    //     setTimeout(this.hideControl, 3000);
    // }

    // closeFullscreen = () => {
    //     document.exitFullscreen()
    //     this.props.setPlayer({
    //         ...this.props.playerSlice,
    //         isFull: false
    //     })
    // }
    // // -----


    // handlePlayPause = () => {
    //     let permission = this.checkPermission();
    //     if (!permission) {
    //         return;
    //     }
    //     // let playedSeconds = this.props.playerSlice.played * this.props.playerSlice.duration
    //     let currentTrack = parseInt(this.props.playerSlice.index);
    //     if (this.props.tracks[currentTrack]) {
    //         if (this.props.playerSlice.playing) { // if clicked pause send the previous data and reset the counter

    //             if (this.props.currentTrackInfo.contentId > 0) {
    //                 this.props.updateTrackThunk({
    //                     ...this.props.currentTrackInfo,
    //                     endTimeStamp: getCurrentTimeStamp(),
    //                     endPosition: this.player.getCurrentTime()
    //                 });
    //             }
    //             this.props.resetCurrentTrack();

    //         } else {

    //             //update the state
    //             this.props.updateCurrentTrack({
    //                 contentId: this.props.tracks[currentTrack].id,
    //                 startTimeStamp: getCurrentTimeStamp(),
    //                 endTimeStamp: null,
    //                 startPosition: this.player.getCurrentTime(),
    //                 endPosition: 0,
    //                 buffer: 0,
    //             });
    //             //update the database
    //             this.props.updateTrackThunk({
    //                 contentId: this.props.tracks[currentTrack].id,
    //                 startTimeStamp: getCurrentTimeStamp(),
    //                 endTimeStamp: null,
    //                 startPosition: this.player.getCurrentTime(),
    //                 endPosition: 0,
    //                 buffer: 0,
    //             });

    //         }
    //     }

    //     this.props.setPlayer({
    //         ...this.props.playerSlice,
    //         playing: !this.props.playerSlice.playing
    //     })



    // }

    handleStop = () => {
        this.props.setPlayer({
            ...this.props.playerSlice,
            playing: false
        })
        let playedSeconds = this.props.playerSlice.played * this.props.playerSlice.duration
        let currentTrack = parseInt(this.props.playerSlice.index);
        if (this.props.tracks[currentTrack]) {
            if (this.props.currentTrackInfo.contentId > 0) {
                this.props.updateTrackThunk({
                    ...this.props.currentTrackInfo,
                    endTimeStamp: getCurrentTimeStamp(),
                    endPosition: playedSeconds
                });
            }
            this.props.resetCurrentTrack();
        }
        // if (document.getElementById("home-player")) {
        //     document.querySelector("#Rplayer").style.display = "none";
        //     document.querySelector("#root").removeAttribute("style");
        // }
    }

    // handleToggleControls = () => {
    //     let permission = this.checkPermission();
    //     if (!permission) {
    //         return;
    //     }
    //     const url = this.props.playerSlice.url
    //     this.props.setPlayer({
    //         ...this.props.playerSlice,
    //         controls: !this.props.playerSlice.controls,
    //         url: url,
    //         played: 0,
    //         loaded: 0,
    //         pip: false,
    //     })
    // }

    // handleToggleLight = () => {
    //     let permission = this.checkPermission();
    //     if (!permission) {
    //         return;
    //     }
    //     this.props.setPlayer({
    //         ...this.props.playerSlice,
    //         light: this.props.playerSlice.light
    //     })
    // }

    // handleToggleLoop = () => {
    //     let permission = this.checkPermission();
    //     if (!permission) {
    //         return;
    //     }
    //     this.props.setPlayer({
    //         ...this.props.playerSlice,
    //         loop: !this.props.playerSlice.loop
    //     })
    // }

    // handleVolumeChange = e => {
    //     this.props.setPlayer({
    //         ...this.props.playerSlice,
    //         volume: parseFloat(e.target.value),
    //         muted: false
    //     })
    // }

    // handleToggleMuted = () => {
    //     let permission = this.checkPermission();
    //     if (!permission) {
    //         return;
    //     }
    //     this.props.setPlayer({
    //         ...this.props.playerSlice,
    //         muted: !this.props.playerSlice.muted
    //     })
    // }

    // handleSetPlaybackRate = e => {
    //     let permission = this.checkPermission();
    //     if (!permission) {
    //         return;
    //     }
    //     this.props.setPlayer({
    //         ...this.props.playerSlice,
    //         playbackRate: parseFloat(e.target.value)
    //     })
    // }

    // handleOnPlaybackRateChange = (speed) => {
    //     let permission = this.checkPermission();
    //     if (!permission) {
    //         return;
    //     }
    //     this.props.setPlayer({
    //         ...this.props.playerSlice,
    //         playbackRate: parseFloat(speed)
    //     })
    // }

    // handleTogglePIP = () => {
    //     let permission = this.checkPermission();
    //     if (!permission) {
    //         return;
    //     }
    //     this.props.setPlayer({
    //         ...this.props.playerSlice,
    //         pip: !this.props.playerSlice.pip
    //     })
    // }

    // handlePlay = () => {
    //     // let permission = this.checkPermission();
    //     // if (!permission) {
    //     //   return;
    //     // }
    //     // this.props.setPlayer({
    //     //   ...this.props.playerSlice,
    //     //   playing: true
    //     // })
    // }

    // handleEnablePIP = () => {
    //     let permission = this.checkPermission();
    //     if (!permission) {
    //         return;
    //     }
    //     this.props.setPlayer({
    //         ...this.props.playerSlice,
    //         pip: false
    //     })
    // }

    // handleDisablePIP = () => {
    //     let permission = this.checkPermission();
    //     if (!permission) {
    //         return;
    //     }
    //     this.props.setPlayer({
    //         ...this.props.playerSlice,
    //         pip: false
    //     })
    // }

    // handlePause = () => {
    //     let permission = this.checkPermission();
    //     if (!permission) {
    //         return;
    //     }
    //     this.props.setPlayer({
    //         ...this.props.playerSlice,
    //         playing: false
    //     })
    // }

    // handleSeekMouseDown = e => {
    //     let permission = this.checkPermission();
    //     if (!permission) {
    //         return;
    //     }
    //     this.props.setPlayer({
    //         ...this.props.playerSlice,
    //         seeking: true,
    //     })



    // }

    // handleSeekChange = e => {
    //     let permission = this.checkPermission();
    //     if (!permission) {
    //         return;
    //     }
    //     this.props.setPlayer({
    //         ...this.props.playerSlice,
    //         played: parseFloat(e.target.value)
    //     })
    //     this.player.seekTo(parseFloat(e.target.value))


    // }

    // handleSeekMouseUp = e => {
    //     let permission = this.checkPermission();
    //     if (!permission) {
    //         return;
    //     }
    //     this.props.setPlayer({
    //         ...this.props.playerSlice,
    //         seeking: false
    //     })
    //     this.player.seekTo(parseFloat(e.target.value))


    // }

    // handleProgress = state => {

    //     // console.log('onProgress', state)
    //     let playedSeconds = state.played * this.props.playerSlice.duration
    //     if (this.props.currentTrackInfo.endTimeStamp == null) {
    //         this.props.updateCurrentTrack({
    //             ...this.props.currentTrackInfo,
    //             endTimeStamp: getCurrentTimeStamp(),
    //             endPosition: playedSeconds,
    //         });
    //     } else if (lastUpdatedExceed(this.props.currentTrackInfo.endTimeStamp)) {
    //         this.props.updateCurrentTrack({
    //             ...this.props.currentTrackInfo,
    //             endTimeStamp: getCurrentTimeStamp(),
    //             endPosition: playedSeconds,
    //         });
    //         this.props.updateTrackThunk({
    //             ...this.props.currentTrackInfo,
    //             endTimeStamp: getCurrentTimeStamp(),
    //             endPosition: playedSeconds,
    //         });
    //     }
    //     // We only want to update time slider if we are not currently seeking

    //     if (!this.props.playerSlice.seeking) {
    //         this.props.setPlayer({
    //             ...this.props.playerSlice,
    //             played: state.played,
    //         })
    //     }
    //     localStorage.setItem('_expireTime', getExpireTime())
    // }

    // handleDuration = (duration) => {
    //     // console.log('onDuration', duration)
    //     this.props.setPlayer({
    //         ...this.props.playerSlice,
    //         duration: duration,
    //     })
    //     this.player.seekTo(this.props.playerSlice.played)
    // }

    // renderLoadButton = (url, label) => {
    //     return (
    //         <button onClick={() => this.load(url)}>
    //             {label}
    //         </button>
    //     )
    // }

    // ref = player => {
    //     this.player = player
    // }

    state = { isActive: true };
    state = { isTracklist: true };
    state = { ismqClass: true };
    state = { isReadMore: true };
    state = { networkError: false };
    state = { bufferTime: 0 };
    state = { BufferLogTime: null };


    toggleReadMore = () => {
        this.setState({ isReadMore: !this.state.isReadMore });
        this.setState({ isTracklist: false });
        var nm = document.getElementById('inner-music');
        if (!nm.classList.contains('video-type')) {
            nm.classList.add("toggle-music");
        }
    };



    ToggleClass = () => {
        this.setState({ isActive: !this.state.isActive });
        document.body.classList.toggle("toggled");
    };
    toggleTracklist = () => {
        this.setState({ isTracklist: !this.state.isTracklist });
        this.setState({ isReadMore: false });
        /*** for minimize player  */
        var nm = document.getElementById('inner-music');
        if (!nm.classList.contains('video-type')) {
            nm.classList.add("toggle-music");
        }



        // set active bar
        // if (document.querySelector('.m-active')) {
        //     document.getElementById("bottom").style.opacity = '0';
        //     let trackHeight = document.querySelector('.m-active').clientHeight;
        //     console.log(trackHeight);
        //     var list = document.getElementsByClassName('tab-row');
        //     for (var i = 0; i < list.length; i++) {
        //         if (list[i].classList.contains('m-active')) {
        //             console.log("trackId" + i);
        //             var top = trackHeight * (i - 1);
        //             document.getElementById("bottom").style.top = top + 'px';
        //             document.getElementById("bottom").style.opacity = '1';
        //         }
        //     }
        // }


    };
    // minimizeTracklist = () => {
    //     this.setState({ isTracklist: false });
    //     this.setState({ isReadMore: false });
    //     var tp = document.getElementById('inner-music');
    //     if (tp.classList.contains('toggle-music')) {
    //         tp.classList.remove('toggle-music');
    //     }
    // };
    // cross = () => {
    //     //stop current song
    //     this.props.setPlayer({
    //         ...this.props.playerSlice,
    //         playing: false
    //     })

    //     document.getElementById('music-player').classList.remove("not-active");
    //     document.getElementById('music-player').classList.add("active");
    //     document.getElementById('player-component').classList.remove("not-active");
    //     document.getElementById('player-component').classList.add("active");

    // };


    // forwind = () => {
    //     this.checkPermission();
    //     //stop current song
    //     var updatetime = this.player.getCurrentTime() + 10;
    //     // console.log(updatetime);
    //     this.props.setPlayer({
    //         ...this.props.playerSlice,
    //         seeking: false
    //     })
    //     this.player.seekTo(parseFloat(updatetime))



    // };
    // backwind = () => {
    //     this.checkPermission();
    //     //stop current song
    //     var updatetime = this.player.getCurrentTime() - 10;
    //     // console.log(updatetime);
    //     this.props.setPlayer({
    //         ...this.props.playerSlice,
    //         seeking: false
    //     })
    //     this.player.seekTo(parseFloat(updatetime))



    // };

    // handleError = () => {

    // }

    // handleBuffer = () => {
    //     if (!navigator.onLine) {
    //         // console.log('Network error occurred');
    //         this.setState({
    //             networkError: true,
    //             BufferLogTime: {
    //                 ...this.props.currentTrackInfo,
    //                 endTimeStamp: getCurrentTimeStamp(),
    //                 endPosition: this.player.getCurrentTime()
    //             }
    //         })
    //         this.handlePause();
    //     } else {
    //         if (this.state.networkError) {
    //             // console.log('resetting network error');
    //             this.setState({ restartTrack: true })
    //         }
    //         this.setState({ networkError: false })
    //     }

    //     this.setState({ bufferTime: getCurrentTimeStamp() })

    //     // console.log('buffering....');
    // }
    // handleBufferEnd = () => {

    //     let burrferTimeNew = 0;
    //     if (this.state.bufferTime && this.state.bufferTime != 0) {
    //         burrferTimeNew = getBufferTime(this.state.bufferTime);

    //     }
    //     let oldBuferTime = parseInt(this.props.currentTrackInfo.buffer) + parseInt(burrferTimeNew);
    //     let totalBuferTime = parseInt(this.props.currentTrackInfo.buffer);
    //     if (oldBuferTime > 0) {
    //         totalBuferTime = oldBuferTime;
    //     }

    //     // console.log('total buffer ' + totalBuferTime);
    //     // console.log('player buffer ' + this.props.currentTrackInfo.buffer);
    //     // console.log('main buffer ' + burrferTimeNew);
    //     // console.log('state buffer ' + this.state.bufferTime);
    //     // console.log('old buffer ' + oldBuferTime);
    //     this.setState({ bufferTime: 0 })
    //     this.props.updateCurrentTrack({
    //         ...this.props.currentTrackInfo,
    //         buffer: totalBuferTime
    //     });

    //     console.log('streaming....');
    // }

    likeFun = async () => {
        if (!this.props.isAuthenticated) {
            this.setState({ redirect: true })
            return;
        }
        let data = {
            'type': 1,
            'main_id': this.props.mediaId
        }
        if (!this.props.mediaSlice.like) {
            try {
                await homeService.addToWatchlist(data);
                this.props.setLikeMedia({
                    like: true
                })
                if (this.props.trackSlice.media.id == this.props.media.id) {
                    this.props.setPlayer({
                        ...this.props.playerSlice,
                        like: true
                    })
                }
                return;
            } catch (error) {
                notification('error', 'Das hat nicht geklappt. Bitte versuche es später noch einmal.');
                return;
            }
        } else {
            try {
                await homeService.removeFromWatchlist(data);
                this.props.setLikeMedia({
                    like: false
                })
                if (this.props.trackSlice.media.id == this.props.media.id) {
                    this.props.setPlayer({
                        ...this.props.playerSlice,
                        like: false
                    })
                }
                return;
            } catch (error) {
                notification('error', 'Das hat nicht geklappt. Bitte versuche es später noch einmal.');
                return;
            }
        }

    }

    makePayment = () => {
        if (!this.props.isAuthenticated) {
            this.setState({ redirect: true })
            return false;
        }

        if (this.props.isExpired) {
            this.setState({ subRedirect: true })
            return false;
        }

        if (!this.props.canPlay) {
            this.setState({ paidRedirectWithNoti: true })
            return false;
        }

        return;
    }

    hideDesc = () => {
        document.body.classList.remove("media-description");
        this.setState({
            showDesc: false
        });
    }


    render() {
        const isActive = this.state.isActive;
        const isTracklist = this.state.isTracklist;
        const ismqClass = this.state.isActive;
        const isReadMore = this.state.isReadMore;
        const backUrl = this.props.backUrl;
        let previousUrl = window.sessionStorage.getItem("previousUrl") ? window.sessionStorage.getItem("previousUrl") : '/';
        const { redirect, subRedirect, paidRedirect, paidRedirectWithNoti, restartTrack } = this.state;

        if (redirect) {
            // notification('info', 'You need to login first');
            return <Navigate to='/register' state={{ from: this.props.location }} />;
        }
        // if (restartTrack) {
        //     return <Navigate to={window.location.pathname} state={{ mediaReset: true }} />;
        // }

        if (subRedirect) {
            notification('info', 'Bitte schließe ein Abonnement ab.');
            return <Navigate to='/subscription' />;
        }

        if (paidRedirect) {
            notification('info', 'Bitte kaufe diesen Inhalt, um ihn wiedergeben zu können.');
            // return <Navigate to='/media-purchase' state={{ from: this.props.location }} />;
        }
        if (paidRedirectWithNoti) {
            return <Navigate to='/media-purchase' state={{ from: this.props.location }} />;
        }


        let text = this.props.media.short_description;
        let covertitle = this.props.media.artist_name;
        let coverartist = this.props.media.title;
        const { url, playing, controls, volume, muted, played, duration } = this.props.playerSlice
        const { like } = this.props.mediaSlice;

        if (this.props.mediaId && this.props.mediaSlice.status === STATUS.LOADING) {
            return (
                <div></div>
                // <SkeletonTheme baseColor="#202020" highlightColor="#444">
                //     <Skeleton width="100%" height={500} />
                // </SkeletonTheme>
            );
        }

        if (this.props.mediaSlice && !this.props.mediaSlice.fetched) {
            return (
                <div></div>
                // <SkeletonTheme baseColor="#202020" highlightColor="#444">
                //     <Skeleton width="100%" height={500} />
                // </SkeletonTheme>
            );
        }

        // const showDesc = this.state.showDesc;
        // if(showDesc){
        //     document.body.classList.add("media-description");
        //     return(
        //         <MediaDescComponent hideDesc={this.hideDesc} desc={text} album={coverartist} artist={covertitle} />
        //     )
        // }

        return (

            <>

                {!this.state.matches && (
                    <div className={`mobile-playlist-page music-fill-cnt main ${!this.props.mediaId ? "active" : "active"}`} id={!this.state.matches ? "player-component" : ""}>
                        <div className="mobile-vs">

                            <div id="music-player" className={!this.props.mediaId ? "not-active" : "active"} style={{ backgroundImage: `url(${config.MEDIA_URL}/${this.props.media.cover_image})` }}>
                                <Link className='mobile-full-screen2' to={previousUrl}></Link>
                                <div className={`inner-music ${this.props.playerSlice.filetype == "1" ? 'audio-type' : 'video-type'}`} id="inner-music">
                                    <div className="toggleTop videotoggleTop mt-minus-half">

                                        <button className="togglebtn" id="tgl" onClick={this.ToggleClass}>
                                            <Link to={backUrl}>
                                                <img src={'/images/dn.png'} alt="" className="t-btn1" />
                                            </Link>
                                        </button>
                                        <button className="fav" onClick={this.likeFun}>
                                            {
                                                like ?
                                                    <img className="heart1" src={'/images/heart-active.png'} />
                                                    :
                                                    <img className="heart1" src={'/images/heart.png'} />
                                            }
                                        </button>
                                        <RWebShare
                                            data={{
                                                text: coverartist,
                                                url: window.location,
                                                title: "Share Your Link",
                                            }}
                                            onClick={() => console.log("shared successfully!")}
                                        >
                                            <button className="share" >
                                                <img src={'/images/share.png'} alt="" className="share-icon" />
                                            </button>
                                        </RWebShare>
                                    </div>

                                    <div className="mobile-playlit-cover">
                                        <img src={`${config.MEDIA_URL}/${this.props.media.cover_image}`} className="mobile-cover-img2" />
                                        <div className="m-album-name">{coverartist}</div>
                                        <div className="m-artist-name">{covertitle}</div>
                                    </div>

                                    <div className="sample-restart-btn">

                                        {
                                            !this.props.canPlay && this.props.media && this.props.media.price > 0
                                                ?
                                                <>
                                                    {
                                                        this.props.media && this.props.media.sample_file_type > 0 && this.props.media.sample_file ?
                                                            <>
                                                                <button className="m-sample" data-bs-toggle="modal" data-bs-target="#sampleModal2" onClick={this.handleStop}><img src="/images/playSample-icon.png" alt="" />VORSCHAU</button>
                                                            </>
                                                            :
                                                            null
                                                    }
                                                    <button className="m-buy-btn" onClick={this.makePayment}>FÜR {currencyFormatDE(this.props.media.price)} KAUFEN</button>
                                                </>
                                                :


                                                this.props.media.last_played ?
                                                    <>
                                                        <button className="continue-play" onClick={this.continuePlay}><img src="/images/playSample-icon.png" alt="" />FORTSETZEN</button>
                                                        <button className="restart" onClick={this.restartPlay}><img src="/images/restart-white-icon.png" alt="" />NEUSTART</button>
                                                    </>
                                                    :
                                                    <button className="continue-play" onClick={this.restartPlay}><img src="/images/playSample-icon.png" alt="" />WIEDERGABE</button>

                                        }

                                        {/* <button type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" onClick={this.showDesc} className={`song-info ${this.props.media.last_played ? "" : "absolute"}`}><img src="/images/song-info.png" alt="" /></button> */}
                                        <button type="button" data-bs-toggle="offcanvas" data-bs-target="#songDescription1" aria-controls="offcanvasRight" className={`song-info ${(this.props.media.last_played || this.props.media.sample_file) ? "" : "absolute"}`}><img src="/images/song-info.png" alt="" /></button>
                                    </div>

                                    {/* <button className="crosstab" id="cross" onClick={this.cross}><i className="fa fa-times" aria-hidden="true"></i></button> */}
                                    <div className="music-player--details">
                                        <div className="tracklist tgltrack" >

                                            <div className="tracklist-top" onClick={this.toggleTracklist}>
                                                <div className="tracklist-lft">{`${(this.props.playerSlice.index + 1)} `}. {this.props.playerSlice.title}</div>
                                                <div className="tracklist-rgt" > <img src="/images/arrow-down.png" alt="" /> </div>
                                            </div>

                                            <div className="tracklist-open">
                                                <div className="tracklist-btm-top">
                                                    <div className="tab-row" >
                                                        <div className="tab-col">Nr.</div>
                                                        <div className="tab-col">Titel & Artist</div>
                                                        <div className="tab-col duration-title">Dauer</div>
                                                    </div>
                                                </div>
                                                <div className={`tab-btm-wp ${this.state.isMoreTrack ? "" : "less-track"}`} id="trackParent">
                                                    {this.props.tracks && this.props.tracks.map((element, i) => (
                                                        <div className={`trackChild tab-row ${(this.props.playerSlice.index) === i ? "m-active" : ""} 
                                                        ${(this.props.playerSlice.contentId) == element.id ? "content-active track-active" : ""}
                                                        `} onClick={() => this.changeTrack(element, i)} id={`link${i}`} key={element.id}>
                                                            <div className="tab-col">{i + 1}.</div>
                                                            <div className="tab-col">
                                                                <h3 className="marque-text">{element.title}</h3>
                                                                <div className="marque-tracklist-text">


                                                                    {element.title ?
                                                                        (element.title.length > 30 ? <Marquee speed={30}>
                                                                            <h3 style={{
                                                                                marginRight: "20px"
                                                                            }}>
                                                                                {element.title}
                                                                            </h3>
                                                                        </Marquee> : <h3 >{element.title}</h3>)

                                                                        : true}

                                                                </div>
                                                                <p className="tab-col-artist">{formatArtistName(element.artists)}</p>
                                                            </div>
                                                            <div className="tab-col tab-col-duration2">{getDuration(element.duration)}</div>

                                                        </div>

                                                    ))}
                                                    {
                                                        this.props.trackSlice.media.id == this.props.media.id ?
                                                            <div className='bottom-div active' id='bottom' style={{ top: this.props.topBarSlice.top1 + "px" }}></div>
                                                            :
                                                            ""
                                                    }
                                                </div>
                                                {
                                                    this.props.tracks.length > 25 ?
                                                        <button style={{ display: this.state.isMoreTrack ? 'none' : 'block' }} type='button' onClick={this.showMoreTrack} className='m-play-sample show-more-track'>MEHR ZEIGEN</button>
                                                        :
                                                        null
                                                }
                                            </div>

                                        </div>

                                    </div>
                                </div>

                            </div>

                        </div>
                        <MediaDescComponent desc={text} album={coverartist} artist={covertitle} media={this.props.media} stopSample={this.stopSample} handleStop={this.handleStop} modalId={"songDescription1"} mediaId={this.props.media.id} sampleModalId={"#sampleModal2"} />
                    </div>

                )
                }
                {this.state.matches && (
                    <div className={`music-fill-cnt main ${isActive ? "not-active" : "active"}`} id={!this.state.matches ? "player-component" : ""}>
                        <div className="desktop-vs" id={this.props.mediaId ? 'details-player' : 'details-player'}>
                            <div className={`desktop-minipl ${isActive ? "not-active" : "active"}`} id={this.props.mediaId ? '' : 'home-player'}>
                                {/* <div className="mini-bg-img" ></div> */}
                                <div className={`inner-music ${this.props.playerSlice.filetype == "1" ? 'audio-type' : 'video-type'}`} id="inner-music">
                                    <div className="desk-link"><Link to={backUrl}> <i className="fa fa-chevron-left"></i> {this.props.pageName}</Link></div>
                                    <div className="flex-desk">
                                        <div className="flex-lft">
                                            <div className="left-cover-sec">
                                                <img src={`${config.MEDIA_URL}/${this.props.media.cover_image}`} className="cover-img2" />
                                                <div className="cover-img2-overlay">
                                                    <div className="sample-restart-btn">
                                                        {
                                                            this.props.media.last_played ?
                                                                <>
                                                                    <button className="continue-play" onClick={this.continuePlay}><img src="/images/playSample-icon.png" alt="" />FORTSETZEN</button>
                                                                    <button className="restart" onClick={this.restartPlay}><img src="/images/restart-icon.png" alt="" />NEUSTART</button>
                                                                </>
                                                                :
                                                                <button className="continue-play" onClick={this.restartPlay}><img src="/images/playSample-icon.png" alt="" />WIEDERGABE</button>

                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`flex-rgt ${this.props.tracks && this.props.tracks.length >= 7 ? 'after' : ''} ${this.props.playerSlice.isInitialize ? "init" : "not-init"}`}>
                                            <div className="rgt-flx-inr">
                                                <div className="artist-info">
                                                    <h4 className="details-artist" >{covertitle}</h4>
                                                    <div className={`marquee ${ismqClass ? "" : "long-line"} `} id="mq-ht">
                                                        <div className="content">
                                                            <h3 className="details-title">
                                                                {/* Warum Gott? - Vernünftiger Glaube Warum Gott? */}
                                                                {coverartist}
                                                            </h3>
                                                        </div>
                                                    </div>

                                                </div>



                                                <div className={`tracklist ${isTracklist ? "tgltrack" : ""} `} >


                                                    <div className="tracklist-open">
                                                        <div className="tracklist-btm-top">
                                                            <div className="tab-row" >
                                                                <div className="tab-col">Nr.</div>
                                                                <div className="tab-col">Titel & Artist</div>
                                                                <div className="tab-col">Dauer</div>
                                                            </div>
                                                        </div>

                                                        <div className="tab-btm-wp" id="trackParent">
                                                            {this.props.tracks && this.props.tracks.map((element, i, tracks) => (
                                                                // Hier
                                                                <div className={`trackChild tab-row ${(this.props.playerSlice.index) === i ? "m-active" : ""}
                                                                ${(this.props.playerSlice.contentId) == element.id ? "content-active track-active" : ""}
                                                                ${(i === tracks.length - 1) ? "last" : ""}
                                                                `} onClick={() => this.changeTrack(element, i)} id={`link${i}`} key={element.id}>
                                                                    <div className="tab-col"  >{i + 1}.</div>
                                                                    <div className="tab-col tab-col-middle">

                                                                        <h3 className="marque-text">{element.title}</h3>
                                                                        <div className="marque-tracklist-text">


                                                                            {element.title ?
                                                                                (element.title.length > 62 ? <Marquee speed={30}>
                                                                                    <h3 className="tickerTxt" style={{ marginRight: "20px" }}>
                                                                                        {element.title}
                                                                                    </h3>
                                                                                </Marquee> : <h3 >{element.title}</h3>)

                                                                                : true}


                                                                        </div>

                                                                        <p>{formatArtistName(element.artists)}</p>
                                                                    </div>
                                                                    <div className="tab-col tab-col-duration">{getDuration(element.duration)}</div>
                                                                </div>

                                                            ))}
                                                            {
                                                                this.props.trackSlice.media.id == this.props.media.id ?
                                                                    <div className='bottom-div  active' id='bottom' style={{ top: this.props.topBarSlice.top1 + "px" }}></div>
                                                                    :
                                                                    ""
                                                            }


                                                        </div>

                                                    </div>

                                                </div>


                                            </div>

                                        </div>
                                    </div>



                                    <div className="description-flx des-fix2">
                                        <div className="des-lft des-lft2">
                                            <div className="des-sec" dangerouslySetInnerHTML={{ __html: text }}>



                                            </div>
                                        </div>
                                        <div className="des-rgt des-rgt2">
                                            {
                                                this.props.media && this.props.media.sample_file_type > 0 && this.props.media.sample_file ?
                                                    <>
                                                        <button className="btn sample-btn" data-bs-toggle="modal" data-bs-target="#sampleModal2" onClick={this.handleStop}> <img src="/images/ply.png" alt="" />Vorschau</button>
                                                    </>
                                                    :
                                                    null

                                            }
                                            {
                                                !this.props.canPlay && this.props.media && this.props.media.price > 0 ?
                                                    <button className="btn fav-btn buy-btn" onClick={this.makePayment}>Zugang kaufen für {currencyFormatDE(this.props.media.price)}</button>
                                                    : null
                                            }


                                            <button className="btn fav-btn-new" onClick={this.likeFun}>
                                                {
                                                    like ? <img src="/images/liked-icon.png" alt="" className="liked-icon" /> : <img src="/images/like-icon.png" alt="" className="like-icon" />
                                                }
                                                {
                                                    like ? 'Gespeichert' : 'Zu Favoriten hinzufügen'
                                                }
                                            </button>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div >
                )
                }


            </>
        )
    }
}
function mapStateToProps(state) {
    return {
        mediaSlice: state.mediaSlice,
        trackSlice: state.trackSlice,
        tracks: state.mediaSlice.tracks,
        media: state.mediaSlice.media,
        canPlay: state.mediaSlice.canPlay,
        currentTrackInfo: state.currentTrackSlice.info,
        isAuthenticated: state.authSlice.isAuthenticated,
        playerSlice: state.playerSlice,
        isExpired: state.subscriptionSlice.isExpired,
        pageName: state.previousPageSlice.pageName,
        topBarSlice: state.topBarSlice,
        subscriptionStatus: state.subscriptionSlice.status
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        updateCurrentTrack: (data) => dispatch(updateCurrentTrack(data)),
        updateTrackThunk: (data) => dispatch(updateTrackThunk(data)),
        resetCurrentTrack: () => dispatch(resetCurrentTrack()),
        setPlayer: (data) => dispatch(setPlayer(data)),
        setLikeMedia: (data) => dispatch(setLikeMedia(data)),
        setTrack: (data) => dispatch(setTrack(data))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(MediaDetails);