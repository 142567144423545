import React from 'react'
import { useRef } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { BallTriangle, Bars } from 'react-loader-spinner';
import { Link, Navigate, useLocation, useNavigate, useParams } from 'react-router-dom'
import notification from '../../config/notification';
import { useDocumentTitle } from '../../hooks/setDocumentTitle';
import authService from '../../store/services/auth.service';
import SiteLogo from '../SiteLogo';
function ResetPassword() {
    useDocumentTitle('Reset Password');
    const [loading, setLoading] = useState(true);
    const [verified, setVerified] = useState(false);
    const { token } = useParams();

    if (!token) {
        return <Navigate to="/forgot-password" />
    }
    useEffect(() => {
        (async () => {
            setLoading(true)
            try {
                await authService.verifyResetToken({
                    token
                })
                setLoading(false)
                setVerified(true)
            } catch (er) {
                notification('warning', 'Dein Link ist abgelaufen. Bitte registriere dich erneut.')
                setLoading(false)
                setVerified(false)
            }
        })()
    }, [])


    const password = useRef();
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    const [loadingSubmit, setLoadingSubmit] = useState(false)
    const checkPass = async (e) => {
        e.preventDefault();
        setError(null);
        setLoadingSubmit(true)
        try {
            await authService.resetPassword({
                token: token,
                password: password.current.value,
            })
            setLoadingSubmit(false)
            notification('message', 'Dein Passwort wurde zurückgesetzt.', 500)
            navigate('/signin');
        } catch (er) {
            setLoadingSubmit(false)

            if (er.response && er.response.status === 422) {
                er.response.data.data.password ? setError(er.response.data.data.password[0]) : setError('');
            } else {
                notification('error', 'Das hat nicht geklappt. Bitte versuche es später noch einmal.')
            }
        }
    };


    if (!loading && !verified) {
        return <Navigate to="/forgot-password" />
    }

    if (!loading && verified) {
        return <div className='choosepassword-page'>

            {
                loadingSubmit ? <Bars
                    height="80"
                    width="80"
                    color="#ffffff"
                    ariaLabel="bars-loading"
                    wrapperStyle={{}}
                    wrapperClass="loading-spinner-animation"
                    visible={true}
                /> : ''
            }
            <div className="account-sec privacy-policy-wrp">
                <div className="container">
                    <div className="em-logo position-relative mb-4 ">
                        <Link to="/">
                            <SiteLogo />
                        </Link>

                    </div>
                    <div className="reg-container reg-2">
                        <div className="join-form">

                            <h3 className='form-title'>Wähle ein sicheres Passwort</h3>
                            <form className="join" onSubmit={checkPass}>
                                <div className="field">
                                    <input type="password" className="j-email" placeholder='Passwort' ref={password} />
                                    <p className="err">{error}</p>
                                </div>
                                <div className="field">
                                    <div>
                                        <div className="choose-p">
                                            Mindestens sechs Zeichen (Groß- und Kleinschreibung beachten) mit mindestens einer Zahl oder einem Sonderzeichen.
                                        </div>
                                        <button type="submit" className="join-btn cp-btn ">Reset Password</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }


    return (
        <div className="verify-email-page">
            <div className="loading-spinner-animation">
                <BallTriangle
                    height={100}
                    width={100}
                    radius={5}
                    color="#ffffff"
                    ariaLabel="ball-triangle-loading"
                    wrapperClass={{}}
                    wrapperStyle=""
                    visible={true}
                />
            </div>
        </div>
    )
}
export default ResetPassword