import React from 'react';
import '../../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { useLocation, useParams } from "react-router-dom";
import NotFound from '../../NotFound';
import homeService from '../../store/services/home.service';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import config from '../../config/config';
import LoadingComponent from '../LoadingComponent'
import { SkeletonTheme } from 'react-loading-skeleton';
import { useDocumentTitle } from '../../hooks/setDocumentTitle';
import ProductCard from '../ProductCard';
import SliderCard from '../Home/SliderCard';
import MediaDetails from './MediaDetails';


function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style }}
            onClick={onClick}
        > <img src={'/images/caret1.png'} alt="" /> </div>
    );
}
function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style }}
            onClick={onClick}
        ><img src={'/images/caret1.png'} alt="" /></div>
    );
}
function ProductsComponent({ mediaId }) {
    useDocumentTitle('Media');
    const { tracks } = useSelector((state) => state.trackSlice)
    const { poster } = useSelector((state) => state.playerSlice)
    // const { mediaId } = useParams();
    const [products, setProducts] = useState(null);
    const [recommendations, setRecommendations] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loadingrec, setLoadingrec] = useState(false);
    // var body = document.body;
    // body.classList.add("body_class");
    // body.classList.remove("artist-body");
    // var elem = document.getElementById('player-component');
    // if (typeof elem !== 'undefined' && elem !== null) {
    //     elem.style.display = 'block';
    // }
    // var tglclass2 = document.getElementById('tgl');
    // if (typeof tglclass2 !== 'undefined' && tglclass2 !== null) {
    //     elem.style.display = 'block';
    // }
    // var cross = document.getElementById('cross');
    // if (typeof cross !== 'undefined' && cross !== null) {
    //     cross.style.display = 'none';
    // }
    const location = useLocation();
    let oldUrl = sessionStorage.getItem('oldUrl') ? sessionStorage.getItem('oldUrl') : '/';
    let backUrl = oldUrl;
    if (backUrl == location.pathname || backUrl == '/signin' || backUrl == 'register') {
        backUrl = '/';
    }
    const recent = {
        variableWidth: true,
        dots: false,
        arrows: true,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 5,
        swipeToSlide: true,
        cssEase: 'linear',
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1200,
                settings: "unslick"
            }
        ],
    };

    const [matches, setMatches] = useState(
        window.matchMedia("(min-width: 1200px)").matches
    )
    useEffect(() => {
        window
            .matchMedia("(min-width: 1200px)")
            .addEventListener('change', e => setMatches(e.matches));
    }, []);
    const fetchProduct = async () => {
        setLoading(true);
        setProducts(null);
        try {
            let res = await homeService.getProduct({
                media_id: mediaId
            });
            // console.log(res.data);
            setProducts(res.data.data);
        } catch (error) {
            // console.log(error);
        }
        setLoading(false);
    }
    const fetchRecommendations = async () => {
        setLoadingrec(true);
        setRecommendations(null);
        try {
            let res = await homeService.getRecommendations({
                media_id: mediaId
            });
            // console.log(res.data);
            setRecommendations(res.data.data);
        } catch (error) {
            // console.log(error);
        }
        setLoadingrec(false);
    }
    useEffect(() => {
        fetchProduct();
        fetchRecommendations();
    }, [mediaId])
    useEffect(() => {
        if (poster) {
            document.getElementById('mobileBg').style.backgroundImage = `url(${poster})`
        }
    }, [poster])
    if (!tracks) {
        return <NotFound />
    }
    // if (loading) {
    //     return (
    //         // <SkeletonTheme baseColor="#202020" highlightColor="#444">
    //         //     <LoadingComponent />
    //         // </SkeletonTheme>
    //         <div></div>
    //     )
    // }
    // if (!products || products.length === 0) {
    //     return <></>
    // }
    return (
        <>
            {
                loading ?
                    // <SkeletonTheme baseColor="#202020" highlightColor="#444">
                    //     <LoadingComponent />
                    // </SkeletonTheme>
                    <div></div>
                    :
                    !products || products.length === 0
                        ?
                        <></>
                        :
                        <div className="audio-wrap1 product d-block">
                            <div className="container">
                                <div className='music-slider recent'>
                                    <div className="music-top">
                                        <div className="row">
                                            <div className="col-12 pl-smll-0">
                                                <div className="top-title position-relative mb-0 left-minus"><h3><b className='dont-break'>Dazu passend</b></h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="music-btm-slider neu al1 pb-2  pb-xl-0">
                                        <Slider {...recent}>
                                            {
                                                products && products.map((product) => {
                                                    return (
                                                        <ProductCard product={product} key={product.id} />
                                                    )
                                                })
                                            }
                                        </Slider>
                                    </div>
                                </div>
                            </div>
                        </div>
            }
            {
                loadingrec ?
                    // <SkeletonTheme baseColor="#202020" highlightColor="#444">
                    //     <LoadingComponent />
                    // </SkeletonTheme>
                    <div></div>
                    :
                    !recommendations || recommendations.length === 0
                        ?
                        <></>
                        :
                        <div className="audio-wrap1 product d-block">
                            <div className="container">
                                <div className='music-slider recent'>
                                    <div className="music-top">
                                        <div className="row">
                                            <div className="col-12 pl-smll-0">
                                                <div className="top-title position-relative mb-0 left-minus"><h3><b className='dont-break'>Das könnte dir auch gefallen</b></h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="music-btm-slider neu al1 pb-2  pb-xl-0">
                                        <Slider {...recent}>
                                            {
                                                recommendations && recommendations.map((media) => {
                                                    return (
                                                        <SliderCard media={media} key={media.id} />
                                                    )
                                                })
                                            }
                                        </Slider>
                                    </div>
                                </div>
                            </div>
                        </div>
            }
        </>
    );
}
export default ProductsComponent;
