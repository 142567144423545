import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './register.css';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useDocumentTitle } from './hooks/setDocumentTitle';
import subscriptionService from './store/services/subscription.service';
import { currencyFormatDE } from './helpers/helpers';
import SiteLogo from './Components/SiteLogo';

export default function Register() {
  useDocumentTitle('Register');
  const [prices, setPrices] = useState(null)
  const [loading, setLoading] = useState(false)
  const { isAuthenticated } = useSelector((state) => state.authSlice);
  const navigate = useNavigate();
  const back = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/');
    }
  }, [navigate, isAuthenticated])


  useEffect(() => {
    var root = document.getElementById("root");
    root.removeAttribute("class");
  }, []);

  const getPrice = async () => {
    setLoading(true);
    try {
      let res = await subscriptionService.getSubscriptionPrice();
      setPrices(res.data.data);
      setLoading(false);
    } catch (error) {

    }
  }

  useEffect(() => {
    getPrice();
  }, [])




  return (
    <div className='register-page'>
      <div className="account-sec privacy-policy-wrp">
        <div className="container">

          <div className="em-logo position-relative mb-4 ">
            <Link to="/">
              <SiteLogo />
            </Link>
            <div className="back-sec">
              <button onClick={back}><img src="images/dn_ar.svg" alt="" /></button>
            </div>
          </div>



          {
            loading ?
              null
              :

              <div className="reg-container reg-1">
                <h3>Dein neues Streaming-Erlebnis wartet!</h3>
                <p>Registriere dich jetzt und teste streamfair für 14 Tage völlig kostenlos und unverbindlich.</p>
                <Link to="/join" className='join-btn'>JETZT REGISTRIEREN</Link>
                <p className='fade1'>Schließe (beginnend nach dem kostenlosen Testzeitraum) ein Abo für {prices && currencyFormatDE(prices.monthy_amount)}/Monat ab oder spare {prices && prices.discount_percent} % mit einem Jahresabo für nur {prices && currencyFormatDE(prices.yearly_amount)}.</p>
                <p className="small1">Du bist schon Mitglied? Willkommen zurück!</p>
                <Link to="/signin" className='signin-btn'>ANMELDEN</Link>
              </div>
          }



        </div>


      </div>
    </div>
  )
}
