import React, { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { ThreeDots } from 'react-loader-spinner';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import config from './config/config';
import { useDocumentTitle } from './hooks/setDocumentTitle';
import usePreviousPage from './hooks/usePreviousPage';
import homeService from './store/services/home.service';
import ImageRenderer from './Components/ImageRenderer';
function Channels() {
    const [loading, setLoading] = useState(false);
    const [channel, setChannel] = useState([]);
    const [hasMore, setHasMore] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    useDocumentTitle('Kanäle');
    usePreviousPage('Kanäle');
    const fetchChannels = async () => {
        if (currentPage === 0) {
            setLoading(true);
            setChannel([])
        }
        setHasMore(false);
        try {
            let res = await homeService.channels(currentPage + 1);
            // console.log(res.data.data);
            if (res.data.data.next_page_url) {
                setHasMore(true);
            }
            setCurrentPage((cpage) => cpage + 1);
            setChannel((pre) => [...pre, ...res.data.data.data]);
            setLoading(false);
        } catch (error) {
            // console.log(error);
        }
    }
    useEffect(() => {
        fetchChannels();
    }, []);
    useEffect(() => {
        document.body.removeAttribute("class");
    }, []);
    return (
        // <SkeletonTheme baseColor="#202020" highlightColor="#444">
        <div className='viewall-page appMain'>
            <div className="container">
                <div className="cat-top">
                    <div className="heading3">
                        <img className="top-img" src={'/images/kanale-3.png'} alt="" />
                        <h3>Kanäle</h3>
                    </div>
                    {
                        loading ?
                            <>
                                {/* <div className="row">
                                        <div className="col-20">
                                            <div className="v-card">
                                                <Skeleton width={225} height={250} />
                                                <div className="slider-btm-wp">
                                                    <div className="wp-lft">
                                                        <h4 className="title"><Skeleton /></h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-20">
                                            <div className="v-card">
                                                <Skeleton width={225} height={250} />
                                                <div className="slider-btm-wp">
                                                    <div className="wp-lft">
                                                        <h4 className="title"><Skeleton /></h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-20">
                                            <div className="v-card">
                                                <Skeleton width={225} height={250} />
                                                <div className="slider-btm-wp">
                                                    <div className="wp-lft">
                                                        <h4 className="title"><Skeleton /></h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-20">
                                            <div className="v-card">
                                                <Skeleton width={225} height={250} />
                                                <div className="slider-btm-wp">
                                                    <div className="wp-lft">
                                                        <h4 className="title"><Skeleton /></h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-20">
                                            <div className="v-card">
                                                <Skeleton width={225} height={250} />
                                                <div className="slider-btm-wp">
                                                    <div className="wp-lft">
                                                        <h4 className="title"><Skeleton /></h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                            </>
                            :
                            channel &&
                            <InfiniteScroll
                                dataLength={channel.length}
                                next={fetchChannels}
                                hasMore={hasMore}
                                loader={<ThreeDots
                                    height="80"
                                    width="80"
                                    radius="9"
                                    color="#4fa94d"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                />}
                            >
                                <div className="row">
                                    {
                                        channel.map((chan) => {
                                            return (
                                                <div className="col-20" key={chan.id}>
                                                    <Link to={`/channel/${chan.id}`}>
                                                        <div className="v-card">
                                                            <ImageRenderer
                                                                wrapperClassName="lazy-viewall"
                                                                url={`${config.CHANNEL_URL}/${chan.thumbnail_image}`} />
                                                            <div className="slider-btm-wp">
                                                                <div className="wp-lft">
                                                                    <h4 className="title">{chan.name}</h4>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </InfiniteScroll>
                    }
                </div>
            </div>
        </div>
        //  </SkeletonTheme>
    )
}
export default Channels