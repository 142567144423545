import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import subscriptionService from "./services/subscription.service";
import STATUS from "./status";


const initialState = {
    status: STATUS.IDLE,
    isSubscribe: false,
    isExpired: true,
    subscriptionId: 0,
    endDate : null,
    next_payment_date : null,
    subscription_cancel : true,
    upcoming_subscription_id : 0,
    fetched : false,
}

export const subscriptionThunk = createAsyncThunk(
    'subscriptionThunk', async () => {
        try {
            const res = await subscriptionService.checkSubscription();
            return res.data;
        } catch (error) {
            if (error.response && error.response.status == '401') {
                window.location.reload(false);
            }
        }
    }
)

const subscriptionSlice = createSlice({
    name: 'subscriptionSlice',
    initialState,
    reducers: {
        resetSubscription: (state) => {
            state.status = STATUS.IDLE;
            state.isExpired = true
            state.isSubscribe = false
            state.subscriptionId = 0
            state.endDate = null
            state.next_payment_date = null
            state.subscription_cancel = true
            state.upcoming_subscription_id = 0
            state.fetched = false
        },
    },
    extraReducers: {
        [subscriptionThunk.rejected]: (state, action) => {
            state.status = STATUS.ERROR;
            state.isExpired = true
            state.isSubscribe = false
            state.subscriptionId = 0
            state.endDate = null
            state.next_payment_date = null
            state.subscription_cancel = true
            state.upcoming_subscription_id = 0
            state.fetched = false
        },
        [subscriptionThunk.pending]: (state, action) => {
            state.status = STATUS.LOADING
            state.isExpired = true
            state.isSubscribe = false
            state.subscriptionId = 0
            state.endDate = null
            state.next_payment_date = null
            state.subscription_cancel = true
            state.upcoming_subscription_id = 0
            state.fetched = false
        },
        [subscriptionThunk.fulfilled]: (state, action) => {
            state.status = STATUS.SUCCESS
            state.isExpired = action.payload.data.isExpired
            state.isSubscribe = action.payload.data.isSubscribe
            state.subscriptionId = action.payload.data.subscriptionId
            state.endDate = action.payload.data.endDate
            state.next_payment_date = action.payload.data.next_payment_date
            state.subscription_cancel = action.payload.data.subscription_cancel
            state.upcoming_subscription_id = action.payload.data.upcoming_subscription_id
            state.fetched = true
        }
    }
})

const { reducer } = subscriptionSlice;
export const { resetSubscription } = subscriptionSlice.actions

export default reducer;