
import { nanoid } from '@reduxjs/toolkit';
import React from 'react'

function Errors(props) {
    let errors = props.errors;

    return (
        <ul>
            {Object.keys(errors).map((error, index) => (
                <li className="common-error" key={nanoid()}>{errors[error][0]}</li>
            ))}
        </ul>
    )
}

export default Errors