import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { decode } from "base-64";
import AuthService from "../services/auth.service"
import STATUS from "../status";


let userToken = localStorage.getItem('_token');
if (userToken) {
    try {
        userToken = decode(userToken);

    } catch (error) {
        userToken = '';
    }
} else {
    userToken = '';
}

const initialState = {
    status : STATUS.IDLE,
    isAuthenticated: false,
    token: userToken,
    authUser: {}
}

export const authUserThunk = createAsyncThunk(
    'authUserThunk', async ()=> {
        const res = await AuthService.authUser();
        return res.data;
    }
)
export const loginThunk = createAsyncThunk(
    'loginThunk', async (data) => {
        const res = await AuthService.login(data);
        return res.data;
    }
    
)

export const registerThunk = createAsyncThunk(
    'registerThunk', async (data) => {
        const res = await AuthService.register(data);
        return res.data;
    }
    
)

const authSlice = createSlice({
    name: 'authSlice',
    initialState,
    reducers: {
        setUser: (state, action) => {
            state.isAuthenticated = true;
            state.status = STATUS.SUCCESS
            state.token = action.payload.token;
            state.authUser = action.payload.user;
        },
        resetUser: (state) => {
            state.isAuthenticated = false;
            state.status = STATUS.IDLE
            state.token = null;
            state.authUser = {};
        },
    },
    extraReducers: {
        [authUserThunk.rejected]: (state, action) => {
            state.status = STATUS.ERROR;
            state.authUser = {}
        },
        [authUserThunk.pending]: (state, action) => {
            state.status = STATUS.LOADING 
            state.authUser = {}
        },
        [authUserThunk.fulfilled]: (state, action) => {
            state.isAuthenticated = true;
            state.status = STATUS.SUCCESS
            state.authUser = action.payload;
        }
    }
})

export const authUserData = state => state.authSlice.authUser
export const { setUser, resetUser } = authSlice.actions
const { reducer } = authSlice;

export default reducer;