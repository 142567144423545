import React, { useState, useEffect } from 'react';
import ProductsComponent from './ProductsComponent';
import SampleVideo from '../SampleVideo';

export default function MediaDescComponent({ desc, album, artist, media, stopSample, handleStop, modalId, mediaId, sampleModalId }) {


  return (
    <>
      <div className="offcanvas offcanvas-end" tabIndex="-1" id={modalId} aria-labelledby="offcanvasRightLabel">
        <div className="offcanvas-body">
          <div className='media-desc-page'>

            <div className="m-desc-header sticky-top scrolled">
              <button data-bs-dismiss="offcanvas" aria-label="Close" className='m-desc-back'><img src="/images/back-btn2.png" alt="Back" /></button>
              <div className="m-albumm">{album}</div>
              <div className="m-artistt">{artist}</div>
            </div>
            <div className="m-desc-body">

              {
                media && media.sample_file_type > 0 && media.sample_file ?
                  <>
                    <button className="m-play-sample" data-bs-toggle="modal" data-bs-target={sampleModalId} onClick={handleStop}>VORSCHAU</button>

                  </>
                  :
                  null
              }

              {
                desc ?
                  <div className="m-des-sec" dangerouslySetInnerHTML={{ __html: desc }}></div>
                  : null
              }







            </div>

          </div>
          <ProductsComponent mediaId={mediaId} />

        </div>
      </div>



    </>
  )
}
