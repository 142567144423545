import React, { useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Bars } from 'react-loader-spinner';
import { Link } from 'react-router-dom'
import notification from './config/notification';
import subscriptionService from './store/services/subscription.service';
import SiteLogo from './Components/SiteLogo';

export default function ChangeCardComponent({ subscription, toggleCard, getSubscription, ...rest }) {


  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [changeSubscription, setChangeSubscription] = useState(false);
  const [cardNo, setcardNo] = useState('');
  const [carderr, setcardErr] = useState(null);
  const [carderrTxt, setcardErrTxt] = useState(null);
  const checkCard = event => {
    if (cardNo.length === 19) {
      setcardErr("success1");
      setcardErrTxt("Kartennummer verifiziert");
      return true;
    }
    else {
      setcardErr("error");
      setcardErrTxt("Geben Sie eine gültige Kartennummer ein");
    }
    return false;
  };
  const [MM, setMM] = useState('');
  const [MMerr, setMMerr] = useState('');
  const [MMerrTxt, setMMerrTxt] = useState('');
  const checkMM = event => {
    if (MM <= 12 && MM >= 1 && MM.length === 2) {
      setMMerr("success1");
      setMMerrTxt("");
      document.getElementById("YY").focus();
      return true;
    }
    else {
      setMMerr("error error4");
      setMMerrTxt("Bitte wählen Sie einen gültigen Monat");
    }
    return false;
  };
  const [YY, setYY] = useState('');
  const [YYerr, setYYerr] = useState('');
  const [YYerrTxt, setYYerrTxt] = useState('');
  const checkYY = event => {
    if (YY) {
      setYYerr("success1");
      setYYerrTxt("");
      return true;
    }
    else {
      setYYerr("error3");
      setYYerrTxt("Bitte wählen Sie ein gültiges Jahr");
    }
    return false;
  };
  const [Cvv, setCvv] = useState('');
  const [Cvverr, setCvverr] = useState('');
  const [CvverrTxt, setCvverrTxt] = useState('');

  var nameregex = /^[a-zA-Z ]+$/
  const [Cnamee, setCnamee] = useState('');
  const [Cnameeerr, setCnameeerr] = useState('');
  const [CnameeerrTxt, setCnameeerrTxt] = useState('');
  const checkCnamee = event => {
    if (Cnamee.match(nameregex)) {
      setCnameeerr("success1");
      setCnameeerrTxt("");
      return true;
    }
    else {
      setCnameeerr("error");
      setCnameeerrTxt("Bitte geben Sie einen gültigen Namen ein");
    }
    return false;
  };


  const payMent = async (e) => {
    e.preventDefault();
    let nameCheck = checkCnamee();
    let cardCheck = checkCard();
    let monthCheck = checkMM();
    let yearCheck = checkYY();

    if (!nameCheck || !cardCheck || !monthCheck || !yearCheck) {
      return false;
    }
    setLoading(true);
    try {
      let res = await subscriptionService.changeCard({
        'card_number': cardNo,
        'expiry_month': MM,
        'expiry_year': YY,
        'cvv': Cvv,
      })

      if (res.data.success) {
        setLoading(false);
        notification('message', 'Zahlungsart wurde geändert');
        getSubscription()
        toggleCard(false);
        // return navigate('/');
      } else {
        setLoading(false);
        notification('error', res.data.message)
      }

    } catch (er) {

      setLoading(false);
      if (er.response && er.response.status === 422) {
        setErrors(er.response.data.data);
      }
      notification('error', 'Das hat nicht geklappt. Bitte versuche es später noch einmal.')
    }
  }

  return (
    <>
      {
        loading ? <Bars
          height="80"
          width="80"
          color="#ffffff"
          ariaLabel="bars-loading"
          wrapperStyle={{}}
          wrapperClass="loading-spinner-animation"
          visible={true}
        /> : ''
      }

      <div className='change-card-con'>
        <div className="payment-page">
          <div className="account-sec privacy-policy-wrp">
            <div className="container">
              <div className="em-logo position-relative mb-4 ">
                <Link to="/">
                  <SiteLogo />
                </Link>
                <div className="back-sec">
                  <button className='back' onClick={() => toggleCard(false)} ><img src="images/dn_ar.svg" alt="" /></button>
                </div>
              </div>
              <div className="reg-container reg-1 confirm-sec2">
                <h4 className="wel-heading">Zahlungsart ändern</h4>

                <div className="c-para11">
                  <span className="c-icon2">!</span><div className="changeCard-alert-txt">Zurzeit werden nur Kreditkarten akzeptiert. Wir arbeiten bereits daran, dir weitere Zahlungsmethoden anbieten zu können.</div>
                </div>
                <div className="plan-title new-plan-title">AKTUELLE ZAHLUNGART</div>
                <div className="active-plan-sec-new">
                  <h5>Kreditkarte</h5>
                  <p>{subscription.card_brand} {subscription.card_last4}</p>
                  <p>Ablaufdatum: {subscription.card_exp_month}/{subscription.card_exp_year}</p>
                </div>
                <div className="plan-title m-0">NEUE ZAHLUNGSART</div>
                <div className="cards-section">
                  <div id="c1">
                    <img className='c-img' src="images/CreditCard.png" alt="" />
                    <div className="c-txt">Kreditkarte</div>
                  </div>
                  <div className="line3" ></div>
                </div>
                <div className="cards-details">
                  <form onSubmit={payMent}>

                    <div>
                      <div className="d1">
                        <label>KARTENINHABER</label>
                        <input type="text" name="" id="" onChange={event => setCnamee(event.target.value)} value={Cnamee} />
                        <span className={Cnameeerr}>{CnameeerrTxt}</span>
                      </div>
                      <div className="d1 num">
                        <label>KARTENNUMMER</label>
                        <input onChange={event => setcardNo(event.target.value.replace(/[^0-9]/gi, '').replace(/(.{4})/g, '$1 ').trim())} value={cardNo} type="text" name="card" id="card" maxLength="19" />
                        <div className="small-cards">
                          <img src="images/visa1.png" alt="" />
                          <img src="images/mastercard.png" alt="" />
                          <img src="images/american-express.png" alt="" />
                        </div>
                        <span className={carderr}>{carderrTxt}</span>
                      </div>
                      <div className="d2">
                        <div className="d1">
                          <label>GÜLTIG BIS</label>
                          <div className="mm-jj">
                            <input type="text" value={MM} onChange={event => setMM(event.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1'))} placeholder="MM" className="mm" maxLength="2" />
                            <span>/ &nbsp; &nbsp;</span>
                            <input id="YY" type="text" value={YY} onChange={event => setYY(event.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1'))} placeholder="JJ" className="jj" maxLength="2" />
                          </div>
                          <span className={MMerr}>{MMerrTxt}</span>
                          <span className={YYerr}>{YYerrTxt}</span>
                        </div>
                        <div className="d1">
                          <label>SICHERHEITSCODE</label>
                          <input className="cvv" value={Cvv} onChange={event => setCvv(event.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1'))} type="text" placeholder="CVV" maxLength="4" />
                          <span className={Cvverr}>{CvverrTxt}</span>
                        </div>
                      </div>
                    </div>
                    <div className="agree-con-new">
                      <p>Mit Klick auf "Bestätigen" bestätigst du, dass du autorisiert bist, diese Daten zu verwenden und stimmst unserem <Link to="/legalinfo">Nutzungsvertrag</Link>, der <Link to="/legalinfo">Datenschutzerklärung</Link>, <Link to="/legalinfo">Cookie-Richtlinie</Link> und den <Link to="/legalinfo">EU-Datenschutzhinweisen</Link> zu.</p>
                      <button className="join-btn join-btn-new">ÄNDERUNGEN BESTÄTIGEN</button>
                    </div>
                  </form>
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
