import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import homeService from "./services/home.service";
import STATUS from "./status";


const initialState = {
    status: STATUS.IDLE,
    fetched: false,
    search: '',
    result : null
}

export const searchThunk = createAsyncThunk(
    'searchThunk', async (search) => {
        const res = await homeService.search(search);
        return res.data;
    }
)

const searchSlice = createSlice({
    name: 'searchSlice',
    initialState,
    reducers: {
        setSearch: (state,action) => {
            state.search = action.payload
        },
    },

    extraReducers: {
        [searchThunk.rejected]: (state, action) => {
            state.status = STATUS.ERROR;
            state.result = null;
         
        },
        [searchThunk.pending]: (state, action) => {
            state.status = STATUS.LOADING
            state.result = null;
        },
        [searchThunk.fulfilled]: (state, action) => {
            state.status = STATUS.SUCCESS
            state.fetched = true;
            state.result = action.payload.data;
        }
    }
})

const { reducer } = searchSlice;
export const { setSearch } = searchSlice.actions
export default reducer;