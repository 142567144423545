import React, { useState } from "react";
import AccordionItem from "./AccordionItem";
import "./accordion.css";
import { nanoid } from "@reduxjs/toolkit";

const Accordion = ({ questionsAnswers, activeIdx }) => {
  const [activeIndex, setActiveIndex] = useState(activeIdx || 0);

  console.log(activeIndex);

  const renderedQuestionsAnswers = questionsAnswers.map((item, index) => {
    const showDescription = index === activeIndex ? "show-description" : "";
    const fontWeightBold = index === activeIndex ? "font-weight-bold" : "";
    const ariaExpanded = index === activeIndex ? "true" : "false";
    return (
      <AccordionItem
        key={nanoid()}
        showDescription={showDescription}
        fontWeightBold={fontWeightBold}
        ariaExpanded={ariaExpanded}
        item={item}
        index={index}
        onClick={() => {
          setActiveIndex(index);
        }}
      />
    );
  });

  return (
    <div className="faq">
      <div className="faq__list">{renderedQuestionsAnswers}</div>
    </div>
  );
};

export default Accordion;