import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import config from '../config/config'
import ImageRenderer from './ImageRenderer'

function ProductCard(props) {
    return (

        <div className="music-slider-blk ">
            <div className="blk-wrapper">
                <div className="image">
                    <a href={props.product.link} target="_blank" rel="noopener noreferrer" key={props.product.id}>
                        <ImageRenderer
                            wrapperClassName="lazy-slider"
                            url={`${config.PRODUCT_URL}/${props.product.image}`} />
                    </a>
                </div>
                <div className="slider-btm-wp">
                    <div className='wp-lft'>
                        <h4 className="title">
                            {props.product.name}
                        </h4>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductCard