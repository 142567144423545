import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const AccordionItem = ({
  showDescription,
  ariaExpanded,
  fontWeightBold,
  item,
  index,
  onClick,
}) => (
  <SkeletonTheme baseColor="#202020" highlightColor="#444">
  <div className="faq__question" key={item.question}>

    <button
      aria-expanded={ariaExpanded}
      aria-controls={`faq${index + 1}_desc`}
      data-qa="faq__question-button"
      className={`faq__question-button ${fontWeightBold}`}
      onClick={onClick}
    >
      {item.question}
      <img src="images/arrow.png" alt="" />
    </button>

   
    <div
      id={`faq${index + 1}_desc`}
      data-qa="faq__desc"
      className={`faq__desc ${showDescription}`}
    >
      
      <div className="faq__desc_inner">
        <p>{item.question}</p>
          {
            item.answer ? <div dangerouslySetInnerHTML={{ __html: item.answer }} className="inner-para"></div> : <Skeleton count={100}/>
       }
        
      </div>
    </div>

  </div>
  </SkeletonTheme>
);

export default AccordionItem;