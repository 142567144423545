import React from "react";
import { Link } from "react-router-dom";
import './App.css';

function NotFound() {
  return (
    <div className='error-page'>
      <div className="container">
        <div className="row">
          <div className="col-md-12 error-page-content">
            <h1>Whooops!</h1>
            <h3>Sorry, the page you are looking for doesn't exist.</h3>
            <Link to="/">Home</Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NotFound