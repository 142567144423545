import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import settingService from "./services/setting.service";
import STATUS from "./status";


const initialState = {
    status: STATUS.IDLE,
    fetched: false,
    setting: {}
}

export const settingThunk = createAsyncThunk(
    'settingThunk', async () => {
        const res = await settingService.getSetting();
        return res.data;
    }
)

const settingSlice = createSlice({
    name: 'settingSlice',
    initialState,

    extraReducers: {
        [settingThunk.rejected]: (state, action) => {
            state.status = STATUS.ERROR;
            state.fetched = false;
            state.setting = {}
        },
        [settingThunk.pending]: (state, action) => {
            state.status = STATUS.LOADING
            state.fetched = false;
            state.setting = {}
        },
        [settingThunk.fulfilled]: (state, action) => {
            state.status = STATUS.SUCCESS
            state.fetched = true;
            state.setting = action.payload.data;
        }
    }
})

const { reducer } = settingSlice;

export default reducer;