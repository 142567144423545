import { unAuthorizedAxios } from "../../axios";


class settingService {

    getSetting() {
        return unAuthorizedAxios.get('/settings');
    }

    

}

export default new settingService();