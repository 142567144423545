import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authSlice from "./auth/authSlice";
import settingSlice from "./settingSlice";
import filterSlice from "./filterSlice";
import trackSlice from "./trackSlice";
import mediaSlice from "./mediaSlice";
import currentTrackSlice from "./currentTrackSlice";
import subscriptionSlice from "./subscriptionSlice";
import playerSlice from "./playerSlice";
import previousPageSlice from "./previousPageSlice";
import topBarSlice from "./topBarSlice";
import searchSlice from "./searchSlice";
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

const reducer = combineReducers({
    authSlice: authSlice,
    settingSlice: settingSlice,
    filterSlice: filterSlice,
    trackSlice: trackSlice,
    mediaSlice: mediaSlice,
    currentTrackSlice: currentTrackSlice,
    subscriptionSlice: subscriptionSlice,
    playerSlice: playerSlice,
    searchSlice: searchSlice,
    previousPageSlice: previousPageSlice,
    topBarSlice: topBarSlice,
})

const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['authSlice', 'subscriptionSlice', 'trackSlice', 'mediaSlice', 'currentTrackSlice', 'filterSlice', 'topBarSlice', 'playerSlice']
}

const persistedReducer = persistReducer(persistConfig, reducer)

export const store = configureStore({
    reducer: persistedReducer,
    devTools: true,
    middleware: [thunk]
});

export const persistor = persistStore(store)