import React, { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component';
import { ThreeDots } from 'react-loader-spinner';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { Link, Navigate, useParams } from 'react-router-dom';
import config from './config/config';
import homeService from './store/services/home.service';
import NotFound from './NotFound'
import LoadingComponent from './Components/LoadingComponent';
import { useSelector } from 'react-redux';
import { useDocumentTitle } from './hooks/setDocumentTitle';
import MediaCard from './Components/MediaCard';
import usePreviousPage from './hooks/usePreviousPage';

function SearchDetail() {

    const [loading, setLoading] = useState(false);
    const [channel, setChannel] = useState([]);
    const [hasMore, setHasMore] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const { search } = useSelector((state) => state.searchSlice)
    const { key } = useParams();
    const fetchChannels = async () => {
        if (currentPage === 0) {
            setLoading(true);
            setChannel([])
        }
        setHasMore(false);
        try {
            let res = await homeService.searchDetail({
                type: types[key].type,
                search: search
            }, currentPage + 1);

            if (res.data.data.next_page_url) {
                setHasMore(true);
            }
            setCurrentPage((cpage) => cpage + 1);
            setChannel((pre) => [...pre, ...res.data.data.data]);
            setLoading(false);
        } catch (error) {
            // console.log(error);
            setLoading(false);
        }
    }
    const types = {
        'channels': {
            type: 3,
            title: 'Kanäle',
            image: '/images/kanale-3.png'
        },
        'series': {
            type: 2,
            title: 'Series',
            image: ''
        },
        'music': {
            type: 1,
            title: 'Musik & Hörbücher',
            image: '/images/musik-3.png'
        },
        'film': {
            type: 4,
            title: 'Filme',
            image: '/images/Kamera-3.png'
        }
    }
    useEffect(() => {
        fetchChannels();
    }, []);
    if (search === '') {
        return <Navigate to={'/search'} />
    }
    if (!['channels', 'series', 'film', 'music'].includes(key)) {
        return <NotFound />
    }
    useDocumentTitle(types[key].title);
    usePreviousPage(types[key].title)
    return (
        // <SkeletonTheme baseColor="#202020" highlightColor="#444">
        <div className='viewall-page appMain'>
            <div className="container">
                <div className="cat-top">
                    <div className="heading3">

                        <img className="top-img" src={types[key].image} alt="" />
                        <h3>{types[key].title}</h3>
                    </div>
                    {
                        loading ?
                            // <LoadingComponent />
                            null
                            :
                            channel &&
                            <InfiniteScroll
                                dataLength={channel.length}
                                next={fetchChannels}
                                hasMore={hasMore}
                                loader={<ThreeDots
                                    height="80"
                                    width="80"
                                    radius="9"
                                    color="#4fa94d"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                />}
                            >
                                <div className="row">

                                    {/* For Channel  */}

                                    {
                                        key === 'channels' ?
                                            channel.map((chan) => {
                                                return (
                                                    <div className="col-20" key={chan.id}>
                                                        <Link to={`/channel/${chan.id}`}>
                                                            <div className="v-card">
                                                                <img src={`${config.CHANNEL_URL}/${chan.thumbnail_image}`} alt="" />
                                                                <div className="slider-btm-wp">
                                                                    <div className="wp-lft">
                                                                        <h4 className="title">{chan.name}</h4>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                )
                                            }) : null

                                    }

                                    {
                                        key === 'series' ?
                                            channel.map((series) => {
                                                return (
                                                    <div className="col-20" key={`ser-${series.id}`}>
                                                        <Link to={`/series/${series.slug}`}>
                                                            <div className="v-card">
                                                                <img src={`${config.SERIES_URL}/${series.image}`} alt="" />
                                                                <div className="slider-btm-wp">
                                                                    <div className="wp-lft">
                                                                        <h4 className="title">{series.title}</h4>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                )
                                            }) : null
                                    }

                                    {
                                        key === 'film' || key === 'music' ?
                                            channel.map((media) => {
                                                return (
                                                    <MediaCard hideIcon={true} media={media} key={media.id} />
                                                )
                                            }) : null
                                    }
                                </div>
                            </InfiniteScroll>
                    }
                </div>
            </div>
        </div>
        // </SkeletonTheme>
    )
}
export default SearchDetail