import React, { Component } from 'react'
import { Link, Navigate } from 'react-router-dom'
import { connect } from "react-redux";
import Duration from './Duration'
import ReactPlayer from 'react-player'
import { findDOMNode } from 'react-dom';
import screenfull from 'screenfull/dist/screenfull.js';
import { getCurrentTimeStamp, getDuration, lastUpdatedExceed, getBufferTime, getExpireTime, currencyFormatDE, formatArtistName } from '../../helpers/helpers';
import config from '../../config/config';
import { resetCurrentTrack, updateCurrentTrack, updateTrackThunk } from '../../store/currentTrackSlice';
import notification from '../../config/notification';
import homeService from '../../store/services/home.service';
import { setPlayer } from '../../store/playerSlice';
import STATUS from '../../store/status';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import SampleVideo from '../SampleVideo';
import 'bootstrap/dist/js/bootstrap.min.js';
import Marquee from 'react-fast-marquee';
// import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { RWebShare } from "react-web-share";

import MediaDescComponent from './MediaDescComponent';
import MediaPlaylistComponent from './MediaPlaylistComponent';

import '../../VideoPlayerNew.css';
import { setLikeMedia } from '../../store/mediaSlice';

class VideoPlayer extends Component {

  load = url => {
    this.props.setPlayer({
      ...this.props.playerSlice,
      url,
      // played: 0,
      // loaded: 0,
      pip: false,
    })

  }

  constructor(props) {
    super(props)
    this.state = {
      matches: window.matchMedia("(min-width: 1200px)").matches,
    };
    this.myElementRef = React.createRef();
  }

  // -------------------

  componentDidMount() {
    // function checkScr() {
    //   let full = document.querySelector('#full');
    //   let fullBtn = document.querySelector('.full-btn');
    //   let closeBtn = document.querySelector('.close-btn');
    //   if (document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement) {
    //     full.classList.add('FullScreen');
    //     fullBtn.style.display = "none";
    //     closeBtn.style.display = "block";

    //   }
    //   else {
    //     if (full && full.classList.contains("FullScreen")) {
    //       full.classList.remove('FullScreen');
    //     }
    //     if (fullBtn) {
    //       fullBtn.style.display = "block";
    //     }
    //     if (closeBtn) {
    //       closeBtn.style.display = "none";
    //     }
    //   }
    // }

    // this.interValId = setInterval(checkScr, 100);

    // ------------------
    const handler = e => this.setState({ matches: e.matches });
    window.matchMedia("(min-width: 1200px)").addEventListener('change', handler);

    var cross = document.getElementById('cross');
    if (typeof cross !== 'undefined' && cross !== null) {
      cross.style.display = 'none';

    }


    document.addEventListener("visibilitychange", this.handleVisibilityChange);
    document.addEventListener("fullscreenchange", this.handleFullScreenChange);

  }

  componentWillUnmount() {
    document.removeEventListener("visibilitychange", this.handleVisibilityChange);
    document.removeEventListener("fullscreenchange", this.handleFullScreenChange);
    clearInterval(this.interValId);
  }

  handleFullScreenChange = () => {
    let full = document.querySelector('#full');
    let fullBtn = document.querySelector('.full-btn');
    let closeBtn = document.querySelector('.close-btn');
    if (document.fullscreenElement) {
      full.classList.add('FullScreen');
      fullBtn.style.display = "none";
      closeBtn.style.display = "block";
    } else {
      if (full && full.classList.contains("FullScreen")) {
        full.classList.remove('FullScreen');
      }
      if (fullBtn) {
        fullBtn.style.display = "block";
      }
      if (closeBtn) {
        closeBtn.style.display = "none";
      }
    }
  }

  handleVisibilityChange = () => {
    let video = document.querySelector('video');
    if (video) {
      if (document.hidden) {
        if (this.props.playerSlice.playing) {
          video.play();
        }
      }
    }
  };

  componentDidUpdate() {

    if (this.state.restartTrack == true) {
      this.setState({ restartTrack: false })
    }

    if (this.state.BufferLogTime && navigator.onLine) {
      this.props.updateTrackThunk({
        ...this.state.BufferLogTime,

      });
      console.log('updated');
      this.setState({ BufferLogTime: null })
    }

    // set active bar
    // if (document.querySelector('.m-active')) {
    //   document.getElementById("bottom").style.opacity = '0';
    //   let trackHeight = document.querySelector('.m-active').clientHeight;
    //   // console.log(trackHeight);
    //   var list = document.getElementsByClassName('tab-row');
    //   for (var i = 0; i < list.length; i++) {
    //     if (list[i].classList.contains('m-active')) {
    //       // console.log("trackId"+i);
    //       var top = trackHeight * (i - 1);
    //       document.getElementById("bottom").style.top = top + 'px';
    //       document.getElementById("bottom").style.opacity = '1';
    //     }
    //   }
    // }



  }

  checkPermission() {
    if (!this.props.isAuthenticated) {
      this.setState({ redirect: true })
      return false;
    }

    if (this.props.isExpired) {
      this.setState({ subRedirect: true })
      return false;
    }

    if (!this.props.canPlay) {
      notification('info', 'Bitte kaufe diesen Inhalt, um ihn wiedergeben zu können.');
      // this.setState({ paidRedirect: true })
      return false;
    }

    return true;
  }

  changeTrack = (element, i, event) => {
    let permission = this.checkPermission();
    if (!permission) {
      return;
    }
    this.props.setPlayer({
      ...this.props.playerSlice,
      url: element.original_source,
      poster: `${config.MEDIA_URL}/${this.props.media.cover_image}`,
      bgimage: element.bgimage,
      time: getDuration(element.duration),
      title: element.title, index: i,
      artist: formatArtistName(element.artists),
      playing: true,
      filetype: element.filetype,
      contentId: element.id,
      played: 0
    })

    let playedSeconds = this.props.playerSlice.played * this.props.playerSlice.duration

    if (this.props.playerSlice.playing) {
      if (this.props.currentTrackInfo.contentId > 0) {
        this.props.updateTrackThunk({
          ...this.props.currentTrackInfo,
          endTimeStamp: getCurrentTimeStamp(),
          endPosition: playedSeconds
        });
      }
    }

    this.props.updateCurrentTrack({
      contentId: element.id,
      startTimeStamp: getCurrentTimeStamp(),
      endTimeStamp: null,
      startPosition: 0,
      endPosition: 0,
      buffer: 0
    });

    this.props.updateTrackThunk({
      contentId: element.id,
      startTimeStamp: getCurrentTimeStamp(),
      endTimeStamp: null,
      startPosition: 0,
      endPosition: 0,
      buffer: 0
    });


    // tracklist animation
    // if (i === 0) {
    //   document.getElementById("bottom").style.top = "0px";
    // }
    // let sum = 0;
    // for (let k = 0; k < i; k++) {
    //   let clientHeight1 = document.querySelector('#link' + k).clientHeight;
    //   let clientHeight2 = document.querySelector('#link' + i).clientHeight;
    //   document.getElementById("bottom").style.height = clientHeight2 + "px";
    //   sum += clientHeight1;
    //   document.getElementById("bottom").style.top = sum + "px";
    // }



  }

  next = () => {
    let permission = this.checkPermission();
    if (!permission) {
      return;
    }
    let currentTrack = parseInt(this.props.playerSlice.index);

    let nextTrack = currentTrack === this.props.tracks.length - 1 ? 0 : currentTrack + 1;
    this.props.setPlayer({
      ...this.props.playerSlice,
      url: this.props.tracks[nextTrack].original_source,
      poster: `${config.MEDIA_URL}/${this.props.media.cover_image}`,
      time: getDuration(this.props.tracks[nextTrack].duration),
      bgimage: `${config.MEDIA_URL}/${this.props.media.cover_image}`,
      title: this.props.tracks[nextTrack].title,
      artist: formatArtistName(this.props.tracks[nextTrack].artists),
      index: nextTrack,
      filetype: this.props.tracks[nextTrack].filetype,
      contentId: this.props.tracks[nextTrack].id,
      played: 0
    })

    let playedSeconds = this.props.playerSlice.played * this.props.playerSlice.duration

    if (this.props.playerSlice.playing) {

      if (this.props.currentTrackInfo.contentId > 0) {
        this.props.updateTrackThunk({
          ...this.props.currentTrackInfo,
          endTimeStamp: getCurrentTimeStamp(),
          endPosition: playedSeconds
        });
      }

      this.props.updateTrackThunk({
        contentId: this.props.tracks[nextTrack].id,
        startTimeStamp: getCurrentTimeStamp(),
        endTimeStamp: null,
        startPosition: 0,
        endPosition: 0,
        buffer: 0
      });
    }

    this.props.updateCurrentTrack({
      contentId: this.props.tracks[nextTrack].id,
      startTimeStamp: getCurrentTimeStamp(),
      endTimeStamp: null,
      startPosition: 0,
      endPosition: 0,
      buffer: 0
    });

    /**8 tracklist animation */

    // if (nextTrack === 0) {
    //   document.getElementById("bottom").style.top = "0px";
    // }
    // let sum = 0;
    // for (let k = 0; k < nextTrack; k++) {
    //   let clientHeight1 = document.querySelector('#link' + k).clientHeight;
    //   let clientHeight2 = document.querySelector('#link' + nextTrack).clientHeight;
    //   document.getElementById("bottom").style.height = clientHeight2 + "px";
    //   sum += clientHeight1;
    //   document.getElementById("bottom").style.top = sum + "px";
    // }
  }

  handleEnded = () => {
    let permission = this.checkPermission();
    if (!permission) {
      return;
    }
    let currentTrack = parseInt(this.props.playerSlice.index);
    let nextTrack = currentTrack === this.props.tracks.length - 1 ? 0 : currentTrack + 1;

    if (nextTrack == 0) {
      this.props.setPlayer({
        ...this.props.playerSlice,
        playing: false
      })
    } else {
      this.props.setPlayer({
        ...this.props.playerSlice,
        url: this.props.tracks[nextTrack].original_source,
        poster: `${config.MEDIA_URL}/${this.props.media.cover_image}`,
        time: getDuration(this.props.tracks[nextTrack].duration),
        bgimage: `${config.MEDIA_URL}/${this.props.media.cover_image}`,
        title: this.props.tracks[nextTrack].title,
        artist: formatArtistName(this.props.tracks[nextTrack].artists),
        index: nextTrack,
        playing: true,
        filetype: this.props.tracks[nextTrack].filetype,
        contentId: this.props.tracks[nextTrack].id,
        played: 0
      })
    }


    let playedSeconds = this.props.playerSlice.played * this.props.playerSlice.duration

    if (this.props.playerSlice.playing) {
      if (this.props.currentTrackInfo.contentId > 0) {
        this.props.updateTrackThunk({
          ...this.props.currentTrackInfo,
          endTimeStamp: getCurrentTimeStamp(),
          endPosition: playedSeconds
        });
      }
    }

    if (nextTrack != 0 && !this.props.playerSlice.iphonePlayer) {
      this.props.updateTrackThunk({
        contentId: this.props.tracks[nextTrack].id,
        startTimeStamp: getCurrentTimeStamp(),
        endTimeStamp: null,
        startPosition: 0,
        endPosition: 0,
        buffer: 0
      });

      this.props.updateCurrentTrack({
        contentId: this.props.tracks[nextTrack].id,
        startTimeStamp: getCurrentTimeStamp(),
        endTimeStamp: null,
        startPosition: 0,
        endPosition: 0,
        buffer: 0
      });
    }




    // this.setState({ playing: true });
    /**8 tracklist animation */

    // if (nextTrack === 0) {
    //   document.getElementById("bottom").style.top = "0px";
    // }
    // let sum = 0;
    // for (let k = 0; k < nextTrack; k++) {
    //   let clientHeight1 = document.querySelector('#link' + k).clientHeight;
    //   let clientHeight2 = document.querySelector('#link' + nextTrack).clientHeight;
    //   document.getElementById("bottom").style.height = clientHeight2 + "px";
    //   sum += clientHeight1;
    //   document.getElementById("bottom").style.top = sum + "px";
    // }
  }
  previous = () => {
    let permission = this.checkPermission();
    if (!permission) {
      return;
    }
    let currentTrack = parseInt(this.props.playerSlice.index);
    let prevTrack = currentTrack === 0 ? this.props.tracks.length - 1 : currentTrack - 1;
    this.props.setPlayer({
      ...this.props.playerSlice,
      url: this.props.tracks[prevTrack].original_source,
      poster: `${config.MEDIA_URL}/${this.props.media.cover_image}`,
      time: getDuration(this.props.tracks[prevTrack].duration),
      bgimage: `${config.MEDIA_URL}/${this.props.media.cover_image}`,
      title: this.props.tracks[prevTrack].title,
      artist: formatArtistName(this.props.tracks[prevTrack].artists),
      index: prevTrack,
      filetype: this.props.tracks[prevTrack].filetype,
      contentId: this.props.tracks[prevTrack].id,
      played: 0,
    })

    let playedSeconds = this.props.playerSlice.played * this.props.playerSlice.duration

    if (this.props.playerSlice.playing) {
      if (this.props.currentTrackInfo.contentId > 0) {
        this.props.updateTrackThunk({
          ...this.props.currentTrackInfo,
          endTimeStamp: getCurrentTimeStamp(),
          endPosition: playedSeconds
        });
      }

      this.props.updateTrackThunk({
        contentId: this.props.tracks[prevTrack].id,
        startTimeStamp: getCurrentTimeStamp(),
        endTimeStamp: null,
        startPosition: 0,
        endPosition: 0,
        buffer: 0
      });
    }

    this.props.updateCurrentTrack({
      contentId: this.props.tracks[prevTrack].id,
      startTimeStamp: getCurrentTimeStamp(),
      endTimeStamp: null,
      startPosition: 0,
      endPosition: 0,
      buffer: 0
    });

    /**8 tracklist animation */

    // if (prevTrack === 0) {
    //   document.getElementById("bottom").style.top = "0px";
    // }
    // let sum = 0;
    // for (let k = 0; k < prevTrack; k++) {
    //   let clientHeight1 = document.querySelector('#link' + k).clientHeight;
    //   let clientHeight2 = document.querySelector('#link' + prevTrack).clientHeight;
    //   document.getElementById("bottom").style.height = clientHeight2 + "px";
    //   sum += clientHeight1;
    //   document.getElementById("bottom").style.top = sum + "px";
    // }
  }

  // -----

  stopSample = () => {

    const videoJs = document.querySelectorAll(".vjs-tech");
    if (videoJs) {
      videoJs.forEach((videoJs) => {
        videoJs.pause();
        videoJs.currentTime = 0;
      });
    }
  }

  hideControl = () => {
    if (document.querySelector('#full').classList.contains('FullScreen')) {
      document.querySelector('#full').classList.add('hide');
    }
  }

  showControl = () => {
    if (document.querySelector('#Rplayer')) {
      if (document.querySelector('#full').classList.contains('FullScreen')) {
        if (document.querySelector('#full').classList.contains('hide')) {
          document.querySelector('#full').classList.remove('hide');
          let timer;
          clearTimeout(timer);
          timer = setTimeout(this.hideControl, 3000);
        }
      }
    }
  }

  mhandleFullScreen = () => {
    if (this.myElementRef.current.requestFullscreen) {
      this.myElementRef.current.requestFullscreen();
      this.props.setPlayer({
        ...this.props.playerSlice,
        isFull: true
      })
    } else if (document.querySelector('video') && document.querySelector('video').webkitEnterFullscreen) { //for iphone
      this.props.setPlayer({
        ...this.props.playerSlice,
        iphonePlayer: true
      })
      document.querySelector('video').webkitEnterFullscreen();
    }
    setTimeout(this.hideMobControls, 5000);


  };

  hideMobControls = () => {
    let mControls = document.querySelector(".mobile-fullscreen-controls");
    if (mControls && mControls.classList.contains('mFullScreen')) {
      mControls.style.opacity = 0;
      mControls.style.visibility = 'hidden';
    }
  }
  showMobControls = () => {
    let mControls = document.querySelector(".mobile-fullscreen-controls");
    if (mControls && mControls.classList.contains('mFullScreen')) {
      mControls.removeAttribute("style");
      let timer;
      clearTimeout(timer);
      timer = setTimeout(this.hideMobControls, 5000);
    }
  }


  handleClickFullscreen = () => {
    screenfull.request(findDOMNode(document.getElementById("Rplayer")))
    this.props.setPlayer({
      ...this.props.playerSlice,
      isFull: true
    })
    setTimeout(this.hideControl, 3000);
  }

  closeFullscreen = () => {
    document.exitFullscreen()
    this.props.setPlayer({
      ...this.props.playerSlice,
      isFull: false
    })
  }
  // -----


  handlePlayPause = () => {
    let permission = this.checkPermission();
    if (!permission) {
      return;
    }
    // let playedSeconds = this.props.playerSlice.played * this.props.playerSlice.duration
    let currentTrack = parseInt(this.props.playerSlice.index);
    if (this.props.tracks[currentTrack]) {
      if (this.props.playerSlice.playing) { // if clicked pause send the previous data and reset the counter

        if (this.props.currentTrackInfo.contentId > 0) {
          this.props.updateTrackThunk({
            ...this.props.currentTrackInfo,
            endTimeStamp: getCurrentTimeStamp(),
            endPosition: this.player.getCurrentTime()
          });
        }
        this.props.resetCurrentTrack();

      } else {

        //update the state
        this.props.updateCurrentTrack({
          contentId: this.props.tracks[currentTrack].id,
          startTimeStamp: getCurrentTimeStamp(),
          endTimeStamp: null,
          startPosition: this.player.getCurrentTime(),
          endPosition: 0,
          buffer: 0,
        });
        //update the database
        this.props.updateTrackThunk({
          contentId: this.props.tracks[currentTrack].id,
          startTimeStamp: getCurrentTimeStamp(),
          endTimeStamp: null,
          startPosition: this.player.getCurrentTime(),
          endPosition: 0,
          buffer: 0,
        });

      }
    }

    this.props.setPlayer({
      ...this.props.playerSlice,
      playing: !this.props.playerSlice.playing
    })



  }

  handleStop = () => {
    this.props.setPlayer({
      ...this.props.playerSlice,
      playing: false
    })
    let playedSeconds = this.props.playerSlice.played * this.props.playerSlice.duration
    let currentTrack = parseInt(this.props.playerSlice.index);
    if (this.props.tracks[currentTrack]) {
      if (this.props.currentTrackInfo.contentId > 0) {
        this.props.updateTrackThunk({
          ...this.props.currentTrackInfo,
          endTimeStamp: getCurrentTimeStamp(),
          endPosition: playedSeconds
        });
      }
      this.props.resetCurrentTrack();
    }
    // if (document.getElementById("home-player")) {
    //   document.querySelector("#Rplayer").style.display = "none";
    //   document.querySelector("#root").removeAttribute("style");
    // }
  }

  handleToggleControls = () => {
    let permission = this.checkPermission();
    if (!permission) {
      return;
    }
    const url = this.props.playerSlice.url
    this.props.setPlayer({
      ...this.props.playerSlice,
      controls: !this.props.playerSlice.controls,
      url: url,
      played: 0,
      loaded: 0,
      pip: false,
    })
  }

  handleToggleLight = () => {
    let permission = this.checkPermission();
    if (!permission) {
      return;
    }
    this.props.setPlayer({
      ...this.props.playerSlice,
      light: this.props.playerSlice.light
    })
  }

  handleToggleLoop = () => {
    let permission = this.checkPermission();
    if (!permission) {
      return;
    }
    this.props.setPlayer({
      ...this.props.playerSlice,
      loop: !this.props.playerSlice.loop
    })
  }

  handleVolumeChange = e => {
    this.props.setPlayer({
      ...this.props.playerSlice,
      volume: parseFloat(e.target.value),
      muted: false
    })
  }

  handleToggleMuted = () => {
    let permission = this.checkPermission();
    if (!permission) {
      return;
    }
    this.props.setPlayer({
      ...this.props.playerSlice,
      muted: !this.props.playerSlice.muted
    })
  }

  handleSetPlaybackRate = e => {
    let permission = this.checkPermission();
    if (!permission) {
      return;
    }
    this.props.setPlayer({
      ...this.props.playerSlice,
      playbackRate: parseFloat(e.target.value)
    })
  }

  handleOnPlaybackRateChange = (speed) => {
    let permission = this.checkPermission();
    if (!permission) {
      return;
    }
    this.props.setPlayer({
      ...this.props.playerSlice,
      playbackRate: parseFloat(speed)
    })
  }

  handleTogglePIP = () => {
    let permission = this.checkPermission();
    if (!permission) {
      return;
    }
    this.props.setPlayer({
      ...this.props.playerSlice,
      pip: !this.props.playerSlice.pip
    })
  }

  handlePlay = () => {
    // let permission = this.checkPermission();
    // if (!permission) {
    //   return;
    // }
    // this.props.setPlayer({
    //   ...this.props.playerSlice,
    //   playing: true
    // })
  }

  handleEnablePIP = () => {
    let permission = this.checkPermission();
    if (!permission) {
      return;
    }
    this.props.setPlayer({
      ...this.props.playerSlice,
      pip: false
    })
  }

  handleDisablePIP = () => {
    let permission = this.checkPermission();
    if (!permission) {
      return;
    }
    this.props.setPlayer({
      ...this.props.playerSlice,
      pip: false
    })
  }

  handlePause = () => {
    let permission = this.checkPermission();
    if (!permission) {
      return;
    }
    this.props.setPlayer({
      ...this.props.playerSlice,
      playing: false
    })
  }

  handleSeekMouseDown = e => {
    let permission = this.checkPermission();
    if (!permission) {
      return;
    }
    this.props.setPlayer({
      ...this.props.playerSlice,
      seeking: true,
    })



  }

  handleSeekChange = e => {
    let permission = this.checkPermission();
    if (!permission) {
      return;
    }
    this.props.setPlayer({
      ...this.props.playerSlice,
      played: parseFloat(e.target.value)
    })
    this.player.seekTo(parseFloat(e.target.value))


  }

  handleSeekMouseUp = e => {
    let permission = this.checkPermission();
    if (!permission) {
      return;
    }
    this.props.setPlayer({
      ...this.props.playerSlice,
      seeking: false
    })
    this.player.seekTo(parseFloat(e.target.value))


  }

  handleProgress = state => {

    // console.log('onProgress', state)
    let playedSeconds = state.played * this.props.playerSlice.duration
    if (this.props.currentTrackInfo.endTimeStamp == null) {
      this.props.updateCurrentTrack({
        ...this.props.currentTrackInfo,
        endTimeStamp: getCurrentTimeStamp(),
        endPosition: playedSeconds,
      });
    } else if (lastUpdatedExceed(this.props.currentTrackInfo.endTimeStamp)) {
      this.props.updateCurrentTrack({
        ...this.props.currentTrackInfo,
        endTimeStamp: getCurrentTimeStamp(),
        endPosition: playedSeconds,
      });
      this.props.updateTrackThunk({
        ...this.props.currentTrackInfo,
        endTimeStamp: getCurrentTimeStamp(),
        endPosition: playedSeconds,
      });
    }
    // We only want to update time slider if we are not currently seeking

    if (!this.props.playerSlice.seeking) {
      this.props.setPlayer({
        ...this.props.playerSlice,
        played: state.played,
      })
    }
    localStorage.setItem('_expireTime', getExpireTime())
  }

  handleDuration = (duration) => {
    // console.log('onDuration', duration)
    this.props.setPlayer({
      ...this.props.playerSlice,
      duration: duration,
    })
    this.player.seekTo(this.props.playerSlice.played)
  }

  renderLoadButton = (url, label) => {
    return (
      <button onClick={() => this.load(url)}>
        {label}
      </button>
    )
  }

  ref = player => {
    this.player = player
  }

  state = { isActive: true };
  state = { isTracklist: true };
  state = { ismqClass: true };
  state = { isReadMore: true };
  state = { networkError: false };
  state = { bufferTime: 0 };
  state = { BufferLogTime: null };


  toggleReadMore = () => {
    this.setState({ isReadMore: !this.state.isReadMore });
    this.setState({ isTracklist: false });
    var nm = document.getElementById('inner-music');
    if (!nm.classList.contains('video-type')) {
      nm.classList.add("toggle-music");
    }
  };

  ToggleClass = () => {
    this.setState({ isActive: !this.state.isActive });
    document.body.classList.toggle("toggled");
  };
  toggleTracklist = () => {
    this.setState({ isTracklist: !this.state.isTracklist });
    this.setState({ isReadMore: false });
    /*** for minimize player  */
    var nm = document.getElementById('inner-music');
    if (!nm.classList.contains('video-type')) {
      nm.classList.add("toggle-music");
    }



    // set active bar
    // if (document.querySelector('.m-active')) {
    //   document.getElementById("bottom").style.opacity = '0';
    //   let trackHeight = document.querySelector('.m-active').clientHeight;
    //   console.log(trackHeight);
    //   var list = document.getElementsByClassName('tab-row');
    //   for (var i = 0; i < list.length; i++) {
    //     if (list[i].classList.contains('m-active')) {
    //       console.log("trackId" + i);
    //       var top = trackHeight * (i - 1);
    //       document.getElementById("bottom").style.top = top + 'px';
    //       document.getElementById("bottom").style.opacity = '1';
    //     }
    //   }
    // }


  };
  minimizeTracklist = () => {
    this.setState({ isTracklist: false });
    this.setState({ isReadMore: false });
    var tp = document.getElementById('inner-music');
    if (tp.classList.contains('toggle-music')) {
      tp.classList.remove('toggle-music');
    }
  };
  cross = () => {
    //stop current song
    this.props.setPlayer({
      ...this.props.playerSlice,
      playing: false
    })

    document.getElementById('music-player').classList.remove("not-active");
    document.getElementById('music-player').classList.add("active");
    document.getElementById('player-component').classList.remove("not-active");
    document.getElementById('player-component').classList.add("active");

  };


  forwind = () => {
    this.checkPermission();
    //stop current song
    var updatetime = this.player.getCurrentTime() + 10;
    // console.log(updatetime);
    this.props.setPlayer({
      ...this.props.playerSlice,
      seeking: false
    })
    this.player.seekTo(parseFloat(updatetime))



  };
  backwind = () => {
    this.checkPermission();
    //stop current song
    var updatetime = this.player.getCurrentTime() - 10;
    // console.log(updatetime);
    this.props.setPlayer({
      ...this.props.playerSlice,
      seeking: false
    })
    this.player.seekTo(parseFloat(updatetime))



  };

  handleError = () => {

  }

  handleBuffer = () => {
    if (!navigator.onLine) {
      // console.log('Network error occurred');
      this.setState({
        networkError: true,
        BufferLogTime: {
          ...this.props.currentTrackInfo,
          endTimeStamp: getCurrentTimeStamp(),
          endPosition: this.player.getCurrentTime()
        }
      })
      this.handlePause();
    } else {
      if (this.state.networkError) {
        // console.log('resetting network error');
        this.setState({ restartTrack: true })
      }
      this.setState({ networkError: false })
    }

    this.setState({ bufferTime: getCurrentTimeStamp() })

    // console.log('buffering....');
  }
  handleBufferEnd = () => {

    let burrferTimeNew = 0;
    if (this.state.bufferTime && this.state.bufferTime != 0) {
      burrferTimeNew = getBufferTime(this.state.bufferTime);

    }
    let oldBuferTime = parseInt(this.props.currentTrackInfo.buffer) + parseInt(burrferTimeNew);
    let totalBuferTime = parseInt(this.props.currentTrackInfo.buffer);
    if (oldBuferTime > 0) {
      totalBuferTime = oldBuferTime;
    }

    // console.log('total buffer ' + totalBuferTime);
    // console.log('player buffer ' + this.props.currentTrackInfo.buffer);
    // console.log('main buffer ' + burrferTimeNew);
    // console.log('state buffer ' + this.state.bufferTime);
    // console.log('old buffer ' + oldBuferTime);
    this.setState({ bufferTime: 0 })
    this.props.updateCurrentTrack({
      ...this.props.currentTrackInfo,
      buffer: totalBuferTime
    });

    console.log('streaming....');
  }

  likeFun = async () => {
    if (!this.props.isAuthenticated) {
      this.setState({ redirect: true })
      return;
    }
    let data = {
      'type': 1,
      'main_id': this.props.media.id
    }
    if (!this.props.playerSlice.like) {
      try {
        await homeService.addToWatchlist(data);
        this.props.setPlayer({
          ...this.props.playerSlice,
          like: true
        })

        if (this.props.mediaSlice.media.id == this.props.media.id) {
          this.props.setLikeMedia({
            like: true
          })
        }

        return;
      } catch (error) {
        notification('error', 'Das hat nicht geklappt. Bitte versuche es später noch einmal.');
        return;
      }
    } else {
      try {
        await homeService.removeFromWatchlist(data);
        this.props.setPlayer({
          ...this.props.playerSlice,
          like: false
        })
        if (this.props.mediaSlice.media.id == this.props.media.id) {
          this.props.setLikeMedia({
            like: false
          })
        }
        return;
      } catch (error) {
        notification('error', 'Das hat nicht geklappt. Bitte versuche es später noch einmal.');
        return;
      }
    }

  }

  makePayment = () => {
    if (!this.props.isAuthenticated) {
      this.setState({ redirect: true })
      return false;
    }

    if (this.props.isExpired) {
      this.setState({ subRedirect: true })
      return false;
    }

    if (!this.props.canPlay) {
      this.setState({ paidRedirectWithNoti: true })
      return false;
    }

    return;
  }

  openPlyr = () => {

    const plyr_body = document.querySelector(".mobile-music-plyr");
    if (plyr_body.classList.contains("not-active")) {
      plyr_body.classList.remove("not-active")
      plyr_body.classList.add("active");
    }

    document.body.classList.add("mini-active");
    document.querySelector('#miniBg').style.backgroundImage = `url('${this.props.playerSlice.poster}')`;

    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'auto'
      });
    }, 100);

  }

  openPlyrFull = () => {
    document.body.classList.add("body_class");
    this.openPlyr();
    if (document.querySelector(".mainPlayerComponent").classList.contains("not-active")) {
      document.querySelector(".mainPlayerComponent").classList.remove("not-active")
      document.querySelector(".mainPlayerComponent").classList.add("active")
    }


  }



  closePlyr = () => {
    const plyr_body = document.querySelector(".mobile-music-plyr");
    if (plyr_body.classList.contains("active")) {
      plyr_body.classList.remove("active")
      plyr_body.classList.add("not-active");
    }

    if (document.body.classList.contains("mini-active")) {
      document.body.classList.remove("mini-active");
    }
  }
  closePlyrFull = () => {
    if (document.body.classList.contains("body_class")) {
      document.body.classList.remove("body_class");
    }
    this.closePlyr();
    // if (document.querySelector(".mainPlayerComponent").classList.contains("active")) {
    //   document.querySelector(".mainPlayerComponent").classList.remove("active")
    //   document.querySelector(".mainPlayerComponent").classList.add("not-active")
    // }
  }

  updatePlayPause = (playing) => {
    let permission = this.checkPermission();
    if (!permission) {
      return;
    }
    let currentTrack = parseInt(this.props.playerSlice.index);
    if (this.props.tracks[currentTrack]) {
      if (!playing) { // if clicked pause send the previous data and reset the counter

        if (this.props.currentTrackInfo.contentId > 0) {
          this.props.updateTrackThunk({
            ...this.props.currentTrackInfo,
            endTimeStamp: getCurrentTimeStamp(),
            endPosition: this.player.getCurrentTime()
          });
        }
        this.props.resetCurrentTrack();

      } else {

        //update the state
        this.props.updateCurrentTrack({
          contentId: this.props.tracks[currentTrack].id,
          startTimeStamp: getCurrentTimeStamp(),
          endTimeStamp: null,
          startPosition: this.player.getCurrentTime(),
          endPosition: 0,
          buffer: 0,
        });
        //update the database
        this.props.updateTrackThunk({
          contentId: this.props.tracks[currentTrack].id,
          startTimeStamp: getCurrentTimeStamp(),
          endTimeStamp: null,
          startPosition: this.player.getCurrentTime(),
          endPosition: 0,
          buffer: 0,
        });

      }
    }

    this.props.setPlayer({
      ...this.props.playerSlice,
      playing: playing
    })
  }

  render() {
    const isMediaPage = this.props.isMediaPage;
    // console.log(isMediaPage);
    const isActive = this.state.isActive;
    const isTracklist = this.state.isTracklist;
    const ismqClass = this.state.isActive;
    const isReadMore = this.state.isReadMore;
    const backUrl = this.props.backUrl;
    let previousUrl = window.sessionStorage.getItem("previousUrl");
    const { redirect, subRedirect, paidRedirect, paidRedirectWithNoti, restartTrack } = this.state;

    if (redirect) {
      // notification('info', 'You need to login first');
      return <Navigate to='/register' state={{ from: this.props.location }} />;
    }
    if (restartTrack) {
      return <Navigate to={window.location.pathname} state={{ mediaReset: true }} />;
    }

    if (subRedirect) {
      notification('info', 'Bitte schließe ein Abonnement ab.');
      return <Navigate to='/subscription' />;
    }

    if (paidRedirect) {
      notification('info', 'Bitte kaufe diesen Inhalt, um ihn wiedergeben zu können.');
      // return <Navigate to='/media-purchase' state={{ from: this.props.location }} />;
    }
    if (paidRedirectWithNoti) {
      return <Navigate to='/media-purchase' state={{ from: this.props.location }} />;
    }


    let text = this.props.media.short_description;
    let covertitle = this.props.media.artist_name;
    let coverartist = this.props.media.title;
    const { url, playing, controls, volume, muted, played, duration, like, iphonePlayer } = this.props.playerSlice

    // if (this.props.media.id && this.props.trackSlice.status === STATUS.LOADING) {
    //   return (
    //     <div></div>
    //     // <SkeletonTheme baseColor="#202020" highlightColor="#444">
    //     //   <Skeleton width="100%" height={500} />
    //     // </SkeletonTheme>
    //   );
    // }

    // if (this.props.trackSlice && !this.props.trackSlice.fetched) {
    //   return (
    //     <div></div>
    //     // <SkeletonTheme baseColor="#202020" highlightColor="#444">
    //     //   <Skeleton width="100%" height={ 500} />
    //     //   </SkeletonTheme>
    //   );
    // }

    // For Iphone devices native player
    const video = document.querySelector("video");
    if (video) {
      video.onpause = (event) => {
        if (iphonePlayer) {
          this.updatePlayPause(false);
        }

      };
      video.onplay = (event) => {
        if (iphonePlayer) {
          this.updatePlayPause(true);
        }
      };
      const onFullScreenExit = (e) => {
        this.props.setPlayer({
          ...this.props.playerSlice,
          isFull: false,
          iphonePlayer: false
        })
      };
      video.removeEventListener('webkitendfullscreen', onFullScreenExit);
      video.addEventListener('webkitendfullscreen', onFullScreenExit);

    }


    return (

      <>
        {!this.state.matches && (
          <div className={`mainPlayerComponent music-fill-cnt main  ${!this.props.media.id ? "not-active" : "active"}`} id={!this.state.matches ? "player-component" : ""}>
            <div className={`mobile-vs ${this.props.playerSlice.isFull ? "m-full" : "m-full-disabled"} ${this.props.playerSlice.isInitialize ? "player-initialized" : "not-initialized"}`}>

              <div id="music-player" className={`mobile-music-plyr ${!this.props.media.id ? "not-active" : "not-active"}`} style={{ backgroundImage: `url(${this.props.playerSlice.poster})` }}>
                {
                  isMediaPage ?
                    <button className='m-fs-btn mobile-full-screen2' onClick={this.openPlyr}></button>
                    :
                    // <Link className='mobile-full-screen2' to={previousUrl} onClick={this.openPlyr}></Link>
                    <button className='m-fs-btn mobile-full-screen2' onClick={this.openPlyrFull}></button>

                }

                <div className={`inner-music ${this.props.playerSlice.filetype == "1" ? 'audio-type' : 'video-type'}`} id="inner-music">
                  <div className="toggleTop videotoggleTop toggleTop2 mt-minus">
                    {
                      isMediaPage ?
                        <button className="togglebtn" id="tgl" onClick={this.closePlyr}><img src={'/images/dn.png'} alt="" className="t-btn1" /></button>
                        :
                        <button className="togglebtn" id="tgl" onClick={this.closePlyrFull}><img src={'/images/dn.png'} alt="" className="t-btn1" /></button>
                    }

                    <button className="fav" onClick={this.likeFun}>
                      {
                        like ?
                          <img className="heart1" src={'/images/heart-active.png'} />
                          :
                          <img className="heart1" src={'/images/heart.png'} />
                      }
                    </button>
                    <RWebShare
                      data={{
                        text: coverartist,
                        url: window.location,
                        title: "Share Your Link",
                      }}
                      onClick={() => console.log("shared successfully!")}
                    >
                      <button className="share" >
                        <img src={'/images/share.png'} alt="" className="share-icon" />
                      </button>
                    </RWebShare>
                  </div>
                  {/* <button className="crosstab" id="cross" onClick={this.cross}><i className="fa fa-times" aria-hidden="true"></i></button> */}
                  <div className="music-player--details">
                    <div className="inner-ms-dt">

                      <div className="details-img" onClick={this.minimizeTracklist} >
                        <div className="par-img-wp"><img className='poster-img' src={this.props.playerSlice.poster} alt="" /></div>
                        <div id="Rplayer" onClick={this.showMobControls} className={`m-fs ${this.props.playerSlice.isFull ? "fullscreen-enabled" : "fullscreen-disabled"}`} ref={this.myElementRef}>
                          {
                            this.props.playerSlice.isFull ? (
                              <div className={`mobile-fullscreen-controls ${this.props.playerSlice.isFull ? "mFullScreen" : "NotmFullScreen"}`}>
                                <div className="mobile-fs-top">
                                  <div className="close-m-fs" onClick={this.closeFullscreen}>
                                    <i className="fa fa-chevron-left"></i>
                                    <div className="m-fs-title">{this.props.playerSlice.title}</div>
                                  </div>
                                </div>

                                <div className="bottom-control-section">
                                  <div className="main-btns">
                                    <button type="button" className="skip-btn" aria-label="Previous" onClick={this.previous}>
                                      <img src={'/images/back.png'} alt="" width="12px" onClick={this.currentTime} />
                                    </button>
                                    <button type="button" className="backwind" aria-label="backwind" onClick={this.backwind}>
                                      <img src={'/images/forwind.png'} alt="" width="22px" />
                                    </button>
                                    <button onClick={this.handlePlayPause} type="button" className="play-btn" aria-label="Pause">
                                      {playing ?
                                        (
                                          <img src={'/images/pause.png'} alt="" width="29px" />
                                        )
                                        :
                                        (
                                          <img src={'/images/play.png'} alt="" width="29px" />
                                        )
                                      }
                                    </button>
                                    <button type="button" className="forwind" aria-label="forwind" onClick={this.forwind}>
                                      <img src={'/images/backwind.png'} alt="" width="22px" />
                                    </button>
                                    <button type="button" className="skip-btn" aria-label="Next" onClick={this.next}>
                                      <img src={'/images/next.png'} alt="" width="12px" />
                                    </button>
                                    <div className="minimize-m-fs">
                                      <button onClick={this.closeFullscreen}><img src="/images/minimize-btn.png" alt="CloseFullScreen" /></button>
                                    </div>
                                  </div>
                                  <div className="range-time">
                                    <div className="timer-lft"><span><Duration seconds={duration * played} /></span></div>
                                    <input
                                      type='range' min={0} max={0.999999} step='any' id="seek-fs"
                                      value={played}
                                      onMouseDown={this.handleSeekMouseDown}
                                      onChange={this.handleSeekChange}
                                      onMouseUp={this.handleSeekMouseUp}
                                    />
                                    <div className="timer-rgt">-<Duration seconds={duration * (1 - played)} /></div>
                                  </div>
                                </div>
                              </div>
                            ) : null
                          }
                          <div id='full' className={`m-player ${this.props.playerSlice.isFull ? "FullScreen" : "notFullScreen"}`} data-screen={this.props.playerSlice.isFull ? "FullScreen" : "notFullScreen"}>
                            {/* {this.props.playerSlice.isFull ? null :
                              (this.props.playerSlice.filetype === "1" ? null :
                                <img className='ph-fullscr' onClick={this.mhandleFullScreen} src="/images/fullscreen-btn.png" alt="" />)
                            } */}

                            {this.props.playerSlice.isFull ? null :
                              (this.props.playerSlice.filetype === "1" ? null :
                                <div className="fullscr-invisible-btn" onClick={this.mhandleFullScreen}></div>)
                            }



                            <ReactPlayer
                              ref={this.ref}
                              className='react-player'
                              width='100%'
                              height='250px'
                              url={this.props.playerSlice.url}
                              pip={false}
                              config={{
                                file: {
                                  attributes: {
                                    disablePictureInPicture: true,
                                    playsInline: true,
                                    poster: `${this.props.playerSlice.poster}`,

                                  }
                                }
                              }}
                              playing={this.props.playerSlice.playing}
                              controls={this.props.playerSlice.controls}

                              onReady={() =>
                                console.log()
                              }
                              onStart={() => console.log()}
                              onPlay={this.handlePlay}
                              onEnded={this.handleEnded}
                              onSeek={e => console.log()}
                              onError={this.handleError}
                              onBuffer={this.handleBuffer}
                              onBufferEnd={this.handleBufferEnd}
                              onProgress={this.handleProgress}
                              onDuration={this.handleDuration}

                            />
                          </div>
                        </div>
                      </div>




                      <div className="timer">
                        <div className="timer-lft"><span><Duration seconds={duration * played} /></span></div>
                        <div className="timer-rgt">-<Duration seconds={duration * (1 - played)} /></div>
                      </div>

                      <input
                        type='range' min={0} max={0.999999} step='any'
                        value={played}
                        onMouseDown={this.handleSeekMouseDown}
                        onChange={this.handleSeekChange}
                        onMouseUp={this.handleSeekMouseUp}
                      />



                      <div className="artist-info">
                        <div className={`no-marquee ${ismqClass ? "" : "long-line"} `} id="mq-ht">
                          <div className="content">

                            {this.props.playerSlice.title ?
                              (this.props.playerSlice.title > 30 ? <Marquee speed={30}>

                                <h3 className="details-title" style={{
                                  marginLeft: "20px"
                                }}>
                                  {this.props.playerSlice.title}
                                </h3>

                              </Marquee> : <h3 className="details-title">
                                <span className="mini-player-hide">{this.props.playerSlice.title}</span>
                                <span className="mini-player-show">{this.props.playerSlice.title}</span>
                              </h3>
                              )

                              : true}



                          </div>

                        </div>

                        <h4 className="details-artist" >{this.props.playerSlice.artist}</h4>
                      </div>

                      <div className="new-desp-con">
                        <button type="button" data-bs-toggle="offcanvas" data-bs-target="#songDescription2" aria-controls="offcanvasRight" className="player-fs-song-info"><img src="/images/song-info.png" alt="" /></button>
                      </div>






                      <div className="music-player--controls controls1">

                        <div className="d-none">
                          <button className='fullscreen-btn full-btn' style={{ display: this.props.playerSlice.isFull ? 'none' : 'block' }} onClick={this.handleClickFullscreen}>
                            <img src="/images/fullscreen-btn.png" alt="FullScreen" />
                          </button>
                          <button className='fullscreen-btn close-btn' style={{ display: this.props.playerSlice.isFull ? 'block' : 'none' }} onClick={this.closeFullscreen}>
                            <img src="/images/minimize-btn.png" alt="CloseFullScreen" />
                          </button>
                        </div>



                        <button type="button" className="backwind" aria-label="backwind" onClick={this.backwind}>
                          <img src={'/images/10-sec-back.png'} alt="" width="22px" decoding="sync" />
                        </button>
                        <button type="button" className="skip-btn" aria-label="Previous" onClick={this.previous}>
                          <img src={'/images/scp.png'} alt="" width="12px" decoding="sync" />
                        </button>
                        <button onClick={this.handlePlayPause} type="button"
                          className="play-btn"

                          aria-label="Pause">
                          {playing ?
                            (
                              <img src={'/images/pause.png'} alt="" width="29px" />
                            )
                            :
                            (
                              <img src={'/images/play.png'} alt="" width="29px" />
                            )
                          }
                        </button>

                        <button type="button" className="skip-btn next-btnn" aria-label="Next" onClick={this.next}>
                          <img src={'/images/scn.png'} alt="" width="12px" decoding="sync" />
                        </button>
                        {/* <button type="button" className="forwind" aria-label="forwind" onClick={this.forwind}>
                          <img src={'/images/10-sec-further.png'} alt="" width="22px" decoding="sync" />
                        </button> */}
                        <button type="button" data-bs-toggle="offcanvas" data-bs-target="#mediaPlaylist" aria-controls="offcanvasRight" className="forwind openPlaylistBtn"><img src="/images/tracklist.png" /></button>

                      </div>
                    </div>



                    {/* <div className={`tracklist ${isTracklist ? "tgltrack" : ""} `} >

                      <div className="tracklist-top" onClick={this.toggleTracklist}>
                        <div className="tracklist-lft">{`${(this.props.playerSlice.index + 1)} `}. {this.props.playerSlice.title}</div>
                        <div className="tracklist-rgt" > <img src="/images/arrow-down.png" alt="" /> </div>
                      </div>

                      <div className="tracklist-open">
                        <div className="tracklist-btm-top">
                          <div className="tab-row" >
                            <div className="tab-col">Nr.</div>
                            <div className="tab-col">Titel & Artist</div>
                            <div className="tab-col duration-title">Dauer</div>
                          </div>
                        </div>
                        <div className="tab-btm-wp">
                          {this.props.tracks && this.props.tracks.map((element, i) => (
                            <div className={`tab-row ${(this.props.playerSlice.index) === i ? "m-active" : ""} 
                            ${(this.props.playerSlice.contentId) == element.id ? "content-active" : ""}
                            `} onClick={() => this.changeTrack(element, i)} id={`link${i}`} key={element.id}>
                              <div className="tab-col">{i + 1}.</div>
                              <div className="tab-col">
                                <h3 className="marque-text">{element.title}</h3>
                                <div className="marque-tracklist-text">


                                  {element.title ?
                                    (element.title.length > 30 ? <Marquee velocity={4}>
                                      {times(7, Number).map(id => (

                                        <h3 key={id} style={{
                                          marginRight: "20px"
                                        }}>
                                          {element.title}
                                        </h3>
                                      ))}
                                    </Marquee> : <h3 >{element.title}</h3>)

                                    : true}


                                </div>
                                <p className="tab-col-artist">{formatArtistName(element.artists)}</p>
                              </div>
                              <div className="tab-col tab-col-duration2">{getDuration(element.duration)}</div>

                            </div>

                          ))}
                          <div className='bottom-div  active' id='bottom'></div>
                        </div>
                      </div>

                    </div>


                    <div className={`readmore-sec ${isReadMore ? "not-tgl-rd" : "tgl-rd"}`}>
                      <div className="in-rd">

                        {
                          text &&
                          <>
                            <div className="text des-text1">

                              <div id="desText" dangerouslySetInnerHTML={{ __html: `${isReadMore ? text : text.slice(0, 40)} ` }}>
                              </div>

                              {
                                text.length < 50 ? "" :
                                  <span id="readBtn" onClick={this.toggleReadMore} className="read-or-hide">
                                    {isReadMore ? "" : " Mehr lesen "}
                                  </span>
                              }


                            </div>
                          </>
                        }

                      </div>



                    </div> */}

                  </div>
                </div>

              </div>
              <div className="music-player--controls controls2">

                <div className="d-none">
                  <button className='fullscreen-btn full-btn' style={{ display: this.props.playerSlice.isFull ? 'none' : 'block' }} onClick={this.handleClickFullscreen}>
                    <img src="/images/fullscreen-btn.png" alt="FullScreen" />
                  </button>
                  <button className='fullscreen-btn close-btn' style={{ display: this.props.playerSlice.isFull ? 'block' : 'none' }} onClick={this.closeFullscreen}>
                    <img src="/images/minimize-btn.png" alt="CloseFullScreen" />
                  </button>
                </div>



                <button type="button" className="backwind" aria-label="backwind" onClick={this.backwind}>
                  <img src={'/images/10-sec-back.png'} alt="" width="22px" decoding="sync" />
                </button>
                <button type="button" className="skip-btn" aria-label="Previous" onClick={this.previous}>
                  <img src={'/images/scp.png'} alt="" width="12px" decoding="sync" />
                </button>
                <button onClick={this.handlePlayPause} type="button"
                  className="play-btn"

                  aria-label="Pause">
                  {playing ?
                    (
                      <img src={'/images/pause.png'} alt="" width="29px" />
                    )
                    :
                    (
                      <img src={'/images/play.png'} alt="" width="29px" />
                    )
                  }
                </button>

                <button type="button" className="skip-btn next-btnn" aria-label="Next" onClick={this.next}>
                  <img src={'/images/scn.png'} alt="" width="12px" decoding="sync" />
                </button>
                {/* <button type="button" className="forwind" aria-label="forwind" onClick={this.forwind}>
                          <img src={'/images/10-sec-further.png'} alt="" width="22px" decoding="sync" />
                        </button> */}
                <button type="button" data-bs-toggle="offcanvas" data-bs-target="#mediaPlaylist" aria-controls="offcanvasRight" className="forwind openPlaylistBtn"><img src="/images/tracklist.png" /></button>

              </div>

            </div>
            <MediaDescComponent desc={text} album={coverartist} artist={covertitle} media={this.props.media} stopSample={this.stopSample} handleStop={this.handleStop} modalId={"songDescription2"} mediaId={this.props.media.id} sampleModalId={"#sampleModal"} />
            {
              this.props.trackSlice && this.props.trackSlice.fetched &&
              <MediaPlaylistComponent contentId={this.props.playerSlice.contentId} tracks={this.props.tracks} desc={text} album={coverartist} artist={covertitle} changeTrack={this.changeTrack} mediaId={this.props.media.id} />

            }
          </div>

        )
        }
        {this.state.matches && (
          <div className={`music-fill-cnt main ${isActive ? "not-active" : "active"}`} id={!this.state.matches ? "player-component" : ""}>
            <div className={`desktop-vs ${this.props.playerSlice.isInitialize ? "player-initialized" : "not-initialized"}`} id={this.props.media.id ? 'details-player' : 'details-player'}>
              <div className={`desktop-minipl ${isActive ? "not-active" : "active"}`} id={this.props.media.id ? 'home-player' : 'home-player'}>
                {/* <div className="mini-bg-img" ></div> */}
                <div className={`inner-music ${this.props.playerSlice.filetype == "1" ? 'audio-type' : 'video-type'}`} id="inner-music">
                  <div className="desk-link"><Link to={backUrl}> <i className="fa fa-chevron-left"></i> {this.props.pageName}</Link></div>
                  <div className="flex-desk">
                    <div className="flex-lft" id='Rplayer' onMouseMove={this.showControl}>
                      <div id='full' className={`inner-ms-dt ${this.props.playerSlice.isFull ? "FullScreen" : "notFullScreen"}`}>
                        {
                          previousUrl &&
                          <Link to={previousUrl} className="fs-cover-link"></Link>
                        }
                        <div className="details-img" style={{ backgroundImage: `url(${this.props.playerSlice.poster})` }}>

                          <ReactPlayer
                            ref={this.ref}
                            className='react-player'
                            width='100%'
                            height='250px'

                            url={this.props.playerSlice.url}

                            pip={this.props.playerSlice.pip}
                            config={{
                              file: {
                                attributes: {
                                  disablePictureInPicture: true,
                                  poster: `${this.props.playerSlice.poster}`,
                                },
                                forceAudio: true,
                              }
                            }}

                            playing={this.props.playerSlice.playing}
                            controls={this.props.playerSlice.controls}
                            onReady={() => console.log()}
                            onStart={() => console.log()}
                            onPlay={this.handlePlay}
                            onEnded={this.handleEnded}
                            volume={this.props.playerSlice.volume}
                            muted={this.props.playerSlice.muted}
                            onSeek={e => console.log()}
                            onError={this.handleError}
                            onBuffer={this.handleBuffer}
                            onBufferEnd={this.handleBufferEnd}
                            onProgress={this.handleProgress}
                            onDuration={this.handleDuration}
                          />


                          <div className="title-top">
                            <div className="fs-top-section" onClick={this.closeFullscreen}>
                              <i className="fa fa-chevron-left"></i>
                              <div className="fs-title">{this.props.playerSlice.title}</div>
                            </div>
                          </div>

                          <div className="player-overlay"></div>
                          <button className='fullscreen-btn full-btn' style={{ display: this.props.playerSlice.isFull ? 'none' : 'block' }} onClick={this.handleClickFullscreen}>
                            <img src="/images/fs-btn2.png" alt="FullScreen" />
                          </button>
                        </div>

                        <div className="song-btm">
                          <input
                            type='range' min={0} max={0.999999} step='any' id="seek"
                            value={played}
                            onMouseDown={this.handleSeekMouseDown}
                            onChange={this.handleSeekChange}
                            onMouseUp={this.handleSeekMouseUp}
                          />
                          <div className="timer">
                            <div className="timer-lft"><span><Duration seconds={duration * played} /></span></div>
                            <div className="timer-rgt">-<Duration seconds={duration * (1 - played)} /></div>
                          </div>
                          <div className="music-player--controls">
                            <button
                              type="button"
                              className="skip-btn"
                              aria-label="Previous"
                              onClick={this.previous}


                            >
                              <img src={'/images/back.png'} alt="" width="12px" onClick={this.currentTime} />

                            </button>

                            <button
                              type="button"
                              className="backwind"
                              aria-label="backwind"
                              onClick={this.backwind}


                            >
                              <img src={'/images/forwind.png'} alt="" width="22px" />

                            </button>
                            <button onClick={this.handlePlayPause} type="button"
                              className="play-btn"

                              aria-label="Pause">
                              {playing ?
                                (
                                  <img src={'/images/pause.png'} alt="" width="29px" />
                                )
                                :
                                (
                                  <img src={'/images/play.png'} alt="" width="29px" />
                                )
                              }
                            </button>


                            <button
                              type="button"
                              className="forwind"
                              aria-label="forwind"
                              onClick={this.forwind}

                            >
                              <img src={'/images/backwind.png'} alt="" width="22px" />

                            </button>
                            <button
                              type="button"
                              className="skip-btn"
                              aria-label="Next"
                              onClick={this.next}

                            >
                              <img src={'/images/next.png'} alt="" width="12px" />

                            </button>


                            <div className={`volume-section ${muted === true || volume === 0 ? "muted1" : ""}`}>
                              <button id='muted' className='vol-btn' onClick={this.handleToggleMuted}>
                                {muted === true || volume === 0 ?
                                  (
                                    <img src={'/images/volume_off.png'} alt="" />
                                  )
                                  :
                                  (
                                    <img src={'/images/volume_on.png'} alt="" />
                                  )
                                }

                              </button>
                              <input id='volume' type='range' min={0} max={1} step='any' value={muted ? 0 : volume} onChange={this.handleVolumeChange} />
                            </div>

                            <button className='fullscreen-btn close-btn' style={{ display: this.props.playerSlice.isFull ? 'block' : 'none' }} onClick={this.closeFullscreen}>
                              <img src="/images/minimize-btn.png" alt="CloseFullScreen" />
                            </button>

                            <div className="fsbtn">
                              {
                                this.props.playerSlice.filetype == "1" ?
                                  <Link to={previousUrl}><img src="/images/fullscreen-btn.png" alt="" /></Link>
                                  :
                                  <img onClick={this.handleClickFullscreen} src="/images/fullscreen-btn.png" alt="" />
                              }

                              {/* <button className='close-btn2' onClick={this.handleStop}><i className="fa fa-times" aria-hidden="true"></i></button> */}
                            </div>

                            <div className="mini-song-details">
                              <div className="s-name">{this.props.playerSlice.title}</div>
                              <div className="a-name">{this.props.playerSlice.artist}</div>
                            </div>

                          </div>
                        </div>




                      </div>
                    </div>
                    <div className="flex-rgt">
                      <div className="rgt-flx-inr">
                        <div className="artist-info">
                          <h4 className="details-artist" >{this.props.playerSlice.artist}</h4>
                          <div className={`marquee ${ismqClass ? "" : "long-line"} `} id="mq-ht">
                            <div className="content">
                              <h3 className="details-title">
                                {/* Warum Gott? - Vernünftiger Glaube Warum Gott? */}
                                {coverartist}
                              </h3>
                            </div>
                          </div>

                        </div>



                        {/* <div className={`tracklist ${isTracklist ? "tgltrack" : ""} `} >


                          <div className="tracklist-open">
                            <div className="tracklist-btm-top">
                              <div className="tab-row" >
                                <div className="tab-col">Nr.</div>
                                <div className="tab-col">Titel & Artist</div>
                                <div className="tab-col">Dauer</div>
                              </div>
                            </div>

                            <div className="tab-btm-wp">
                              {this.props.tracks && this.props.tracks.map((element, i) => (

                                <div className={`tab-row ${(this.props.playerSlice.index) === i ? "m-active" : ""} 
                                ${(this.props.playerSlice.contentId) == element.id ? "content-active2" : ""}
                                `} onClick={() => this.changeTrack(element, i)} id={`link${i}`} key={element.id}>
                                  <div className="tab-col"  >{i + 1}.</div>
                                  <div className="tab-col tab-col-middle">

                                    <h3 className="marque-text">{element.title}</h3>
                                    <div className="marque-tracklist-text">


                                      {element.title ?
                                        (element.title.length > 30 ? <Marquee velocity={4}>
                                          {times(5, Number).map(id => (

                                            <h3 className="tickerTxt" key={id} style={{ marginRight: "20px" }}>
                                              {element.title}
                                            </h3>
                                          ))}
                                        </Marquee> : <h3 >{element.title}</h3>)

                                        : true}


                                    </div>

                                    <p>{formatArtistName(element.artists)}</p>
                                  </div>
                                  <div className="tab-col tab-col-duration">{getDuration(element.duration)}</div>
                                </div>

                              ))}
                              <div className='bottom-div  active' id='bottom'></div>


                            </div>

                          </div>

                        </div> */}


                      </div>

                    </div>
                  </div>



                  <div className="description-flx des-fix2">
                    <div className="des-lft des-lft2">
                      <div className="des-sec" dangerouslySetInnerHTML={{ __html: text }}>



                      </div>
                    </div>
                    <div className="des-rgt des-rgt2">
                      {
                        this.props.media && this.props.media.sample_file_type > 0 && this.props.media.sample_file ?
                          <>
                            <button className="btn sample-btn" data-bs-toggle="modal" data-bs-target="#sampleModal" onClick={this.handleStop}> <img src="/images/ply.png" alt="" />Hörprobe anhören</button>
                          </>
                          :
                          null

                      }
                      {
                        !this.props.canPlay && this.props.media && this.props.media.price > 0 ?
                          <button className="btn fav-btn buy-btn" onClick={this.makePayment}>Zugang kaufen für {currencyFormatDE(this.props.media.price)}</button>
                          : null
                      }


                      <button className="btn fav-btn-new" onClick={this.likeFun}>
                        {
                          like ? <img src="/images/liked-icon.png" alt="" className="liked-icon" /> : <img src="/images/like-icon.png" alt="" className="like-icon" />
                        }
                        {
                          like ? 'Gespeichert' : 'Zu Favoriten hinzufügen'
                        }
                      </button>
                    </div>

                  </div>
                </div>

              </div>
            </div>
          </div >
        )
        }

        {/* =====sample video modal===== */}

        <div className={`modal fade ${this.props.media && this.props.media.sample_file_type == '1' ? 'audio-sample' : 'video-sample'}`} id="sampleModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <button onClick={this.stopSample} className="closeModal" data-bs-dismiss="modal" aria-label="Close"><i className="fa fa-times" aria-hidden="true"></i></button>
              <div className="modal-body">
                {
                  this.props.media && this.props.media.sample_file_type > 0 && this.props.media.sample_file ?
                    <SampleVideo sampleFile={this.props.media.sample_file} /> : null
                }
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
function mapStateToProps(state) {
  return {
    trackSlice: state.trackSlice,
    mediaSlice: state.mediaSlice,
    tracks: state.trackSlice.tracks,
    media: state.trackSlice.media,
    canPlay: state.trackSlice.canPlay,
    currentTrackInfo: state.currentTrackSlice.info,
    isAuthenticated: state.authSlice.isAuthenticated,
    playerSlice: state.playerSlice,
    isExpired: state.subscriptionSlice.isExpired,
    pageName: state.previousPageSlice.pageName,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    updateCurrentTrack: (data) => dispatch(updateCurrentTrack(data)),
    updateTrackThunk: (data) => dispatch(updateTrackThunk(data)),
    resetCurrentTrack: () => dispatch(resetCurrentTrack()),
    setPlayer: (data) => dispatch(setPlayer(data)),
    setLikeMedia: (data) => dispatch(setLikeMedia(data)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(VideoPlayer);