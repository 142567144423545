import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import homeService from "./services/home.service";
import STATUS from "./status";


const initialState = {
    status: STATUS.IDLE,
    fetched: false,
    canPlay: false,
    tracks: {},
    media: {},
}

export const trackThunk = createAsyncThunk(
    'trackThunk', async (id) => {
        const res = await homeService.tracks(id);
        return res.data;
    }
)

const compareTrackNumber = (a, b) => {
    if (a.track_number < b.track_number) { return -1; }
    if (a.track_number > b.track_number) { return 1; }
    return 0;
};

const trackSlice = createSlice({
    name: 'trackSlice',
    initialState,
    reducers: {
        resetTrack: (state) => {
            state.status = STATUS.IDLE
            state.fetched = false
            state.canPlay = false
            state.tracks = {}
            state.media = {}
        },
        setCanPlay: (state) => {
            state.canPlay = true
        },
        setTrack: (state, action) => {
            state.status = STATUS.SUCCESS
            state.fetched = true;
            state.media = action.payload.media;
            state.canPlay = action.payload.canPlay
            state.tracks = action.payload.tracks;
        },
    },

    extraReducers: {
        [trackThunk.rejected]: (state, action) => {
            state.status = STATUS.ERROR;
            state.fetched = false;
            state.canPlay = false
            state.media = {}
            state.tracks = {}
        },
        [trackThunk.pending]: (state, action) => {
            state.status = STATUS.LOADING
            state.fetched = false;
            state.canPlay = false
            state.media = {};
            state.tracks = {};
        },
        [trackThunk.fulfilled]: (state, action) => {
            state.status = STATUS.SUCCESS
            state.fetched = true;
            state.media = action.payload.data;
            state.canPlay = action.payload.data.canPlay;
            state.tracks = action.payload.data.content.sort(compareTrackNumber);
        }
    }
})

const { reducer } = trackSlice;
export const { resetTrack, setCanPlay, setTrack } = trackSlice.actions
export default reducer;