import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { BallTriangle } from 'react-loader-spinner';
import { Navigate, useParams } from 'react-router-dom'
import notification from '../../config/notification';
import { useDocumentTitle } from '../../hooks/setDocumentTitle';
import authService from '../../store/services/auth.service';
function VerifyEmail() {
    useDocumentTitle('Verify Email');
    const [loading, setLoading] = useState(true);
    const [verified, setVerified] = useState(false);
    const { token } = useParams();
    if (!token) {
        return <Navigate to="/register" />
    }
    useEffect(() => {
        (async () => {
            setLoading(true)
            try {
                await authService.verifyEmail({
                    token
                })
                setLoading(false)
                setVerified(true)
            } catch (er) {
                notification('warning', 'Dein Link ist abgelaufen. Bitte registriere dich erneut.')
                setLoading(false)
                setVerified(false)
            }
        })()
    }, [])
    if (!loading && !verified) {
        return <Navigate to="/register" />
    }

    if (!loading && verified) {
        return <Navigate to="/payment" state={{
            token: token
        }} />;
    }
    return (
        <div className="verify-email-page">
            <div className="loading-spinner-animation">
                <BallTriangle
                    height={100}
                    width={100}
                    radius={5}
                    color="#ffffff"
                    ariaLabel="ball-triangle-loading"
                    wrapperClass={{}}
                    wrapperStyle=""
                    visible={true}
                />
            </div>
        </div>
    )
}
export default VerifyEmail