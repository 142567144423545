import React from 'react'
import Skeleton from 'react-loading-skeleton'

function LoadingComponent() {
  return (
      <>
        {/* <div className="top-title position-relative title-skl">
            <Skeleton />
        </div> */}
          <div className="row loading-component">
              <div className="col-20">
                  <div className="v-card">
                      <Skeleton className='sk-img'/>
                      <div className="slider-btm-wp">
                          <div className="wp-lft w-100">
                              <h4 className="title"><Skeleton className='sk-txt' /></h4>
                          </div>
                      </div>
                  </div>
              </div>
              <div className="col-20">
                  <div className="v-card">
                    <Skeleton className='sk-img'/>
                      <div className="slider-btm-wp">
                          <div className="wp-lft w-100">
                              <h4 className="title"><Skeleton className='sk-txt' /></h4>
                          </div>
                      </div>
                  </div>
              </div>
              <div className="col-20">
                  <div className="v-card">
                  <Skeleton className='sk-img'/>
                      <div className="slider-btm-wp">
                          <div className="wp-lft w-100">
                              <h4 className="title"><Skeleton className='sk-txt' /></h4>
                          </div>
                      </div>
                  </div>
              </div>
              <div className="col-20 m-hide">
                  <div className="v-card">
                  <Skeleton className='sk-img'/>
                      <div className="slider-btm-wp">
                          <div className="wp-lft w-100">
                              <h4 className="title"><Skeleton className='sk-txt' /></h4>
                          </div>
                      </div>
                  </div>
              </div>
              <div className="col-20 m-hide">
                  <div className="v-card">
                  <Skeleton className='sk-img'/>
                      <div className="slider-btm-wp">
                          <div className="wp-lft w-100">
                              <h4 className="title"><Skeleton className='sk-txt' /></h4>
                          </div>
                      </div>
                  </div>
              </div>
              <div className="col-20 d-hide m-hide">
                  <div className="v-card">
                  <Skeleton className='sk-img'/>
                      <div className="slider-btm-wp">
                          <div className="wp-lft w-100">
                              <h4 className="title"><Skeleton className='sk-txt' /></h4>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </>
  )
}

export default LoadingComponent