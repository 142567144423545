import React from "react";
import { useLocation } from "react-router-dom";
import Accordion from "./Components/Accordion/Accordion";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { settingThunk } from "./store/settingSlice";
import { nanoid } from "@reduxjs/toolkit";
import { useDocumentTitle } from "./hooks/setDocumentTitle";
import { LazyLoadImage } from "react-lazy-load-image-component";
import SiteLogo from "./Components/SiteLogo";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const LegalInfo = () => {
  let query = useQuery();

  useDocumentTitle('Legal Info');
  const dispatch = useDispatch();
  const { setting, fetched } = useSelector((state) => state.settingSlice);
  useEffect(() => {
    if (!fetched) {
      dispatch(settingThunk())
    }
  }, [dispatch, fetched])

  useEffect(() => {
    var root = document.getElementById("root");
    root.removeAttribute("class");
  }, []);

 const questionsAnswers = [
    {
      question: "Datenschutzerklärung",
      answer: setting.data_protection
    },
    {
      question: "Cookie-Richtlinie",
      answer: setting.cookie_policy
    },
    {
      question: "EU-Datenschutzhinweise",
      answer: setting.eu_privacy_note
    },
    {
      question: "Impressum",
      answer: setting.imprint
    },
    {
      question: "Nutzungsvertrag",
      answer: setting.license_agreement
    },
  ]
  

  return (
    <div className="account-sec privacy-policy-wrp">
    <div className="container">
    <div className="em-logo position-relative mb-4 ">
          <Link to="/">
            <SiteLogo />
          </Link>
          <div className="back-sec">
            <Link to={-1}><img src="images/dn_ar.svg" alt="" /></Link>
          </div>
        </div>
        <div className="base-container">
      <div className="privacy-policy">
            <Accordion key={nanoid()} questionsAnswers={questionsAnswers} activeIdx={parseInt(query.get("activeIndex"))} />
      </div>
      </div>
    </div>
    
    
  </div>
  );
};

export default LegalInfo;