import React from "react";
import { useEffect, useRef, useState } from "react"
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Outlet, Link, useParams, useNavigate } from "react-router-dom";
import VideoPlayer from "./AudioDetails/VideoPlayer";
import { useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux";
import { resetUser } from "../store/auth/authSlice";
import STATUS from "../store/status";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import config from "../config/config";
import { resetTrack, trackThunk } from "../store/trackSlice";
import { resetSubscription } from "../store/subscriptionSlice";
import { resetPlayer, setPlayer } from "../store/playerSlice";
import { getCurrentTimeStamp, getDuration } from "../helpers/helpers";
import { updateTrackThunk } from "../store/currentTrackSlice";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { mediaThunk } from "../store/mediaSlice";
import SiteLogo from "./SiteLogo";


function Header() {

  const ref = useRef()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const { isAuthenticated: isLoggedin, authUser, status } = useSelector(state => state.authSlice)
  const dispatch = useDispatch();
  const { fetched, setting, status: settingStatus } = useSelector((state) => state.settingSlice)
  const { fetched: fetchedTrack } = useSelector((state) => state.trackSlice)
  const { info } = useSelector((state) => state.currentTrackSlice);

  const [isMediaPage, setMediaPage] = useState(false);
  useEffect(() => {
    const checkIfClickedOutside = e => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
        setIsMenuOpen(false);

      }
    }
    document.addEventListener("mousedown", checkIfClickedOutside)
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside)
    }
  }, [isMenuOpen])


  const { mediaId } = useParams();
  const { tracks, media } = useSelector((state) => state.trackSlice)
  const playerSlice = useSelector((state) => state.playerSlice)
  const navigate = useNavigate();
  // -----
  const location = useLocation()
  let previousUrl = window.sessionStorage.getItem("previousUrl");
  let currentUrl = `/media/${mediaId}`;



  useEffect(() => {
    // if (mediaId && (previousUrl != currentUrl || !fetchedTrack)) {
    if (mediaId) {
      // if (info.contentId > 0 && isLoggedin) {
      //   let playedSeconds = playerSlice.played * playerSlice.duration
      //   dispatch(updateTrackThunk({ ...info, endTimeStamp: getCurrentTimeStamp(), endPosition: playedSeconds }))
      // }
      // dispatch(resetTrack())
      // dispatch(resetPlayer())
      // dispatch(trackThunk(mediaId))
      dispatch(mediaThunk(mediaId));

    }
  }, [dispatch, mediaId])

  // useEffect(() => {
  //   if (tracks && tracks.length > 0) {
  //     let lastPlayedId = 0;
  //     if (media.last_played) {
  //       lastPlayedId = tracks.findIndex(x => x.id == media.last_played.content_id);
  //     }
  //     lastPlayedId = lastPlayedId > 0 ? lastPlayedId : 0;
  //     dispatch(setPlayer({
  //       ...playerSlice,
  //       url: tracks[lastPlayedId].original_source,
  //       poster: `${config.MEDIA_URL}/${media.cover_image}`,
  //       time: getDuration(tracks[lastPlayedId].duration),
  //       bgimage: `${config.MEDIA_URL}/${media.cover_image}`,
  //       title: tracks[lastPlayedId].title,
  //       index: lastPlayedId,
  //       volume: 0.7,
  //       muted: false,
  //       played: tracks[lastPlayedId].last_played ? parseFloat(tracks[lastPlayedId].last_played.endPosition / tracks[lastPlayedId].duration): 0,
  //       playing: false,
  //       duration: tracks[lastPlayedId].duration,
  //       like: media.watchlist,
  //       filetype: tracks[lastPlayedId].filetype,
  //       contentId: tracks[lastPlayedId].id,
  //       last_played: tracks[lastPlayedId].last_played,
  //     }))

  //   }
  // }, [tracks])


  let oldUrl = sessionStorage.getItem('oldUrl') ? sessionStorage.getItem('oldUrl') : '/';
  let backUrl = oldUrl;
  if (backUrl == location.pathname || backUrl == '/signin' || backUrl == 'register') {
    backUrl = '/';
  }

  useEffect(() => {
    if (!mediaId) {
      sessionStorage.setItem('backUrl', oldUrl);
      sessionStorage.setItem('oldUrl', location.pathname);
    }
    // location.state=location.pathname; 
    // var loc=location.pathname;
    if (mediaId) {
      // window.sessionStorage.setItem("previousUrl", `/media/${mediaId}`);
      // var elem6 = document.querySelector('.desktop-minipl');
      // if (typeof elem6 !== 'undefined' && elem6 !== null) {
      //   elem6.removeAttribute('id');
      // }
      // var elem7 = document.querySelector('.desktop-vs');
      // if (typeof elem7 !== 'undefined' && elem7 !== null) {
      //   elem7.setAttribute('id', 'details-player');
      // }

      // if (document.querySelector("#Rplayer")) {
      //   document.querySelector("#Rplayer").removeAttribute("style");
      // }

      mPlayer();
      mobilePlay();


      setMediaPage(true);


      // console.log("true");
      const playee = document.querySelector(".mobile-music-plyr");
      if (playee && playee.classList.contains("not-ctive")) {
        playee.classList.add("active");
        playee.classList.remove("not-active");
      }
    }
    else {

      setMediaPage(false);


      if (document.body.classList.contains("mini-active")) {
        document.body.classList.remove("mini-active");
      }
      // console.log("false");
      const playee = document.querySelector(".mobile-music-plyr");
      if (playee && playee.classList.contains("active")) {
        playee.classList.remove("active");
        playee.classList.add("not-active");
      }

      // var elem5 = document.querySelector('.desktop-minipl');
      // if (typeof elem5 !== 'undefined' && elem5 !== null) {
      //   elem5.setAttribute('id', 'home-player');
      // }
      mPlayer();
      mobilePlay();

    }
  }, [location])

  function mPlayer() {
    var root = document.getElementById("root");
    if (document.getElementById("details-player") && document.getElementById("home-player")) {
      root.classList.add('pbb-100');
    }
    else {
      // root.removeAttribute("style");
      root.classList.remove('pbb-100');
      // console.log("padding-false");
    }
  }

  function mobilePlay() {
    var root = document.getElementById("root");
    if (document.querySelector(".mobile-music-plyr") && document.querySelector(".mobile-music-plyr").classList.contains("active")) {
      root.classList.add('pbb-75');
    }
    else {
      root.classList.remove('pbb-75');
    }
  }

  const logout = () => {
    localStorage.clear();
    dispatch(resetUser());
    dispatch(resetSubscription());
    dispatch(resetTrack());
    sessionStorage.setItem("reload", true);
    navigate('/');
  }





  return (
    <div className="App ">
      <header>
        {/* <SkeletonTheme baseColor="#202020" highlightColor="#444"> */}
        <div className="header-wrp py-custom-3 " id={isMenuOpen ? "current" : "not-current"}>
          <div className="container position-relative" >
            <div className="navbar-cnt" ref={ref}>
              <div className="ham-icon"  >
                <input type="checkbox" id="checkbox3" className="checkbox3 visuallyHidden" onClick={() => setIsMenuOpen(oldState => !oldState)} />
                <label htmlFor="checkbox3">
                  <div className="hamburger hamburger3">
                    <span className="bar bar1"></span>
                    <span className="bar bar2"></span>
                    <span className="bar bar3"></span>
                    <span className="bar bar4"></span>
                  </div>
                </label>
              </div>

              <div className="navbar-t"  >
                <ul onClick={() => setIsMenuOpen(oldState => !oldState)}>
                  <li className="active" ><Link to="/search"><img src='/images/search.png' className='img-fluid' alt="search" />SUCHE</Link></li>
                  <li>
                    <Link to="/favourites"><img src='/images/fav2.png' className='img-fluid' alt="favourite" />
                      <span className="mob-text">Favoriten</span>
                      <span className="desk-text">FAVORITEN</span>
                    </Link>
                  </li>
                  <li className="account">
                    <Link className="user-icon-m" to="/account"><img src='/images/user.png' className='img-fluid' alt="user" />
                      <span className="mob-text">Mein Konto</span>
                      <span className="desk-text">Konto</span>
                    </Link>

                    <div className="user-icon-d">
                      <div className="p-menu">
                        {
                          status == STATUS.LOADING ?
                            // <Skeleton height={50} width={50} borderRadius={50} /> 
                            null
                            :
                            isLoggedin ? <img src={authUser.user_image} className='user2' alt="user" /> : <img src="/images/kanale-3.png" className='user2' alt="user" />
                        }

                      </div>
                      {
                        isLoggedin ?

                          <ul className="user-menu">
                            <li><Link to="/accountinfo">Konto</Link></li>
                            <li><Link to="/subscription">Mein Abonnement</Link></li>
                            <li><Link to="/legalinfo">Rechtliches</Link></li>
                            <li><Link to="/">Hilfe</Link></li>
                            <li onClick={logout} className="log-out">Abmelden</li>
                          </ul> :
                          <ul className="user-menu">
                            <li><Link to="/signin">ANMELDEN</Link></li>
                            <li><Link to="/join">REGISTRIEREN</Link></li>
                          </ul>
                      }
                    </div>


                  </li>

                  <li className="mob-only">
                    <Link to="/legalinfo?activeIndex=4" target="_blank" rel="noopener noreferrer">
                      <span className="mob-text">Nutzungsvertrag</span>
                    </Link>
                  </li>
                  <li className="mob-only">
                    <Link to="/legalinfo?activeIndex=0" target="_blank" rel="noopener noreferrer">
                      <span className="mob-text">Datenschutzerklärung</span>
                    </Link>
                  </li>
                  <li className="mob-only">
                    <Link to="/legalinfo?activeIndex=2" target="_blank" rel="noopener noreferrer">
                      <span className="mob-text">EU-Datenschutzhinweise</span>
                    </Link>
                  </li>
                  <li className="mob-only">
                    <Link to="/legalinfo?activeIndex=1" target="_blank" rel="noopener noreferrer">
                      <span className="mob-text">Cookie-Richtlinie</span>
                    </Link>
                  </li>
                  <li className="mob-only">
                    <Link to="/legalinfo?activeIndex=3" target="_blank" rel="noopener noreferrer">
                      <span className="mob-text">Impressum</span>
                    </Link>
                  </li>
                </ul>
              </div>


            </div>

            <div className="home-icon">
              <Link to="/"><img src={'/images/home.png'} className='img-fluid' alt="home" />

                <span className="desk-text">STARTSEITE</span>
              </Link>
            </div>
            <div className="logo text-center">
              <Link to="/">
                <SiteLogo />
              </Link>


            </div>

          </div>
        </div>
        {/* </SkeletonTheme> */}
      </header>

      <div className="audio-top-wrap" id="main_audiocomponent">
        <div className="container">
          {
            // tracks &&
            <VideoPlayer mediaId={mediaId} location={location} backUrl={backUrl} isMediaPage={isMediaPage} />

          }
        </div>
      </div>


      <Outlet />
    </div>

  );
}

export default Header;
