
import React, { useEffect } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { resetUser } from './store/auth/authSlice';
import { resetSubscription } from './store/subscriptionSlice';
import { resetTrack } from './store/trackSlice';
import { useDocumentTitle } from './hooks/setDocumentTitle';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import SiteLogo from './Components/SiteLogo';





function Account() {
  useDocumentTitle('Account');
  const { isAuthenticated: isLoggedin } = useSelector(state => state.authSlice)
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const logout = () => {
    dispatch(resetUser());
    dispatch(resetSubscription());
    dispatch(resetTrack());
    localStorage.clear();
    sessionStorage.setItem("reload", true);
    navigate('/');
  }

  useEffect(() => {
    var root = document.getElementById("root");
    root.removeAttribute("class");
  }, []);

  return (
    <div className="account-sec">
      <div className="container">
        <div className="em-logo position-relative mb-5">
          <Link to="/">
            <SiteLogo />
          </Link>
          
        </div>
        <div className="base-container">
        <div className="account-list">
          {
            isLoggedin? 
          <ul>
            <li><Link to="/accountinfo">Konto</Link></li>
            <li><Link to="/subscription">Mein Abonnement</Link></li>
            <li><Link to="/legalinfo">Rechtliches</Link></li>
            <li><Link to="/">Hilfe</Link></li>
            <li onClick={logout}><span className="log-out-m">Abmelden</span></li>
          </ul> :
          <ul>
              <li><Link to="/signin">ANMELDEN</Link></li>
              /* <li><Link to="/join">REGISTRIEREN</Link></li> */
          </ul>
          }
        </div>
        </div>
      </div>
      
      
    </div>
  );
}

export default Account;
