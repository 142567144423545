import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const useDocumentTitle = title => {
    const [document_title, setDoucmentTitle] = useState(title);
    const {setting} = useSelector((state)=>state.settingSlice)
    useEffect(() => {
        document.title = `${document_title} | ${setting.site_title ? setting.site_title : 'Noten'}`;
    }, [document_title]);

    return [document_title, setDoucmentTitle];
};

export { useDocumentTitle };