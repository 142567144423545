import React from 'react'
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';


function ProtectedRoute() {
    const { isAuthenticated } = useSelector((state)=>state.authSlice)
    let location = useLocation();
    if (!isAuthenticated) {
        return <Navigate to="/signin" state={{ from: location }} />;
    }

    return <Outlet />;
}

export default ProtectedRoute
