import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import homeService from "./services/home.service";
import STATUS from "./status";


const initialState = {
    status: STATUS.IDLE,
    updated: false,
    info: {
        contentId: 0,
        startTimeStamp: null,
        endTimeStamp: null,
        startPosition: 0,
        endPosition: 0,
        buffer:0,
    }
}

export const updateTrackThunk = createAsyncThunk(
    'updateTrackThunk', async (data) => {
        try {
            const res = await homeService.updateTrack(data);
            return res.data;
        } catch (error) {
            if (error.response.status == 401) {
                window.location.reload(false);
            }
        }
    }
)

const currentTrackSlice = createSlice({
    name: 'currentTrackSlice',
    initialState,
    reducers: {
        resetCurrentTrack: (state) => {
            state.status = STATUS.IDLE
            state.updated = false
            state.info = {
                contentId: 0,
                startTimeStamp: null,
                endTimeStamp: null,
                startPosition: 0,
                endPosition: 0,
                buffer: 0,
            }
        },
        updateCurrentTrack: (state,action) => {
            state.status = STATUS.IDLE
            state.updated = false
            state.info = action.payload
        },
    },
    extraReducers: {
        [updateTrackThunk.rejected]: (state, action) => {
            state.status = STATUS.ERROR;
        },
        [updateTrackThunk.pending]: (state, action) => {
            state.status = STATUS.LOADING
        },
        [updateTrackThunk.fulfilled]: (state, action) => {
            state.status = STATUS.SUCCESS
        }
    }

})
const { reducer } = currentTrackSlice;
export const { resetCurrentTrack, updateCurrentTrack } = currentTrackSlice.actions
export default reducer;