import React from 'react';
import { Link } from 'react-router-dom';
import SiteLogo from "./SiteLogo";

export default function Footer() {
  return (
    <footer>
      <SiteLogo />

      <nav>
        <Link to="/legalinfo?activeIndex=4" target="_blank" rel="noopener noreferrer">
          Nutzungsvertrag
        </Link>
        <Link to="/legalinfo?activeIndex=0" target="_blank" rel="noopener noreferrer">
          Datenschutzerklärung
        </Link>
        <Link to="/legalinfo?activeIndex=2" target="_blank" rel="noopener noreferrer">
          EU-Datenschutzhinweise
        </Link>
        <Link to="/legalinfo?activeIndex=1" target="_blank" rel="noopener noreferrer">
          Cookie-Richtlinie
        </Link>
        <Link to="/legalinfo?activeIndex=3" target="_blank" rel="noopener noreferrer">
          Impressum
        </Link>
      </nav>
    </footer>
  );
}
