import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link, useNavigate } from 'react-router-dom';
import notification from "../../config/notification";
import { useDocumentTitle } from "../../hooks/setDocumentTitle";
import authService from "../../store/services/auth.service";
import SiteLogo from "../SiteLogo";

export default function Join() {
    useDocumentTitle('Join');
    const email = useRef();
    const firstName = useRef();
    const lastName = useRef();
    const newsletter = useRef(null);
    const [error, setError] = useState(null);
    const [firstNameErr, setFirstNameErr] = useState(null)
    const [lastNameErr, setLastNameErr] = useState(null)
    const navigate = useNavigate();
    const checkMail = async (e) => {
        e.preventDefault();
        let first_name = firstName.current.value;
        let last_name = lastName.current.value;
        setFirstNameErr(null);
        setLastNameErr(null);
        if (first_name == '' || last_name == '') { // validation
            if (first_name == '') {
                setFirstNameErr('Gib deinen Vornamen an.');
            }
            if (last_name == '') {
                setLastNameErr('Gib deinen Nachnamen an.');
            }
            return;
        }
        setError(null);
        try {
            await authService.checkEmail({
                email: email.current.value
            })
            navigate('/license', { state: { email: email.current.value, newsletter: newsletter.current.checked, first_name, last_name } });
        } catch (er) {
            if (er.response && er.response.status === 422) {
                er.response.data.data.email ? setError(er.response.data.data.email[0]) : setError('');
            } else {
                notification('error', 'Das hat nicht geklappt. Bitte versuche es später noch einmal.')
            }
        }
    };

    useEffect(() => {
        var root = document.getElementById("root");
        root.removeAttribute("class");
    }, []);


    return (
        <div className='register-page'>
            <div className="account-sec privacy-policy-wrp">
                <div className="container">
                    <div className="em-logo position-relative mb-4 ">
                        <Link to="/">
                            <SiteLogo />
                        </Link>
                        <div className="back-sec">
                            <Link to="/register"><img src="images/dn_ar.svg" alt="" /></Link>
                        </div>
                    </div>
                    <div className="reg-container reg-2">
                        <h4 className="join-steps">SCHRITT 1 VON 5</h4>
                        <div className="join-form">
                            <h3 className='form-title form-title-new'>Gib deine Namen und deine E-Mail-Adresse ein</h3>
                            <form className="join join-new" onSubmit={checkMail}>
                                <div className='form-field half'>
                                    <input className='j-email' placeholder="Vorname" ref={firstName} />
                                    <p className="err mt-1">{firstNameErr}</p>
                                </div>
                                <div className='form-field half'>
                                    <input className='j-email' placeholder="Nachname" ref={lastName} />
                                    <p className="err mt-1">{lastNameErr}</p>
                                </div>
                                <div className='form-field full'>
                                    <input id='jMail' className='j-email' placeholder="E-Mail-Adresse" ref={email} />
                                    <p className="err">{error}</p>
                                </div>
                                <div className='form-field'>
                                    <div className="n-con">
                                        <input type="checkbox" name="notify" id="noti" ref={newsletter}
                                        />
                                        <label htmlFor="noti">Ja! Ich möchte per E-Mail über wichtige Neuerungen und ergänzende Angebote informiert werden. (Keine Sorge, wir übertreiben es nicht.) Du kannst die Einstellungen jederzeit anpassen.</label>
                                    </div>
                                </div>
                                <div className='form-field'>
                                    <div className="f-btn-section">
                                        <p className='f-links'>NOTEN.WERT MEDIA verwendet deine Daten, um dir ein bestmögliches, personalisiertes Erlebnis mit streamfair zu bieten. Du kannst deine Kommunikationseinstellungen jederzeit ändern. Wir können deine Daten wie in der <Link to="/legalinfo" className='fl-a'>Datenschutzerklärung</Link> beschrieben verarbeiten. Indem du auf "Weiter" klickst, bestätigst du, dass du unsere <Link to="/legalinfo" className='fl-a'>Datenschutzerklärung</Link>, <Link to="/legalinfo" className='fl-a'>Cookie-Richtlinie</Link> und <Link to="/legalinfo" className='fl-a'>EU-Datenschutzhinweise</Link> gelesen hast.</p>
                                        <button type="submit" className="join-btn f-btn">WEITER</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
