import React, { useEffect, useState } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { Bars } from "react-loader-spinner";
import subscriptionService from "../store/services/subscription.service";
import notification from "../config/notification";
import Errors from "./Errors";
import { useDispatch, useSelector } from "react-redux";
import STATUS from "../store/status";
import { setCanPlay } from "../store/trackSlice";
import { useDocumentTitle } from "../hooks/setDocumentTitle";
import { currencyFormatDE } from "../helpers/helpers";
import config from "../config/config";
import Skeleton from "react-loading-skeleton";
import { setCanPlayMedia } from "../store/mediaSlice";
import SiteLogo from "./SiteLogo";

export default function MediaPurchase() {
    useDocumentTitle('Purchase Media');
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [cards, setCards] = useState(null);
    const [cardActive, setCardActive] = useState(false)
    const [cardToken, setCardToken] = useState('');
    const [saveCard, setSaveCard] = useState(0);
    const [saveCardClass, setSaveCardClass] = useState('inActive');
    const [deleteNotify, setdeleteNotify] = useState(false);
    const [deletedCardNo, setDeletedCardNo] = useState(null);
    const [deletedCardToken, setDeletedCardToken] = useState(null);
    const { media, canPlay, status, fetched } = useSelector((state) => state.mediaSlice)
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    let previous_location = '/';
    if (location.state && location.state.from) {
        previous_location = location.state.from.pathname;
    } else {
        previous_location = '/';
    }


    const [cardNo, setcardNo] = useState('');
    const [carderr, setcardErr] = useState(null);
    const [carderrTxt, setcardErrTxt] = useState(null);
    const checkCard = event => {
        if (cardNo.length === 19) {
            setcardErr("success1");
            setcardErrTxt("Kartennummer verifiziert");
            return true;
        }
        else {
            setcardErr("error");
            setcardErrTxt("Geben Sie eine gültige Kartennummer ein");
        }
        return false;
    };
    const [MM, setMM] = useState('');
    const [MMerr, setMMerr] = useState('');
    const [MMerrTxt, setMMerrTxt] = useState('');
    const checkMM = event => {
        if (MM <= 12 && MM >= 1 && MM.length === 2) {
            setMMerr("success1");
            setMMerrTxt("");
            document.getElementById("YY").focus();
            return true;
        }
        else {
            setMMerr("error error4");
            setMMerrTxt("Bitte wählen Sie einen gültigen Monat");
        }
        return false;
    };
    const [YY, setYY] = useState('');
    const [YYerr, setYYerr] = useState('');
    const [YYerrTxt, setYYerrTxt] = useState('');
    const checkYY = event => {
        if (YY) {
            setYYerr("success1");
            setYYerrTxt("");
            return true;
        }
        else {
            setYYerr("error3");
            setYYerrTxt("Bitte wählen Sie ein gültiges Jahr");
        }
        return false;
    };
    const [Cvv, setCvv] = useState('');
    const [Cvverr, setCvverr] = useState('');
    const [CvverrTxt, setCvverrTxt] = useState('');

    var nameregex = /^[a-zA-Z ]+$/
    const [Cnamee, setCnamee] = useState('');
    const [Cnameeerr, setCnameeerr] = useState('');
    const [CnameeerrTxt, setCnameeerrTxt] = useState('');
    const checkCnamee = event => {
        if (Cnamee.match(nameregex)) {
            setCnameeerr("success1");
            setCnameeerrTxt("");
            return true;
        }
        else {
            setCnameeerr("error");
            setCnameeerrTxt("Bitte geben Sie einen gültigen Namen ein");
        }
        return false;
    };
    const payMent = async (e) => {
        e.preventDefault();

        if (cardToken == '') {
            let nameCheck = checkCnamee();
            let cardCheck = checkCard();
            let monthCheck = checkMM();
            let yearCheck = checkYY();

            if (!nameCheck || !cardCheck || !monthCheck || !yearCheck) {
                return false;
            }
        }
        if (media.price === 0 || canPlay) {
            notification('error', 'Dein Inhalt ist schon aktiv.');
            return false;
        }

        setLoading(true);
        try {
            let res = await subscriptionService.purchseMedia({
                'card_token': cardToken,
                'save_card': saveCard,
                'name': Cnamee,
                'card_number': cardNo,
                'expiry_month': MM,
                'expiry_year': YY,
                'cvv': Cvv,
                'media_id': media.id
            })


            if (res.data.success) {
                setLoading(false);
                notification('iconMessage', 'Dein Kauf war erfolgreich.', 500);
                dispatch(setCanPlay());
                return navigate(previous_location);
            } else {
                setLoading(false);
                notification('error', res.data.message)
            }

        } catch (er) {
            setLoading(false);
            if (er.response && er.response.status === 422) {
                setErrors(er.response.data.data);
            } else {
                notification('error', 'Das hat nicht geklappt. Bitte versuche es später noch einmal.')
            }
        }
    }

    const getCard = async () => {
        setProcessing(true)
        try {
            let res = await subscriptionService.getCardDetail();
            let result = res.data.data;
            setCards(res.data.data)
            if (result.length > 0) {
                let first = Object.values(result)[0];
                setCardToken(first.card_token);
            }
            setProcessing(false)
        } catch (error) {

        }
    }

    const saveCardd = () => {
        if (saveCard == 0) {
            setSaveCard(1);
            setSaveCardClass('active');
        }
        else {
            setSaveCard(0);
            setSaveCardClass('inActive');
        }
    }

    function setPayment(token) {
        setCardToken(token)
        setCardActive(false)
    }

    const setCreditCard = () => {
        setCardToken('')
        setCardActive(true)
    }

    function deleteCardd(card) {
        setDeletedCardNo(`${card.brand} ********${card.last4}`)
        setDeletedCardToken(card.card_token)
        setdeleteNotify(true);
    }

    const cardDelete = async () => {
        setLoading(true)
        try {
            await subscriptionService.deleteCard(deletedCardToken);
            getCard()
            setdeleteNotify(false)
            setDeletedCardNo('');
            notification('message', 'Erfolgreich gelöscht.')
        } catch (error) {
            notification('message', 'Das hat nicht geklappt. Bitte versuche es später noch einmal.')
        }
        setLoading(false)
    }

    useEffect(() => {
        getCard()
    }, []);

    if (canPlay) {
        return <Navigate to={previous_location} />
    }

    if (!fetched) {
        return <Navigate to={previous_location} />
    }

    if (status === STATUS.LOADING) {
        return <h2>Loading.....</h2>
    }

    useEffect(() => {
        var root = document.getElementById("root");
        root.removeAttribute("class");
    }, []);

    return (
        <div className='payment-page media-purchase-page'>
            {
                loading ? <Bars
                    height="80"
                    width="80"
                    color="#ffffff"
                    ariaLabel="bars-loading"
                    wrapperStyle={{}}
                    wrapperClass="loading-spinner-animation"
                    visible={true}
                /> : ''
            }
            <div className="account-sec privacy-policy-wrp">
                <div className="container">
                    <div className="em-logo position-relative mb-4 ">
                        <Link to="/">
                            <SiteLogo />
                        </Link>
                    </div>
                    <div className="reg-container reg-1">
                        <div className="purchase-head">
                            <h5>DU KAUFST DIESEN INHALT</h5>
                            <img src={`${config.MEDIA_URL}/${media.cover_image}`} alt="Media" />
                            <p>Nach dem Kauf darfst du im Rahmen deines Abos diesen Inhalt unbegrenzt oft wiedergeben, jedoch nicht an Dritte weitergeben. Mit dem Kauf akzeptierst du unsere Nutzungsbedingungen.</p>
                            <div className="p-second-head">Bitte wähle deine Zahlungsmethode:</div>
                        </div>
                        <div className="cards-section">
                            <div id="c1">
                                <img className='c-img' src="images/CreditCard.png" alt="" />
                                <div className="c-txt">Kreditkarte</div>
                            </div>

                            <div className="line3" ></div>
                        </div>

                        {
                            processing ? <Skeleton baseColor="#202020" highlightColor="#444" className="media-purchase-skeleton" />
                                :

                                cards && cards.length > 0 ?
                                    <>
                                        {
                                            cards.map((card) => {
                                                return (
                                                    <div className={`saved-cards ${cardToken == card.card_token ? 'activee' : 'inActivee'}`} key={card.card_token} >
                                                        <div className="click-sec" onClick={() => setPayment(card.card_token)}>
                                                            <div className="circle">
                                                                <div className="inner-circle"></div>
                                                            </div>
                                                            <p>{card.brand} ********{card.last4}</p>
                                                        </div>
                                                        <button className="deleteCard" onClick={() => deleteCardd(card)}><img src="images/delete-card.png" /></button>
                                                    </div>

                                                )
                                            })
                                        }


                                        <div className={`saved-cards ${cardActive ? 'activee' : 'inActivee'}`} onClick={setCreditCard}>
                                            <div className="click-sec">
                                                <div className="circle">
                                                    <div className="inner-circle"></div>
                                                </div>
                                                <p>Neue Kreditkarte</p>
                                            </div>

                                        </div>

                                        <div className="cards-details new-card-details">
                                            <Errors errors={errors} />
                                            <form action="" onSubmit={payMent}>
                                                <div className={`cards-section-new ${cardActive ? 'showing' : 'not-showing'}`}>
                                                    <div className="d1">
                                                        <label>KARTENINHABER</label>
                                                        <input type="text" name="" id="" onChange={event => setCnamee(event.target.value)} value={Cnamee} />
                                                        <span className={Cnameeerr}>{CnameeerrTxt}</span>
                                                    </div>
                                                    <div className="d1 num">
                                                        <label>KARTENNUMMER</label>
                                                        <input onChange={event => setcardNo(event.target.value.replace(/[^0-9]/gi, '').replace(/(.{4})/g, '$1 ').trim())} value={cardNo} type="text" name="card" id="card" maxLength={19} />
                                                        <div className="small-cards">
                                                            <img src="images/visa1.png" alt="" />
                                                            <img src="images/mastercard.png" alt="" />
                                                            <img src="images/american-express.png" alt="" />
                                                        </div>
                                                        <span className={carderr}>{carderrTxt}</span>
                                                    </div>
                                                    <div className="d2">
                                                        <div className="d1">
                                                            <label>GÜLTIG BIS</label>
                                                            <div className="mm-jj">
                                                                <input type="text" value={MM} onChange={event => setMM(event.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1'))} placeholder="MM" className="mm" maxLength="2" />
                                                                <span>/ &nbsp; &nbsp;</span>
                                                                <input id="YY" type="text" value={YY} onChange={event => setYY(event.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1'))} placeholder="JJ" className="jj" maxLength="2" />
                                                            </div>
                                                            <span className={MMerr}>{MMerrTxt}</span>
                                                            <span className={YYerr}>{YYerrTxt}</span>
                                                        </div>
                                                        <div className="d1">
                                                            <label>SICHERHEITSCODE</label>
                                                            <input className="cvv" value={Cvv} onChange={event => setCvv(event.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1'))} type="text" placeholder="CVV" maxLength="4" />
                                                            <span className={Cvverr}>{CvverrTxt}</span>
                                                        </div>
                                                    </div>
                                                    <div className="save-card-sec">
                                                        <button type="button" className={saveCardClass} onClick={saveCardd}></button>
                                                        <p>Kreditkarte für nächsten Einkauf speichern</p>
                                                    </div>
                                                </div>
                                                <button id="credit-btn" type="submit" className="join-btn f-btn">{`JETZT KAUFEN FÜR ${currencyFormatDE(media.price)}`}</button>

                                            </form>
                                        </div>


                                    </>

                                    :
                                    <>
                                        <div className="cards-details new-card-details">
                                            <Errors errors={errors} />
                                            <form action="" onSubmit={payMent}>

                                                <div>
                                                    <div className="d1">
                                                        <label>KARTENINHABER</label>
                                                        <input type="text" name="" id="" onChange={event => setCnamee(event.target.value)} value={Cnamee} />
                                                        <span className={Cnameeerr}>{CnameeerrTxt}</span>
                                                    </div>
                                                    <div className="d1 num">
                                                        <label>KARTENNUMMER</label>
                                                        <input onChange={event => setcardNo(event.target.value.replace(/[^0-9]/gi, '').replace(/(.{4})/g, '$1 ').trim())} value={cardNo} type="text" name="card" id="card" maxLength={19} />
                                                        <div className="small-cards">
                                                            <img src="images/visa1.png" alt="" />
                                                            <img src="images/mastercard.png" alt="" />
                                                            <img src="images/american-express.png" alt="" />
                                                        </div>
                                                        <span className={carderr}>{carderrTxt}</span>
                                                    </div>
                                                    <div className="d2">
                                                        <div className="d1">
                                                            <label>GÜLTIG BIS</label>
                                                            <div className="mm-jj">
                                                                <input type="text" value={MM} onChange={event => setMM(event.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1'))} placeholder="MM" className="mm" maxLength="2" />
                                                                <span>/ &nbsp; &nbsp;</span>
                                                                <input id="YY" type="text" value={YY} onChange={event => setYY(event.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1'))} placeholder="JJ" className="jj" maxLength="2" />
                                                            </div>
                                                            <span className={MMerr}>{MMerrTxt}</span>
                                                            <span className={YYerr}>{YYerrTxt}</span>
                                                        </div>
                                                        <div className="d1">
                                                            <label>SICHERHEITSCODE</label>
                                                            <input className="cvv" value={Cvv} onChange={event => setCvv(event.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1'))} type="text" placeholder="CVV" maxLength="4" />
                                                            <span className={Cvverr}>{CvverrTxt}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="save-card-sec">
                                                    <button type="button" onClick={saveCardd} className={saveCardClass}></button>
                                                    <p>Kreditkarte für nächsten Einkauf speichern</p>
                                                </div>
                                                <button id="credit-btn" type="submit" className="join-btn f-btn">{`PAY ${currencyFormatDE(media.price)}`}</button>

                                            </form>
                                        </div>
                                    </>
                        }

                        <Link to={-1} className="escape">Abbrechen</Link>

                    </div>
                </div>
            </div>
            <div className="delete-card-full-con" style={{ display: `${deleteNotify ? 'block' : 'none'}` }}>
                <div className="reg-container del-container2">
                    <div className="delete-card-confirm">
                        <div className="d-c-heading">Möchtest du die Kreditkarte</div>
                        <div className="d-c-heading">{deletedCardNo} wirklich löschen?</div>
                        <button className="join-btn confirm-deleteCard" onClick={cardDelete}>BESTÄTIGEN</button>
                        <button className="escape" onClick={() => { setdeleteNotify(false) }}>Abbrechen</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
