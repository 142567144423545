import { createSlice } from "@reduxjs/toolkit";
import STATUS from "./status";


const initialState = {
    status: STATUS.IDLE,
    top1 : 0,
    top2 : 0,
}


const topBarSlice = createSlice({
    name: 'topBarSlice',
    initialState,
    reducers: {
        setTop1: (state, action) => {
            state.top1 = action.payload.top1;
        },
        setTop2: (state, action) => {
            state.top2 = action.payload.top2;
        },
    }
})

const { reducer } = topBarSlice;
export const { setTop1, setTop2 } = topBarSlice.actions

export default reducer;