import React from 'react'
import config from '../config/config'
import STATUS from '../store/status'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { useSelector } from 'react-redux'
import ImageRenderer from './ImageRenderer'

const SiteLogo = () => {
    const { fetched, setting, status: settingStatus } = useSelector((state) => state.settingSlice)
    return (
        <>
            {
                settingStatus == STATUS.LOADING
                    ?
                    null
                    :
                    <ImageRenderer
                        wrapperClassName="lazy-logo"
                        url={fetched ? `${config.ASSET_URL}/admin_asset/images/${setting.site_logo}` : '/images/logo1.png'} />
            }
        </>
    )
}

export default SiteLogo