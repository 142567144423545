import '../../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import React from 'react';
import { useState, useEffect } from "react";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import homeService from '../../store/services/home.service';
import config from '../../config/config';
import { EqualHeight } from 'react-equal-height';
// import EqualHeightElement from '../../node_modules/react-equal-height/lib/equal-height-element';
import { useSelector } from 'react-redux';

function Banner() {
    const { setting } = useSelector((state) => state.settingSlice)

    var body = document.body;
    document.getElementById('root').removeAttribute("style");
    body.classList.remove("body_class");
    body.classList.remove("artist-body");

    var tglclass = document.getElementById('tgl');
    if (typeof tglclass !== 'undefined' && tglclass !== null) {
        tglclass.style.display = 'none';
    }
    var cross = document.getElementById('cross');
    if (typeof cross !== 'undefined' && cross !== null) {
        cross.style.display = 'block';

    }

    var elem = document.getElementById('player-component');
    if (typeof elem !== 'undefined' && elem !== null) {
        elem.style.display = 'none';
    }

    const settings = {
        autoplay: true,
        autoplaySpeed: setting && setting.desktop_slider_time ? parseInt(setting.mobile_slider_time) : 7000,
        pauseOnHover: true,
        dots: false,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '15px',

    };

    const settings2 = {
        autoplay: true,
        autoplaySpeed: setting && setting.desktop_slider_time ? parseInt(setting.desktop_slider_time) : 7000,
        pauseOnHover: true,
        dots: false,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false

    };

    const [loading, setLoading] = useState(false);
    const [mobileBanners, setMobileBanners] = useState(null);
    const [desktopBanner, setDesktopBanner] = useState(null);

    const fetchBanner = async () => {
        setLoading(true);
        setMobileBanners(null);
        setDesktopBanner(null);
        try {
            let res = await homeService.getBanner();
            setMobileBanners(res.data.data.mobile);
            setDesktopBanner(res.data.data.desktop);
            setLoading(false);
        } catch (error) {
            // console.log(error);
        }
    }

    useEffect(() => {
        fetchBanner();
    }, [])

    return (

        <div className='home-full-banner'>
            <SkeletonTheme baseColor="#202020" highlightColor="#444">

                <div className="banner d-md-none d-block" >

                    <div className="container">
                        <Slider {...settings}>
                            {
                                loading ?
                                    <Skeleton className='mobile-desk-slider' />
                                    // null
                                    :
                                    mobileBanners && mobileBanners.map((banner) => {
                                        return (
                                            // <EqualHeight updateOnChange={loading}>
                                            <div className="slider-blk" key={banner.id}>
                                                <a href={banner.link}>
                                                    {/* <EqualHeightElement name="slide"> */}
                                                    <div className="slider-bg" style={{ backgroundImage: `url(${config.BANNER_URL}/${banner.background_image})` }}>
                                                        <div className='row' >
                                                            <div className='col-7 d-flex flex-wrap align-items-end lft-col'>
                                                                <div>
                                                                    { (banner.title) ? <h3>{banner.title}</h3> : null }
                                                                    { banner.button_text ? <button className='btn btn-primary'>{banner.button_text}</button> : null}
                                                                </div></div>

                                                            { banner.image ?
                                                                <div className='col-5 rgt-col'>
                                                                    <img src={`${config.BANNER_URL}/${banner.image}`} className="img-fluid" alt='' />
                                                                </div>
                                                                : null
                                                            }
                                                        </div>
                                                    </div>
                                                    {/* </EqualHeightElement> */}
                                                </a>

                                            </div>
                                            // </EqualHeight>
                                        )
                                    })
                            }

                        </Slider>

                    </div>
                </div>


                <div className="banner-desktop d-none d-md-block">
                    <div className="container">

                        {loading ? (
                            <Skeleton className='desk-slider' />
                            // null
                        ) :
                            <Slider {...settings2}>
                                {
                                    desktopBanner && desktopBanner.map((banner) => {
                                        return (
                                            <div className="in-bann" key={banner.id}>
                                                <a href={banner.link}><img src={`${config.BANNER_URL}/${banner.image}`} alt="" /></a>
                                                { (banner.button_text) ? <a href={banner.link} className="btn btn-blue">{banner.button_text}</a> : null }
                                                { (banner.title) ? <a href={banner.link}><h2>{banner.title}</h2></a> : null }
                                            </div>
                                        )
                                    })
                                }
                            </Slider>

                        }

                    </div>
                </div>
            </SkeletonTheme>
        </div>
    );
}

export default Banner;
