import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import { Link, Navigate, useLocation} from 'react-router-dom';
import { useDocumentTitle } from '../../hooks/setDocumentTitle';
import SiteLogo from '../SiteLogo';

export default function License() {
    useDocumentTitle('License');
    const location = useLocation();
    const {setting,fetched} = useSelector((state) => state.settingSlice)

    if (!location || !location.state || !location.state.email) {
        return <Navigate to="/join" replace={true} />
    }

    return (
        <div className='license-page'>
            <div className="account-sec privacy-policy-wrp">
                <div className="container">

                    <div className="em-logo position-relative mb-4 ">
                        <Link to="/">
                            <SiteLogo />
                        </Link>
                        <div className="back-sec">
                            <Link to="/join"><img src="images/dn_ar.svg" alt="" /></Link>
                        </div>
                    </div>

                    <div className="reg-container reg-1">
                        <h4 className="join-steps">SCHRITT 2 VON 5</h4>
                        <h3 className="license-title">Nutzungsvertrag</h3>
                        <SkeletonTheme baseColor="#202020" highlightColor="#444">
                        <div className="l-container">
                            <div className="license-text">
                                {
                                    fetched ? setting.license_agreement : <Skeleton count={100}/>
                                }
                            </div>
                            <div className="f-btn-section f-btn-section2">
                                <div className='f-links2'>Indem du auf "Zustimmen und weiter" klickst, stimmst du unserem Nutzungsvertrag und der Widerrufsbelehrung zu.</div>

                                <Link to={'/choosepassword'} state={{...location.state}} className='join-btn jb-2'>BESTÄTIGEN & WEITER</Link>
                            </div>
                            </div>
                        </SkeletonTheme>
                    </div>


                </div>


            </div>
        </div>
    )
}
