import axios, { unAuthorizedAxios } from "../../axios";



class homeService {

    collection(filter) {
        return axios.get(`/collections?filter=${filter}`);
    }
  
    collectionById(id, filter, page = 1) {
        return axios.get(`/collection/${id}?filter=${filter}&page=${page}`);
    }
    
    filters() {
        return unAuthorizedAxios.get('/filters');
    }

    tracks(id) {
        return axios.get(`/media/${id}`);
    }

    updateTrack(data) {
        return axios.post('/update-track',data);
    }

    series(slug) {
        return axios.get(`/series/${slug}`);
    }

    getSeries(page=1) {
        return axios.get(`/series?page=${page}`);
    }

    channels(page) {
        return axios.get(`/channels?page=${page}`);
    }

    channelById(id) {
        return axios.get(`/channel/${id}`);
    }

    addToWatchlist(data) {
        return axios.post('add-to-watchlist',data);
    }

    removeFromWatchlist(data) {
        return axios.post('remove-from-watchlist',data);
    }

    watchlists() {
        return axios.get('watchlists');
    }
    watchlist(data, page = 1) {
        return axios.post(`watchlist?page=${page}`,data)
    }
    
    search(data) {
        return axios.post('search',data)
    }
    
    searchDetail(data, page = 1) {
        return axios.post(`search-detail?page=${page}`,data)
    }

    getBanner() {
        return axios.get('banner')
    }

    getProduct(data) {
        return axios.post('get-product', data)
    }

    getRecommendations(data) {
        return axios.post('get-recommendations', data)
    }

}

export default new homeService();