import React, { Fragment, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useRef } from "react";
import { Bars } from "react-loader-spinner";
import notification from "./config/notification";
import subscriptionService from "./store/services/subscription.service";
import Errors from "./Components/Errors";
import { subscriptionThunk } from "./store/subscriptionSlice";
import { useDispatch } from "react-redux";
import { currencyFormatDE } from "./helpers/helpers";
import SiteLogo from "./Components/SiteLogo";
export default function SubscriptionPayment(props) {
    const [subscriptions, setSubscriptions] = useState(null);
    const [subscriptionType, setSubscriptionType] = useState(props.selectedSubscription);
    const dispatch = useDispatch();
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const getAllSubscriptions = async () => {
        try {
            let res = await subscriptionService.allSubscriptions();
            setSubscriptions(res.data.data);
        } catch (error) {

        }
    }
    useEffect(() => {
        getAllSubscriptions();
    }, [])

    useEffect(() => {
        if (document.body.classList.contains('body_class')) {
            document.body.classList.remove('body_class')
        }
        document.body.classList.add('noBefore');
    }, [])

    const checkSubscriptionType = () => {
        if (subscriptionType == '') {
            notification('error', 'Bitte wähle ein Abonnement.');
            return false;
        }
        if (![1, 2, 3, 4].includes(subscriptionType)) {
            notification('error', 'Wähle ein gültiges Abonnement.');
            return false;
        }
        return true;
    }

    const changeAct = type => (e) => {
        setSubscriptionType(type);
    }
    const [visibility, setVisibility] = useState(true);

    const [cardNo, setcardNo] = useState('');
    const [carderr, setcardErr] = useState(null);
    const [carderrTxt, setcardErrTxt] = useState(null);
    const checkCard = event => {
        if (cardNo.length === 19) {
            setcardErr("success1");
            setcardErrTxt("Kartennummer verifiziert");
            return true;
        }
        else {
            setcardErr("error");
            setcardErrTxt("Geben Sie eine gültige Kartennummer ein");
        }
        return false;
    };
    const [MM, setMM] = useState('');
    const [MMerr, setMMerr] = useState('');
    const [MMerrTxt, setMMerrTxt] = useState('');
    const checkMM = event => {
        if (MM <= 12 && MM >= 1 && MM.length === 2) {
            setMMerr("success1");
            setMMerrTxt("");
            document.getElementById("YY").focus();
            return true;
        }
        else {
            setMMerr("error error4");
            setMMerrTxt("Bitte wählen Sie einen gültigen Monat");
        }
        return false;
    };
    const [YY, setYY] = useState('');
    const [YYerr, setYYerr] = useState('');
    const [YYerrTxt, setYYerrTxt] = useState('');
    const checkYY = event => {
        if (YY) {
            setYYerr("success1");
            setYYerrTxt("");
            return true;
        }
        else {
            setYYerr("error3");
            setYYerrTxt("Bitte wählen Sie ein gültiges Jahr");
        }
        return false;
    };
    const [Cvv, setCvv] = useState('');
    const [Cvverr, setCvverr] = useState('');
    const [CvverrTxt, setCvverrTxt] = useState('');

    var nameregex = /^[a-zA-Z ]+$/
    const [Cnamee, setCnamee] = useState('');
    const [Cnameeerr, setCnameeerr] = useState('');
    const [CnameeerrTxt, setCnameeerrTxt] = useState('');
    const checkCnamee = event => {
        if (Cnamee.match(nameregex)) {
            setCnameeerr("success1");
            setCnameeerrTxt("");
            return true;
        }
        else {
            setCnameeerr("error");
            setCnameeerrTxt("Bitte geben Sie einen gültigen Namen ein");
        }
        return false;
    };
    const payMent = async (e) => {
        e.preventDefault();
        let nameCheck = checkCnamee();
        let cardCheck = checkCard();
        let monthCheck = checkMM();
        let yearCheck = checkYY();

        let checkSubscription = checkSubscriptionType();
        if (!nameCheck || !cardCheck || !monthCheck || !yearCheck || !checkSubscription) {
            return false;
        }
        setLoading(true);
        try {
            let res = await subscriptionService.changeSubscription({
                'card_number': cardNo,
                'expiry_month': MM,
                'expiry_year': YY,
                'cvv': Cvv,
                'subscription_id': subscriptionType,
            })
            if (res.data.success) {
                setLoading(false);
                notification('message', 'Abonnement wurde geändert', 500);
                dispatch(subscriptionThunk())
                return navigate('/');
            } else {
                setLoading(false);
                notification('error', res.data.message)
            }

        } catch (er) {
            setLoading(false);
            if (er.response && er.response.status === 422) {
                setErrors(er.response.data.data);
            } else {
                notification('error', 'Das hat nicht geklappt. Bitte versuche es später noch einmal.')
            }
        }
    }
    const subscriptionDiv = subscriptions && subscriptions.map((subscription) => {
        return (<Fragment key={subscription.id}>
            {subscription.id === 4 ? <div className="device-txt last"><span>3 GERÄTE GLEICHZEITIG</span></div> : subscription.id === 1 ? <div className="device-txt first"><span>1 GERÄT GLEICHZEITIG</span></div> : ''}
            <div key={subscription.id} className={subscription.id == 2 || subscription.id == 4 ? 'subs-con-full' : 'subs-con'} sub-id={subscription.id}>
                <label htmlFor={subscription.name} onClick={changeAct(subscription.id)} className={subscriptionType == subscription.id ? 'active' : ''}>
                    <span className="t-txt">
                        <span className="tt1">{subscription.name}</span>
                        <span className="tt2">{currencyFormatDE(subscription.price)} inkl. MwSt.</span>
                        <span className="tt3" dangerouslySetInnerHTML={{ __html: subscription.content }}></span>
                    </span>
                    {
                        subscription.discount_text != null ? <span className="discountt">{subscription.discount_text}</span> : null
                    }
                    <span className="t-circle">
                        <span className="in-circle"></span>
                    </span>
                </label>
                <input type="radio" id={subscription.name} name="subscription_type" className="hidden-radio-btn" />
            </div>
        </Fragment>)
    })
    return (
        <div className='payment-page'>
            {
                loading ? <Bars
                    height="80"
                    width="80"
                    color="#ffffff"
                    ariaLabel="bars-loading"
                    wrapperStyle={{}}
                    wrapperClass="loading-spinner-animation"
                    visible={true}
                /> : ''
            }
            <div className="account-sec privacy-policy-wrp">
                <div className="container">
                    <div className="em-logo position-relative mb-4 ">
                        <Link to="/"><SiteLogo /></Link>
                    </div>
                    <div className="reg-container reg-1">
                        <div className="p-para1">
                            <span> Du kannst jederzeit mit Wirkung zum Ende des Abrechnungszeitraums kündigen. Andernfalls wird das Abo je nach Laufzeit um 1 Monat oder 1 Jahr verlängert.</span>
                        </div>
                        <div className="check-container t-btn">
                            {subscriptionDiv}
                        </div>

                        <div className="line1" style={{ display: visibility ? 'none' : '' }}></div>
                        {/* --------------------- */}
                        <div className="cards-section">
                            <div id="c1">
                                <img className='c-img' src="images/CreditCard.png" alt="" />
                                <div className="c-txt">Kreditkarte</div>
                            </div>

                            <div className="line3" ></div>
                        </div>
                        <div className="cards-details">
                            <Errors errors={errors} />

                            <form action="" onSubmit={payMent}>

                                <div>
                                    <div className="d1">
                                        <label>KARTENINHABER</label>
                                        <input type="text" name="" id="" onChange={event => setCnamee(event.target.value)} value={Cnamee} />
                                        <span className={Cnameeerr}>{CnameeerrTxt}</span>
                                    </div>
                                    <div className="d1 num">
                                        <label>KARTENNUMMER</label>
                                        <input onChange={event => setcardNo(event.target.value.replace(/[^0-9]/gi, '').replace(/(.{4})/g, '$1 ').trim())} value={cardNo} type="text" name="card" id="card" maxlength="19" />
                                        <div className="small-cards">
                                            <img src="images/visa1.png" alt="" />
                                            <img src="images/mastercard.png" alt="" />
                                            <img src="images/american-express.png" alt="" />
                                        </div>
                                        <span className={carderr}>{carderrTxt}</span>
                                    </div>
                                    <div className="d2">
                                        <div className="d1">
                                            <label>GÜLTIG BIS</label>
                                            <div className="mm-jj">
                                                <input type="text" value={MM} onChange={event => setMM(event.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1'))} placeholder="MM" className="mm" maxlength="2" />
                                                <span>/ &nbsp; &nbsp;</span>
                                                <input id="YY" type="text" value={YY} onChange={event => setYY(event.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1'))} placeholder="JJ" className="jj" maxlength="2" />
                                            </div>
                                            <span className={MMerr}>{MMerrTxt}</span>
                                            <span className={YYerr}>{YYerrTxt}</span>
                                        </div>
                                        <div className="d1">
                                            <label>SICHERHEITSCODE</label>
                                            <input className="cvv" value={Cvv} onChange={event => setCvv(event.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1'))} type="text" placeholder="CVV" maxlength="4" />
                                            <span className={Cvverr}>{CvverrTxt}</span>
                                        </div>
                                    </div>
                                    <button id="credit-btn" type="submit" className="join-btn f-btn">BESTÄTIGEN & WEITER</button>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
