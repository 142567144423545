import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link, useLocation } from "react-router-dom";
import 'react-loading-skeleton/dist/skeleton.css'
import { useState } from 'react';
import { useEffect } from 'react';
import homeService from './store/services/home.service';
import LoadingComponent from './Components/LoadingComponent';
import config from './config/config';
import SliderCard from './Components/Home/SliderCard';
import { SkeletonTheme } from 'react-loading-skeleton';
import { useDocumentTitle } from './hooks/setDocumentTitle';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import usePreviousPage from './hooks/usePreviousPage';
import ImageRenderer from './Components/ImageRenderer';
function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style }}
            onClick={onClick}
        > <img src={'/images/caret1.png'} alt="" /> </div>
    );
}
function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style }}
            onClick={onClick}
        ><img src={'/images/caret1.png'} alt="" /></div>
    );
}
function Favourites() {
    useDocumentTitle('Favoriten');
    usePreviousPage('Favoriten');
    const [series, setSeries] = useState([]);
    const [musics, setMusics] = useState([]);
    const [films, setFilms] = useState([]);
    const [channels, setChannels] = useState([]);
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    var body = document.body;
    body.classList.remove("body_class");
    body.classList.add("artist-body");
    const recent = {
        variableWidth: true,
        dots: false,
        arrows: true,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 5,
        swipeToSlide: true,
        cssEase: 'linear',
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1200,
                settings: "unslick"
            }
        ],
    };

    const fetchWatchlist = async () => {
        setLoading(true);
        try {
            let res = await homeService.watchlists();
            setChannels(res.data.data.channels)
            setFilms(res.data.data.films)
            setMusics(res.data.data.musics)
            setSeries(res.data.data.series)
            setLoading(false);
        } catch (error) {
            if (error.response && error.response.status == '401') {
                window.location.reload(false);
            }
            console.log(error);
        }
    }
    useEffect(() => {
        fetchWatchlist();
        document.getElementById('root').removeAttribute("style");
    }, [])


    return (
        // <SkeletonTheme baseColor="#202020" highlightColor="#444">
        <div className="favourites-sec appMain">
            <div className="container">
                <h2>Favoriten</h2>
                {
                    loading ?
                        null
                        // <LoadingComponent />
                        :

                        <div className="fav-btm">
                            {
                                channels && channels.length > 0 ?
                                    <div className='music-slider rounded mtc-4' >
                                        <div className="music-top">
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="top-title position-relative"><h3><b className='dont-break'>Kanäle</b></h3>
                                                        <img className="top-img" src={'/images/kanale-3.png'} alt="" />
                                                    </div>
                                                </div>
                                                <div className="col-6 text-end rgt">
                                                    <h3><Link to={'/favourites/channels'}>Alle ansehen </Link></h3>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="music-btm-slider  neu pb-2">


                                            <Slider {...recent}>
                                                {channels.map((channel) => {
                                                    return (

                                                        <div className="music-slider-blk " key={channel.id}>
                                                            <Link to={`/channel/${channel.id}`}>
                                                                <div className="blk-wrapper">
                                                                    <div className="image">
                                                                        <ImageRenderer url={`${config.CHANNEL_URL}/${channel.thumbnail_image}`} />
                                                                    </div>
                                                                    <div className="slider-btm-wp">
                                                                        <div className="wp-lft">
                                                                            <h4 className="title">{channel.name}</h4>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </div>

                                                    );
                                                })}
                                            </Slider>

                                        </div>
                                    </div> : null
                            }

                            {
                                series && series.length > 0 ?
                                    <div className='music-slider mtc-4' >
                                        <div className="music-top">
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="top-title position-relative"><h3><b className='dont-break'>Series</b></h3>
                                                        {/* <img className="top-img" src={'/images/kanale-3.png'} alt="" /> */}
                                                    </div>
                                                </div>
                                                <div className="col-6 text-end rgt">
                                                    <h3><Link to={'/favourites/series'}>Alle ansehen </Link></h3>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="music-btm-slider  neu pb-2">

                                            <Slider {...recent}>
                                                {series.map((series) => {
                                                    return (

                                                        <div className="music-slider-blk " key={series.id}>
                                                            <Link to={`/series/${series.slug}`}>
                                                                <div className="blk-wrapper">
                                                                    <div className="image">
                                                                        <ImageRenderer url={`${config.SERIES_URL}/${series.image}`} />
                                                                    </div>
                                                                    <div className="slider-btm-wp">
                                                                        <div className="wp-lft">
                                                                            <h4 className="title">{series.title}</h4>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </div>

                                                    );
                                                })}
                                            </Slider>

                                        </div>
                                    </div> : null
                            }

                            {
                                musics && musics.length > 0
                                    ?
                                    <div className='music-slider filme mtc-4' >
                                        <div className="music-top">
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="top-title position-relative"><h3><b className='dont-break'>Musik & Hörbücher</b></h3>
                                                        <img className="top-img" src={'/images/musik-3.png'} alt="" />
                                                    </div>
                                                </div>
                                                <div className="col-6 text-end rgt">
                                                    <h3><Link to="/favourites/music">Alle ansehen</Link></h3>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="music-btm-slider ">
                                            <Slider {...recent}>
                                                {
                                                    musics && musics.map((media) => {
                                                        return (
                                                            <SliderCard hideIcon={true} media={media} key={media.id} />
                                                        )
                                                    })
                                                }
                                            </Slider>
                                        </div>
                                    </div> : null
                            }

                            {
                                films && films.length > 0
                                    ?
                                    <div className='music-slider filme mtc-4' >
                                        <div className="music-top">
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="top-title position-relative"><h3><b className='dont-break'>Filme</b></h3>
                                                        <img className="top-img" src={'/images/Kamera-3.png'} alt="" />
                                                    </div>
                                                </div>
                                                <div className="col-6 text-end rgt">
                                                    <h3><Link to="/favourites/film">Alle ansehen</Link></h3>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="music-btm-slider ">
                                            <Slider {...recent}>
                                                {
                                                    films && films.map((media) => {
                                                        return (
                                                            <SliderCard hideIcon={true} media={media} key={media.id} />
                                                        )
                                                    })
                                                }
                                            </Slider>
                                        </div>
                                    </div> : null
                            }

                        </div>
                }

            </div>
        </div>
        // </SkeletonTheme>
    );
}
export default Favourites;
