import React from 'react'
import { useSelector } from 'react-redux';
import videojs from 'video.js';
import VideoJS from './VideoJS';

function SampleVideo({sampleFile}) {
    const playerRef = React.useRef(null);
    // const { media } = useSelector((state) => state.mediaSlice)
    const videoJsOptions = {
        autoplay: false,
        controls: true,
        responsive: true,
        fluid: true,
        sources: [{
            src: sampleFile,
        }]
    };

    const handlePlayerReady = (player) => {
        playerRef.current = player;

        // You can handle player events here, for example:
        player.on('waiting', () => {
            // videojs.log('player is waiting');
        });

        player.on('dispose', () => {
            // videojs.log('player will dispose');
        });
    };
  return (
      <div>
          <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
    </div>
  )
}

export default SampleVideo