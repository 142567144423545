import React, { useState } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, useParams, Navigate, useLocation, useNavigate } from 'react-router-dom';
import homeService from './store/services/home.service';
import config from './config/config';
import { useEffect } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import NotFound from './NotFound';
import { useSelector } from 'react-redux';
import notification from './config/notification';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useDocumentTitle } from './hooks/setDocumentTitle';
import MediaCard from './Components/MediaCard';
import usePreviousPage from './hooks/usePreviousPage';
import ImageRenderer from './Components/ImageRenderer';

export default function Series() {
    const [series, setSeries] = useState({})
    const [loading, setLoading] = useState(false)
    const { seriesSlug } = useParams();
    const [notFound, setNotFound] = useState(false);
    const { isAuthenticated } = useSelector((state) => state.authSlice);
    const [like, setLike] = useState(false);
    const navigate = useNavigate();
    useDocumentTitle('Series');
    usePreviousPage(series && series.title);
    var body = document.body;
    body.classList.remove("body_class");
    body.classList.add("artist-body");


    let location = useLocation();
    const likeFun = async () => {
        if (!isAuthenticated) {
            notification('info', 'Bitte logge dich zuerst ein.');
            navigate('/signin', { state: { from: location } })
            return;
        }
        let data = {
            'type': 2,
            'main_id': series.id
        }
        if (!like) {
            try {
                await homeService.addToWatchlist(data);
                setLike(true);
                return;
            } catch (error) {
                notification('error', 'Das hat nicht geklappt. Bitte versuche es später noch einmal.');
                return;
            }
        } else {
            try {
                await homeService.removeFromWatchlist(data);
                setLike(false);
                return;
            } catch (error) {
                notification('error', 'Das hat nicht geklappt. Bitte versuche es später noch einmal.');
                return;
            }
        }
    }

    const fetchSeries = async () => {
        setLoading(true);
        try {
            let res = await homeService.series(seriesSlug)
            if (res.data.success == false) {
                setNotFound(true);
                return;
            }
            setSeries(res.data.data);
            setLike(res.data.data.watchlist)
            // console.log(res.data.data);
            setLoading(false);
        } catch (error) {
            // console.log(error);
        }
    }

    useEffect(() => {
        fetchSeries();
    }, [])

    if (notFound) {
        return <NotFound />;
    }
    if (!series || loading) {
        return (
            <div></div>
            // <SkeletonTheme baseColor="#202020" highlightColor="#444">
            //     <div className='series-page'>
            //         <div className="container">
            //             <div className="s-banner">
            //                 <Skeleton width={1220} height={468}/>
            //                 <div className="banner-text">
            //                     <div className="a-con">
            //                         <div className="a-tetxt">
            //                             <h3><Skeleton width={100} /></h3>
            //                         </div>
            //                         <button className='heart' onClick={likeFun}>
            //                             <Skeleton width={30} height={30} circle={true} />
            //                         </button>
            //                     </div>
            //                 </div>
            //             </div>
            //             <div className="cat-top">
            //                 <div className="row">

            //                     <div className="col-20">
            //                         <div className="v-card">
            //                             <Skeleton width={225} height={250} />
            //                             <div className="slider-btm-wp">
            //                                 <div className="wp-lft">
            //                                     <h4 className="title"><Skeleton /></h4>
            //                                     <h4 className="artist"><Skeleton /></h4>
            //                                 </div>
            //                             </div>
            //                         </div>
            //                     </div>
            //                     <div className="col-20">
            //                         <div className="v-card">
            //                             <Skeleton width={225} height={250} />
            //                             <div className="slider-btm-wp">
            //                                 <div className="wp-lft">
            //                                     <h4 className="title"><Skeleton /></h4>
            //                                     <h4 className="artist"><Skeleton /></h4>
            //                                 </div>
            //                             </div>
            //                         </div>
            //                     </div>
            //                     <div className="col-20">
            //                         <div className="v-card">
            //                             <Skeleton width={225} height={250} />
            //                             <div className="slider-btm-wp">
            //                                 <div className="wp-lft">
            //                                     <h4 className="title"><Skeleton /></h4>
            //                                     <h4 className="artist"><Skeleton /></h4>
            //                                 </div>
            //                             </div>
            //                         </div>
            //                     </div>
            //                     <div className="col-20">
            //                         <div className="v-card">
            //                             <Skeleton width={225} height={250} />
            //                             <div className="slider-btm-wp">
            //                                 <div className="wp-lft">
            //                                     <h4 className="title"><Skeleton /></h4>
            //                                     <h4 className="artist"><Skeleton /></h4>
            //                                 </div>
            //                             </div>
            //                         </div>
            //                     </div>
            //                     <div className="col-20">
            //                         <div className="v-card">
            //                             <Skeleton width={225} height={250} />
            //                             <div className="slider-btm-wp">
            //                                 <div className="wp-lft">
            //                                     <h4 className="title"><Skeleton /></h4>
            //                                     <h4 className="artist"><Skeleton /></h4>
            //                                 </div>
            //                             </div>
            //                         </div>
            //                     </div>
            //                 </div>
            //             </div>
            //         </div>
            //     </div>
            // </SkeletonTheme>
        )
    }

    return (
        <div className='series-page appMain'>
            <div className="container">
                <div className="s-banner">
                    <ImageRenderer
                        wrapperClassName="lazy-banner"
                        url={`${config.SERIES_URL}/${series.image}`} />
                    <div className="banner-text banner-text2">
                        <div className="a-con">
                            <div className="a-tetxt">
                                <h3 className='series-title1'>{series.title}</h3>
                                {
                                    series.category && <p className="series-subtitle1">{series.category.name} · SERIE</p>
                                }
                            </div>
                            <button className='heart s-heart' onClick={likeFun}>
                                {
                                    like ?
                                        <img src={'/images/heart-active.png'} alt="" />
                                        :
                                        <img src={'/images/heart.png'} alt="" />
                                }
                            </button>
                        </div>
                    </div>
                </div>
                <div className="cat-top">
                    <div className="row">

                        {
                            series && series.media && series.media.map((media) => {
                                return (
                                    <MediaCard media={media} key={media.id} />
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
