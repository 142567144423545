import React, { Fragment, useState } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import notification from "../../config/notification";
import subscriptionService from "../../store/services/subscription.service";
import { useEffect } from "react";
import { useRef } from "react";
import Errors from "../Errors";
import { encode } from "base-64";
import { Bars } from "react-loader-spinner";
import { subscriptionThunk } from "../../store/subscriptionSlice";
import { resetTrack } from "../../store/trackSlice";
import { authUserThunk } from "../../store/auth/authSlice";
import { useDispatch } from "react-redux";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useDocumentTitle } from "../../hooks/setDocumentTitle";
import { currencyFormatDE } from "../../helpers/helpers";
import SiteLogo from "../SiteLogo";
export default function Payment() {
    useDocumentTitle('Payment');
    const [subscriptions, setSubscriptions] = useState(null);
    const [subscriptionType, setSubscriptionType] = useState(1);
    const [subscriptionPrice, setSubscriptionPrice] = useState(0)
    const location = useLocation();
    if (!location || !location.state || !location.state.token) {
        notification('warning', 'Bitte registriere dich zuerst.');
        return <Navigate to="/register" />
    }
    let token = location.state.token;
    const [err, setErr] = useState(null);
    const [errTxt, setErrTxt] = useState(null);
    const [coupon, setCoupon] = useState(null)
    const [couponApplied, setCouponApplied] = useState(false);
    const [couponId, setCouponId] = useState(0)
    const [couponMessage, setCouponMessage] = useState(null)
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [subLoading, setSubLoading] = useState(false);
    const [confirmPayment, setConfirmPayment] = useState(false);
    const [paymentSuccess, setPaymentSuccess] = useState(false);


    const navigate = useNavigate();
    const dispatch = useDispatch();
    const getAllSubscriptions = async () => {
        setSubLoading(true)
        try {
            let res = await subscriptionService.allSubscriptions();
            setSubscriptions(res.data.data);
        } catch (error) {

        }
        setSubLoading(false)
    }
    useEffect(() => {
        getAllSubscriptions();
    }, [])

    useEffect(() => {
        document.body.classList.add('noBefore');
    }, [])

    const checkSubscriptionType = () => {
        if (subscriptionType == '') {
            notification('error', 'Bitte wähle ein Abonnement.');
            return false;
        }
        if (![1, 2, 3, 4].includes(subscriptionType)) {
            notification('error', 'Wähle ein gültiges Abonnement.');
            return false;
        }
        return true;
    }
    const onSubmitCoupon = async () => {
        let checkSubscription = checkSubscriptionType();
        if (!checkSubscription) {
            return false;
        }

        if (coupon == '') {
            setErr("error2");
            setErrTxt("Bitte Gutscheincode eingeben");
            return false;
        }
        if (couponApplied) {
            notification('error', 'Bitte entferne zuerst den Gutschein.')
        }
        setCouponId(0);
        setCouponMessage(null);
        try {
            let res = await subscriptionService.couponCheck({
                subscription_id: subscriptionType,
                code: coupon
            });
            if (res.data.success) {
                setErr("success2");
                setErrTxt("Code ist gültig und wird im nächsten Schritt verrechnet.");
                setCouponApplied(true);
                setCouponId(res.data.data.id);
                setCouponMessage(res.data.message);
                notification('message', 'Gutschein erfolgreich eingelöst.')
            } else {
                setErr("error2");
                setErrTxt(res.data.message);
            }
        } catch (er) {
            notification('error', 'Das hat nicht geklappt. Bitte versuche es später noch einmal.')
        }
    }
    const removeCoupon = () => {
        setErr(null);
        setErrTxt(null);
        setCouponApplied(false);
        setCouponId(0);
        setCouponMessage(null);
        setCoupon('');
        notification('info', 'Gutschein wurde entfernt.')
    }
    const changeAct = type => (e) => {
        setSubscriptionType(type);
        setCouponId(0);
        setErr(null);
        setErrTxt(null);
        setCouponApplied(false);
        setCouponId(0);
        setCoupon('');
    }
    const [visibility, setVisibility] = useState(true);
    function showCoupon() {
        setVisibility(false);
    }

    const [cardNo, setcardNo] = useState('');
    const [carderr, setcardErr] = useState(null);
    const [carderrTxt, setcardErrTxt] = useState(null);
    const checkCard = event => {
        if (cardNo.length === 19) {
            setcardErr("success1");
            setcardErrTxt("Kartennummer verifiziert");
            return true;
        }
        else {
            setcardErr("error");
            setcardErrTxt("Geben Sie eine gültige Kartennummer ein");
        }
        return false;
    };
    const [MM, setMM] = useState('');
    const [MMerr, setMMerr] = useState('');
    const [MMerrTxt, setMMerrTxt] = useState('');
    const checkMM = event => {
        if (MM <= 12 && MM >= 1 && MM.length === 2) {
            setMMerr("success1");
            setMMerrTxt("");
            document.getElementById("YY").focus();
            return true;
        }
        else {
            setMMerr("error error4");
            setMMerrTxt("Bitte wählen Sie einen gültigen Monat");
        }
        return false;
    };
    const [YY, setYY] = useState('');
    const [YYerr, setYYerr] = useState('');
    const [YYerrTxt, setYYerrTxt] = useState('');
    const checkYY = event => {
        if (YY) {
            setYYerr("success1");
            setYYerrTxt("");
            return true;
        }
        else {
            setYYerr("error3");
            setYYerrTxt("Bitte wählen Sie ein gültiges Jahr");
        }
        return false;
    };
    const [Cvv, setCvv] = useState('');
    const [Cvverr, setCvverr] = useState('');
    const [CvverrTxt, setCvverrTxt] = useState('');

    var nameregex = /^[a-zA-Z ]+$/
    const [Cnamee, setCnamee] = useState('');
    const [Cnameeerr, setCnameeerr] = useState('');
    const [CnameeerrTxt, setCnameeerrTxt] = useState('');
    const checkCnamee = event => {
        if (Cnamee.match(nameregex)) {
            setCnameeerr("success1");
            setCnameeerrTxt("");
            return true;
        }
        else {
            setCnameeerr("error");
            setCnameeerrTxt("Bitte geben Sie einen gültigen Namen ein");
        }
        return false;
    };
    const paymentDetails = event => {
        setConfirmPayment(false);
    }
    const confirmation = async (e) => {
        e.preventDefault();
        let nameCheck = checkCnamee();
        let cardCheck = checkCard();
        let monthCheck = checkMM();
        let yearCheck = checkYY();
        let checkSubscription = checkSubscriptionType();
        if (!nameCheck || !cardCheck || !monthCheck || !yearCheck || !checkSubscription) {
            return false;
        }
        let selSub = subscriptions.filter((sub) => { return sub.id == subscriptionType });
        setSubscriptionPrice(selSub[0].price)

        setConfirmPayment(true)
    };
    const payMent = async (e) => {
        e.preventDefault();
        // let nameCheck = checkCnamee();
        // let cardCheck = checkCard();
        // let monthCheck = checkMM();
        // let yearCheck = checkYY();

        // let checkSubscription = checkSubscriptionType();
        // if (!nameCheck || !cardCheck || !monthCheck || !yearCheck  || !checkSubscription) {
        //     return false;
        // }
        // setConfirmPayment(true)
        setLoading(true);
        try {
            let res = await subscriptionService.payment({
                token,
                'card_number': cardNo,
                'expiry_month': MM,
                'expiry_year': YY,
                'cvv': Cvv,
                'subscription_id': subscriptionType,
                'coupon_id': couponId
            })

            if (res.data.success) {
                setLoading(false);
                const token = encode(res.data.data.token);
                notification('message', 'Zahlung war erfolgreich.', 500);
                localStorage.setItem('_token', token);
                dispatch(authUserThunk())
                dispatch(subscriptionThunk())
                dispatch(resetTrack());
                setPaymentSuccess(true);


                // return navigate('/');
            } else {
                setLoading(false);
                setConfirmPayment(false)
                notification('error', res.data.message)
            }

        } catch (er) {
            setConfirmPayment(false)
            setLoading(false);
            if (er.response && er.response.status === 422) {
                setErrors(er.response.data.data);
            }
            notification('error', 'Das hat nicht geklappt. Bitte versuche es später noch einmal.')
        }
    }
    const subscriptionDiv = subscriptions && subscriptions.map((subscription) => {
        return <Fragment key={subscription.id}>
            {subscription.id === 4 ? <div className="device-txt last"><span>3 GERÄTE GLEICHZEITIG</span></div> : subscription.id === 1 ? <div className="device-txt first"><span>1 GERÄT GLEICHZEITIG</span></div> : ''}
            <div key={subscription.id} className={subscription.id == 2 || subscription.id == 4 ? 'subs-con-full' : 'subs-con'} sub-id={subscription.id}>
                <label htmlFor={subscription.name} onClick={changeAct(subscription.id)} className={subscriptionType == subscription.id ? 'active' : ''}>
                    <span className="t-txt">
                        <span className="tt1">{subscription.name}</span>
                        <span className="tt2">{currencyFormatDE(subscription.price)} inkl. MwSt.</span>
                        <span className="tt3" dangerouslySetInnerHTML={{ __html: subscription.content }}></span>
                    </span>
                    {
                        subscription.discount_text != null ? <span className="discountt">{subscription.discount_text}</span> : null
                    }
                    <span className="t-circle">
                        <span className="in-circle"></span>
                    </span>
                </label>
                <input type="radio" id={subscription.name} name="subscription_type" className="hidden-radio-btn" />
            </div>
        </Fragment>
    })
    if (paymentSuccess) {
        return (<div className="payment-page">
            <div className="account-sec privacy-policy-wrp">
                <div className="container">
                    <div className="em-logo position-relative mb-4 ">
                        <Link to="/">
                            <SiteLogo />
                        </Link>
                    </div>
                    <div className="reg-container reg-1 confirm-sec2">
                        <h4 className="wel-heading">Herzlich willkommen!</h4>

                        <div className="c-para1">
                            <div className="wel-txt">Vielen Dank für deine Registrierung. Viel Spaß mit <br /> streamfair!
                            </div>
                        </div>
                        <Link to="/">
                            <button className="join-btn f-btn c-payment-btn">JETZT BEGINNEN</button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>)
    }
    if (confirmPayment) {
        return (<>
            {
                loading ? <Bars
                    height="80"
                    width="80"
                    color="#ffffff"
                    ariaLabel="bars-loading"
                    wrapperStyle={{}}
                    wrapperClass="loading-spinner-animation"
                    visible={true}
                /> : ''
            }
            <div className="payment-page">
                <div className="account-sec privacy-policy-wrp">
                    <div className="container">
                        <div className="em-logo position-relative mb-4 ">
                            <Link to="/">
                                <SiteLogo />
                            </Link>
                            <div className="back-sec">
                                <button onClick={paymentDetails}><img src="images/dn_ar.svg" /></button>
                            </div>
                        </div>
                        <div className="reg-container reg-1 confirm-sec2">
                            <h4 className="join-steps">SCHRITT 5 VON 5</h4>
                            <h3 className="license-title p-title">Überprüfung deines Abos</h3>

                            <div className="c-para1">
                                <div className="c-para-heading">Monatsabo für streamfair</div>
                                <div className="c-para-txt">14 Tage kostenlos streamen. Nach Ablauf des Testzeitraums (plus evtl. Zeitguthaben durch Gutscheincodes, falls unten angegeben) werden dir sofort {currencyFormatDE(subscriptionPrice)}/{subscriptionType == '1' ? 'Monat' : subscriptionType == '3' ? '6 Monate' : 'Jahr'} abgebucht, sofern du nicht im Testzeitraum kündigst.
                                    <br /><br />
                                    Du kannst jederzeit mit Wirkung zum Ende des Abrechnungszeitraums kündigen.
                                </div>
                                <hr className="c-hr" />
                            </div>

                            {
                                couponId > 0 ?
                                    <div className="c-coupon-applied">
                                        <div className="c-voucher-code"><span>Gutscheincode</span> {coupon}</div>
                                        <div className="c-voucher-text">
                                            {couponMessage}
                                        </div>
                                    </div> : null

                            }


                            <div className="c-card-sec">
                                <img src="images/CreditCard.png" />
                                <div className="c-card-name">Kreditkarte</div>
                            </div>
                            <div className="c-back">
                                Verwenden Sie die Zurück-Schaltfläche, um Ihre Zahlungsinformationen zu bearbeiten.
                            </div>

                            <div className="c-agree">Durch Klicken auf den Button "Jetzt zahlungspflichtig bestellen" tätigst du eine rechtskräftige Bestellung.</div>

                            <button onClick={payMent} className="join-btn f-btn c-payment-btn">JETZT ZAHLUNGSPFLICHTIG BESTELLEN</button>

                        </div>
                    </div>
                </div>
            </div>
        </>
        );
    }



    return (
        <SkeletonTheme baseColor="#202020" highlightColor="#444">
            <div className='payment-page'>
                {
                    loading ? <Bars
                        height="80"
                        width="80"
                        color="#ffffff"
                        ariaLabel="bars-loading"
                        wrapperStyle={{}}
                        wrapperClass="loading-spinner-animation"
                        visible={true}
                    /> : ''
                }
                <div className="account-sec privacy-policy-wrp">
                    <div className="container">
                        <div className="em-logo position-relative mb-4 ">
                            <Link to="/">
                                <SiteLogo />
                            </Link>
                        </div>
                        <div className="reg-container reg-1">
                            <h4 className="join-steps">SCHRITT 4 VON 5</h4>
                            <h3 className="license-title p-title">Streame 14 Tage kostenlos</h3>
                            <div className="p-para1">
                                Du kannst unser Angebot in Ruhe für 14 Tage testen, völlig kostenlos. Kündigst du innerhalb dieses Zeitraums, wird dir nichts berechnet. Möchtest du streamfair danach weiter nutzen, musst du nichts weiter tun. Der Betrag für deinen gewählten Abrechnungszeitraum wird nach dem Testzeitraum automatisch abgebucht.
                                <span> Du kannst jederzeit mit Wirkung zum Ende des Abrechnungszeitraums kündigen. Andernfalls wird das Abo je nach Laufzeit um 1 Monat oder 1 Jahr verlängert.</span>
                            </div>
                            <div className="check-container t-btn">
                                {subLoading ? <Skeleton /> :
                                    subscriptionDiv
                                    // <Skeleton />
                                }
                            </div>
                            <div className="coupon-section">

                                <div className="s-coupon-btn" style={{ display: visibility ? '' : 'none' }} onClick={showCoupon}>Gutschein vorhanden? Klicke hier!</div>

                                <div className="coupon" style={{ display: visibility ? 'none' : '' }}>
                                    <div className="field">
                                        <input onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} className={err}
                                            placeholder='GUTSCHEINCODE EINGEBEN' value={coupon} onChange={event => setCoupon(event.target.value)}
                                            disabled={couponApplied} />
                                    </div>
                                    {
                                        couponApplied ? <button type="button" onClick={removeCoupon}>Remove</button> :
                                            <button type="button" onClick={onSubmitCoupon}>PRÜFEN</button>
                                    }
                                </div>

                                <span className={err}>{errTxt}</span>
                            </div>
                            <div className="line1" style={{ display: visibility ? 'none' : '' }}></div>
                            {/* --------------------- */}
                            <div className="cards-section">
                                {/* <div id="c1" onClick={changeCact} className={cactive.c1}> */}
                                <div id="c1">
                                    <img className='c-img' src="images/CreditCard.png" alt="" />
                                    <div className="c-txt">Kreditkarte</div>
                                </div>
                                {/* <div id="c2" onClick={changeCact} className={cactive.c2}>
                                    <img className='c-img' src="images/Klarna.png" alt="" />
                                    <div className="c-txt">Lastschrift</div>
                                </div> */}
                                {/* <div className="line3" style={cactive.c1 === "active" ? { left: 0 } : { left: '60%' }} ></div> */}
                                <div className="line3" ></div>
                            </div>
                            <div className="cards-details">
                                <form action="" onSubmit={confirmation}>

                                    <div>
                                        <div className="d1">
                                            <label>KARTENINHABER</label>
                                            <input type="text" name="" id="" onChange={event => setCnamee(event.target.value)} value={Cnamee} />
                                            <span className={Cnameeerr}>{CnameeerrTxt}</span>
                                        </div>
                                        <div className="d1 num">
                                            <label>KARTENNUMMER</label>
                                            <input onChange={event => setcardNo(event.target.value.replace(/[^0-9]/gi, '').replace(/(.{4})/g, '$1 ').trim())} value={cardNo} type="text" name="card" id="card" maxLength="19" />
                                            <div className="small-cards">
                                                <img src="images/visa1.png" alt="" />
                                                <img src="images/mastercard.png" alt="" />
                                                <img src="images/american-express.png" alt="" />
                                            </div>
                                            <span className={carderr}>{carderrTxt}</span>
                                        </div>
                                        <div className="d2">
                                            <div className="d1">
                                                <label>GÜLTIG BIS</label>
                                                <div className="mm-jj">
                                                    <input type="text" value={MM} onChange={event => setMM(event.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1'))} placeholder="MM" className="mm" maxLength="2" />
                                                    <span>/ &nbsp; &nbsp;</span>
                                                    <input id="YY" type="text" value={YY} onChange={event => setYY(event.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1'))} placeholder="JJ" className="jj" maxLength="2" />
                                                </div>
                                                <span className={MMerr}>{MMerrTxt}</span>
                                                <span className={YYerr}>{YYerrTxt}</span>
                                            </div>
                                            <div className="d1">
                                                <label>SICHERHEITSCODE</label>
                                                <input className="cvv" value={Cvv} onChange={event => setCvv(event.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1'))} type="text" placeholder="CVV" maxLength="4" />
                                                <span className={Cvverr}>{CvverrTxt}</span>
                                            </div>
                                        </div>
                                        <button id="credit-btn" type="submit" className="join-btn f-btn">BESTÄTIGEN & WEITER</button>
                                    </div>

                                </form>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </SkeletonTheme>
    )
}
