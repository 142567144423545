// import logo from '../logo.svg';
import React from 'react';
import '../../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import homeService from '../../store/services/home.service';
import config from '../../config/config';
import { useSelector } from 'react-redux';
import SliderCard from './SliderCard';
import LoadingComponent from '../LoadingComponent';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import LoadingTitle from '../LoadingTitle';
import ImageRenderer from '../ImageRenderer';


function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style }}
            onClick={onClick}
        > <img src={'/images/caret1.png'} alt="" /> </div>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style }}
            onClick={onClick}
        ><img src={'/images/caret1.png'} alt="" /></div>
    );
}

/***vertical scroll */

function CategoryTab() {

    const [loading, setLoading] = useState(false);
    const [newloading, setNewloading] = useState(true);
    const [channelLoading, setChannelLoading] = useState(false)
    const [recentlyPlayedLoading, setRecentlyPlayedLoading] = useState(false);
    const [musicLoading, setMusicLoading] = useState(true);
    const [audioLoading, setAudioLoading] = useState(false);
    const [collections, setCollections] = useState(null);
    const [newCollection, setNewCollection] = useState(null)
    const [recentlyPlayedCollection, setRecentlyPlayedCollection] = useState(null)
    const [musicCollection, setMusicCollection] = useState(null);
    const [audioCollection, setAudioCollection] = useState(null);
    const [series, setSeries] = useState(null);
    const [seriesLoading, setSeriesLoading] = useState(false);
    const [channels, setChannels] = useState(null);
    const [filterSelected, setFilterSelected] = useState(0);
    const { filters, fetched } = useSelector((state) => state.filterSlice);

    const fetchCollection = async () => {
        setLoading(true);
        setCollections(null)
        try {
            let res = await homeService.collection(filterSelected);
            setCollections(res.data.data);
            setLoading(false);
        } catch (error) {
            // console.log(error);
        }
    }
    const fetchSeries = async () => {
        setSeriesLoading(true);
        setSeries(null)
        try {
            let res = await homeService.getSeries();
            setSeries(res.data.data.data);
            // console.log(res.data.data.data);
            setSeriesLoading(false);
        } catch (error) {
            // console.log(error);
        }
    }

    const fetchDefaultColletion = async (type) => {

        switch (type) {
            case 'new':
                setNewloading(true);
                setNewCollection(null)
                break;
            case 'recentlyplayed':
                setRecentlyPlayedLoading(true);
                setRecentlyPlayedCollection(null);
                break;
            case 'music':
                setMusicLoading(true);
                setMusicCollection(null);
                break;
            case 'film':
                setAudioLoading(true);
                setAudioCollection(null);
                break;
            default:
                break;
        }
        try {
            let res = await homeService.collectionById(type, filterSelected);
            // console.log(res.data.data);
            switch (type) {
                case 'new':
                    setNewloading(false);
                    setNewCollection(res.data.data.media_arr.data);
                    break;
                case 'recentlyplayed':
                    setRecentlyPlayedLoading(false);
                    setRecentlyPlayedCollection(res.data.data.media_arr.data)
                    break;
                case 'music':
                    setMusicLoading(false);
                    setMusicCollection(res.data.data.media_arr.data)
                    break;
                case 'film':
                    setAudioLoading(false);
                    setAudioCollection(res.data.data.media_arr.data)
                    break;
                default:
                    break;
            }
        } catch (error) {
            console.log(error);
        }
    }


    const fetchChannels = async () => {
        setChannelLoading(true);
        setChannels(null)
        try {
            let res = await homeService.channels();
            // console.log(res.data.data.data);
            setChannels(res.data.data.data);
            setChannelLoading(false);
        } catch (error) {
            // console.log(error);
        }
    }
    useEffect(() => {
        fetchCollection();
    }, [filterSelected]);

    useEffect(() => {
        fetchDefaultColletion('new');
        fetchDefaultColletion('recentlyplayed');
        fetchDefaultColletion('music');
        fetchDefaultColletion('film');
    }, [filterSelected]);

    useEffect(() => {
        fetchChannels();
    }, []);

    useEffect(() => {
        fetchSeries();
    }, []);


    const recent = {
        variableWidth: true,
        dots: false,
        arrows: true,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 5,
        swipeToSlide: true,
        cssEase: 'linear',
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1200,
                settings: "unslick"
            }
        ],

    };


    const [isClass, setClass] = useState(false)


    useEffect(() => {
        setTimeout(() => {
            setClass(false);
        }, 5000);
    }, []);

    const [, setMatches] = useState(
        window.matchMedia("(min-width: 1200px)").matches
    )

    useEffect(() => {
        window
            .matchMedia("(min-width: 1200px)")
            .addEventListener('change', e => setMatches(e.matches));


    }, []);




    return (
        <div className={`category-tab ${isClass ? "skeleton" : ""} `} >

            <SkeletonTheme baseColor="#202020" highlightColor="#444">

                <div className="container">
                    <div className='cat-top'>
                        <div className="cat-ul">
                            {
                                !fetched ? <Skeleton className='sk-filter' /> :

                                    <ul className="d-flex flex-wrap mb-3">

                                        <li className={filterSelected == 0 ? 'active' : null} onClick={() => setFilterSelected(0)}>
                                            Alle
                                        </li>
                                        {

                                            filters.map((filter) => {
                                                return (
                                                    <li key={filter.id} className={filterSelected == filter.id ? 'active' : null} onClick={() => setFilterSelected(filter.id)}>
                                                        {filter.title}
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                            }
                        </div>


                        {
                            newloading
                                ? <>
                                    <LoadingTitle />
                                    <LoadingComponent />
                                </>
                                // null
                                :
                                newCollection && newCollection.length > 0 ?
                                    <div className='music-slider recent' key="new">
                                        <div className="music-top">
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="top-title position-relative"><h3><b className='dont-break'>Neu</b></h3>
                                                        <img className="top-img" src={'/images/star2.png'} alt="" />
                                                    </div>
                                                </div>
                                                <div className="col-6 text-end rgt">
                                                    <h3><Link to="/collection/new">Alle ansehen</Link></h3>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="music-btm-slider neu ">
                                            <Slider {...recent}>
                                                {
                                                    newCollection && newCollection.map((media) => {
                                                        return (
                                                            <SliderCard media={media} key={media.id} />
                                                        )
                                                    })
                                                }
                                            </Slider>
                                        </div>
                                    </div> : null

                        }


                        {
                            recentlyPlayedLoading
                                ?
                                <>
                                    <LoadingTitle />
                                    <LoadingComponent />
                                </>
                                // null
                                :
                                recentlyPlayedCollection && recentlyPlayedCollection.length > 0
                                    ?
                                    <div className='music-slider filme mtc-4' >
                                        <div className="music-top">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="top-title position-relative ps-0"><h3><b className='dont-break'>Zuletzt abgespielt</b></h3></div>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="music-btm-slider ">
                                            <Slider {...recent}>
                                                {
                                                    recentlyPlayedCollection && recentlyPlayedCollection.map((media) => {
                                                        return (
                                                            <SliderCard media={media} key={media.id} />
                                                        )
                                                    })
                                                }
                                            </Slider>
                                        </div>
                                    </div> : null

                        }

                        {
                            musicLoading
                                ?
                                <>
                                    <LoadingTitle />
                                    <LoadingComponent />
                                </>
                                // null
                                :
                                musicCollection && musicCollection.length > 0
                                    ?
                                    <div className='music-slider filme mtc-4' >
                                        <div className="music-top">
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="top-title position-relative"><h3><b className='dont-break'>Musik & Hörbücher</b></h3>
                                                        <img className="top-img" src={'/images/musik-3.png'} alt="" />
                                                    </div>
                                                </div>
                                                <div className="col-6 text-end rgt">
                                                    <h3><Link to="/collection/music">Alle ansehen</Link></h3>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="music-btm-slider ">
                                            <Slider {...recent}>
                                                {
                                                    musicCollection && musicCollection.map((media) => {
                                                        return (
                                                            <SliderCard hideIcon={true} media={media} key={media.id} />
                                                        )
                                                    })
                                                }
                                            </Slider>
                                        </div>
                                    </div> : null

                        }

                        {
                            audioLoading
                                ?
                                <>
                                    <LoadingTitle />
                                    <LoadingComponent />
                                </>
                                // null
                                :
                                audioCollection && audioCollection.length > 0
                                    ?
                                    <div className='music-slider filme mtc-4' >
                                        <div className="music-top">
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="top-title position-relative"><h3><b className='dont-break'>Filme</b></h3>
                                                        <img className="top-img" src={'/images/Kamera-3.png'} alt="" />
                                                    </div>
                                                </div>
                                                <div className="col-6 text-end rgt">
                                                    <h3><Link to="/collection/film">Alle ansehen</Link></h3>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="music-btm-slider ">
                                            <Slider {...recent}>
                                                {
                                                    audioCollection && audioCollection.map((media) => {
                                                        return (
                                                            <SliderCard hideIcon={true} media={media} key={media.id} />
                                                        )
                                                    })
                                                }
                                            </Slider>
                                        </div>
                                    </div> : null

                        }


                        {
                            loading
                                ?
                                <>
                                    <LoadingTitle />
                                    <LoadingComponent />
                                </>
                                // null
                                :
                                collections && collections.map((collection) => {
                                    return (
                                        collection.media_arr && collection.media_arr.length > 0
                                            ?
                                            <div className='music-slider filme mtc-4' key={collection.id}>
                                                <div className="row">
                                                    <div className="col pe-0">
                                                        <div className="top-title position-relative"><h3><b className="dont-break">{collection.title}</b></h3>
                                                            {
                                                                collection.icon ? <img className="top-img" src={`${config.COLLECTION_URL}/${collection.icon}`} alt="" />
                                                                    :
                                                                    null
                                                            }

                                                        </div>
                                                    </div>
                                                    <div className="col text-end rgt">
                                                        <h3><Link to={`/collection/${collection.id}`}>Alle ansehen</Link></h3>
                                                    </div>
                                                </div>

                                                <div className="music-btm-slider neu">
                                                    <Slider {...recent}>
                                                        {
                                                            collection.media_arr && collection.media_arr.map((media) => {
                                                                return (
                                                                    <SliderCard media={media} key={media.id} />
                                                                )
                                                            })
                                                        }
                                                    </Slider>
                                                </div>
                                            </div> : null


                                    )

                                }

                                )
                        }

                        {
                            seriesLoading ?
                                <>
                                    <LoadingTitle />
                                    <LoadingComponent />
                                </>
                                // null 
                                :

                                series && series.length > 0 ?

                                    <div className='music-slider mtc-4' >
                                        <div className="music-top">
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="top-title position-relative"><h3><b className='dont-break'>Series</b></h3>
                                                        {/* <img className="top-img" src={'/images/kanale-3.png'} alt="" /> */}
                                                    </div>
                                                </div>
                                                <div className="col-6 text-end rgt">
                                                    <h3><Link to={'/series'}>Alle ansehen </Link></h3>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="music-btm-slider  neu pb-2 series-con2">

                                            {
                                                seriesLoading ?
                                                    // <LoadingComponent />
                                                    null
                                                    :
                                                    <Slider {...recent}>
                                                        {series && series.map((series) => {
                                                            return (

                                                                <div className="music-slider-blk " key={series.id}>
                                                                    <Link to={`series/${series.slug}`}>
                                                                        <div className="blk-wrapper">
                                                                            <div className="image">
                                                                                <ImageRenderer
                                                                                    wrapperClassName="lazy-slider"
                                                                                    url={`${config.SERIES_URL}/${series.image}`} />
                                                                            </div>
                                                                            <div className="slider-btm-wp">
                                                                                <div className="wp-lft">
                                                                                    <h4 className="title">{series.title}</h4>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Link>
                                                                </div>

                                                            );
                                                        })}
                                                    </Slider>
                                            }
                                        </div>
                                    </div>
                                    :
                                    null




                        }

                        {
                            channels && channels.length > 0 ?
                                <div className='music-slider rounded mtc-4' >
                                    <div className="music-top">
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="top-title position-relative"><h3><b className='dont-break'>Kanäle</b></h3>
                                                    <img className="top-img" src={'/images/kanale-3.png'} alt="" />
                                                </div>
                                            </div>
                                            <div className="col-6 text-end rgt">
                                                <h3><Link to={'/channels'}>Alle ansehen </Link></h3>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="music-btm-slider  neu pb-2">

                                        {
                                            channelLoading ?
                                                <>
                                                    <LoadingTitle />
                                                    <LoadingComponent />
                                                </>
                                                // null
                                                :
                                                <Slider {...recent}>
                                                    {channels && channels.map((channel) => {
                                                        return (

                                                            <div className="music-slider-blk " key={channel.id}>
                                                                <Link to={`channel/${channel.id}`}>
                                                                    <div className="blk-wrapper">
                                                                        <div className="image">
                                                                            <ImageRenderer
                                                                                wrapperClassName="lazy-slider"
                                                                                url={`${config.CHANNEL_URL}/${channel.thumbnail_image}`} />
                                                                        </div>
                                                                        <div className="slider-btm-wp">
                                                                            <div className="wp-lft">
                                                                                <h4 className="title">{channel.name}</h4>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </div>

                                                        );
                                                    })}
                                                </Slider>
                                        }
                                    </div>
                                </div>
                                : null

                        }



                    </div>
                </div>
            </SkeletonTheme>
        </div>

    );
}

export default CategoryTab;
