import axios from "../../axios";


class profileUpdate {

    updatefirstName(data) {
        return axios.post('/update-first-name', data);
    }
    updatelastName(data) {
        return axios.post('/update-last-name', data);
    }

    updateEmail(data) {
        return axios.post('/update-email', data);
    }
    updatePassword(data) {
        return axios.post('/update-password', data);
    }
   

}

export default new profileUpdate();