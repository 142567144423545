import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import homeService from "./services/home.service";
import STATUS from "./status";


const initialState = {
    status: STATUS.IDLE,
    fetched: false,
    filters: {}
}

export const filterThunk = createAsyncThunk(
    'filterThunk', async () => {
        const res = await homeService.filters();
        return res.data;
    }
)

const filterSlice = createSlice({
    name: 'filterSlice',
    initialState,

    extraReducers: {
        [filterThunk.rejected]: (state, action) => {
            state.status = STATUS.ERROR;
            state.fetched = false;
            state.filters = {}
        },
        [filterThunk.pending]: (state, action) => {
            state.status = STATUS.LOADING
            state.fetched = false;
            state.filters = {}
        },
        [filterThunk.fulfilled]: (state, action) => {
            state.status = STATUS.SUCCESS
            state.fetched = true;
            state.filters = action.payload.data;
        }
    }
})

const { reducer } = filterSlice;

export default reducer;