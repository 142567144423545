import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Link } from 'react-router-dom'
import config from '../config/config'
import ImageRenderer from './ImageRenderer'

function MediaCard(props) {
    return (
        <div className="col-20" key={`media_${props.media.id}`}>
            <Link to={`/media/${props.media.id}`}>
                <div className="v-card v-all">
                    <ImageRenderer
                        wrapperClassName="lazy-viewall"
                        url={`${config.MEDIA_URL}/${props.media.cover_image}`} />

                    <div className="slider-btm-wp">
                        <div className="wp-lft">
                            <h4 className="title">{props.media.title}</h4>

                            {
                                props.media.artists ?
                                    <h4 className="artist">
                                        {
                                            props.media.artists.map((artist, i, row) => {
                                                if (i + 1 === row.length) {
                                                    // Last one.
                                                    return `${artist.name}`
                                                } else {
                                                    return `${artist.name}, `
                                                }
                                            })
                                        }
                                    </h4> : null
                            }

                        </div>
                        {
                            !props.hideIcon && props.media.category && <div className="wp-rgt viewall">
                                <img src={`${config.CATEGORY_URL}/${props.media.category.icon}`} alt="" className="icon d-only" />
                                <img src={`${config.CATEGORY_URL}/${props.media.category.mobile_icon}`} alt="" className="icon m-only" />
                            </div>
                        }
                    </div>
                </div>
            </Link>
        </div>
    )
}

export default MediaCard